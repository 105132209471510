import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { LocalStorageService, FiltersService } from '@app/core/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private filtersService: FiltersService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      // add auth header with jwt if user is logged in and request is to api url
      const userData = this.localStorageService.getCurrentUser();
      const isApiUrl = request.url.startsWith(environment.baseApiUrl + 'user/');
      const userFilter = this.filtersService.userValue;
      const operatorFilter = this.filtersService.operatorValue;

      if (userData && userData.token && isApiUrl) {
        request = request.clone({
          headers: request.headers.set("Authorization", `Bearer ${userData.token}`)
        });
      } else {
        // no modifications at this stage if no user data or token
        request = request.clone();
      }

      if (userFilter) {
        request = request.clone({
          headers: request.headers.set("User-Filter", userFilter)
        });
      }

      if (operatorFilter) {
        request = request.clone({
          headers: request.headers.set("Operator-Filter", operatorFilter)
        });
      }

      return next.handle(request);
    }
    catch (err) {
      console.error('AuthInterceptor() catch; err: ', err);

      // return it as it is..
      return next.handle(request);
    }
  }
}
