export class WalletTransaction {
  _id?: string;
  uuid?: string;
  type?: string;
  fromWalletUuid?: string;
  toWalletUuid?: string;
  amount?: number;
  currency?: string;
  description?: string;
  reference?: string;
  additionalData?: any;
  status?: string;
  createdAt?: Date;

  outgoing?: boolean;

    /**
   * Constructor
   *
   * @param transaction
   */
  constructor( transaction? )
  {
    this._id = transaction._id || '';
    this.uuid = transaction.uuid || '';
    this.type = transaction.type || '';
    this.fromWalletUuid = transaction.fromWalletUuid;
    this.toWalletUuid = transaction.toWalletUuid;
    this.amount = transaction.amount;
    this.currency = transaction.currency;
    this.reference = transaction.reference || '';
    this.additionalData = transaction.additionalData || {};
    this.status = transaction.status;
    this.createdAt = transaction.createdAt;
    this.outgoing = transaction.outgoing;
  }

}
