import { HistoryItem } from "./business-statement.interface";
import { Subscription } from "./subscription.interface";

export interface UserSubscription {
  _id: string;

  startDate: Date;
  endDate: Date;
  oneTimeStartFee: number;
  duration: string;
  fee: number;

  autoRenewal: boolean;
  status: string;
  feature: string;

  // Model references
  user: string;
  createdByUser: string;
  operator: string;
  subscription: Subscription;

  createdAt: Date;
  updatedAt?: Date;
  history: [HistoryItem];
};
