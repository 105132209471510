import { User } from '@app/core/models';

export class RowItem {
  title: string;
  sku: string;
  description: string;
  netPrice: number;
  isGrossCalc: boolean;
  grossPrice: number;
  vatRate: number;
  vatClass: string;
  imageUrl: string;
  quantity: number;
  user?: User;
  // virtuals too
  vatPrice: number;
  totalNet: number;
  totalVat: number;
  totalGross: number;
  // helper for printing expense items
  isPayoutDeduction: boolean;

  constructor(rowItem?) {
    this.title = rowItem.title;
    this.sku = rowItem.sku;
    this.description = rowItem.description;
    this.netPrice = rowItem.netPrice;
    this.isGrossCalc = rowItem.isGrossCalc;
    this.grossPrice = rowItem.grossPrice;
    this.vatRate = rowItem.vatRate;
    this.vatClass = rowItem.vatClass;
    this.imageUrl = rowItem.imageUrl;
    this.quantity = rowItem.quantity;
    this.user = rowItem.user;
    // virtuals too
    this.vatPrice = rowItem.vatPrice;
    this.totalNet = rowItem.totalNet;
    this.totalVat = rowItem.totalVat;
    this.totalGross = rowItem.totalGross;
    // other helpers
    this.isPayoutDeduction = rowItem.isPayoutDeduction;
  }
}
