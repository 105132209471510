export class UserProfile {
  createdAt?: string;
  firstName?: string;
  firstName1?: string;
  lastName?: string;
  fullName?: string;
  formattedAddress?: string;
  fullAddress?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  postalCode?: string;
  city?: string;
  stateOrProvince?: string;
  countryCode?: string;
  citizenshipCode?: string;

  mobilePhone?: string;
  mobileVerified?: boolean;
  tin?: string;
  ssn?: string;
  idVerified?: boolean;
  isCompleted?: boolean;

  locale?: string;
  age?: number;
  gender?: string;
  profileImageId?: string;
  profileImageUrl?: string;

  abstractPin?: string;
  foreignPin?: string;


  // taxi specific
  taxiDriverId?: string;

  /**
   * Constructor
   *
   * @param user
   */
  constructor(userProfile?) {
    this.createdAt = userProfile.createdAt;
    this.firstName = userProfile.firstName;
    this.firstName1 = userProfile.firstName1;
    this.lastName = userProfile.lastName;
    this.fullName = userProfile.fullName;
    this.ssn = userProfile.ssn;
    this.formattedAddress = userProfile.formattedAddress;
    this.fullAddress = userProfile.fullAddress;
    this.streetAddress1 = userProfile.streetAddress1;
    this.streetAddress2 = userProfile.streetAddress2;
    this.postalCode = userProfile.postalCode;
    this.city = userProfile.city;
    this.stateOrProvince = userProfile.stateOrProvince;
    this.countryCode = userProfile.countryCode || 'FI';
    this.citizenshipCode = userProfile.citizenshipCode || 'FI'
    this.mobilePhone = userProfile.mobilePhone;
    this.mobileVerified = userProfile.mobileVerified;
    this.locale = userProfile.locale || 'fi-FI';
    this.profileImageId = userProfile.profileImageId;
    this.profileImageUrl = userProfile.profileImageUrl;
    this.idVerified = userProfile.idVerified;
    this.age = userProfile.age;
    this.tin = userProfile.tin;
    this.isCompleted = userProfile.isCompleted;
    this.gender = userProfile.gender;

    this.abstractPin = userProfile.abstractPin;
    this.foreignPin = userProfile.foreignPin;

    this.taxiDriverId = userProfile.taxiDriverId;
  }
}
