import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContentComponent } from '@app/layout/components/content/content.component';
import { FooterComponent } from '@app/layout/components/footer/footer.component';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';
import { VerticalLayout1Component } from 'app/layout/vertical/layout-1/layout-1.component';

@NgModule({
    declarations: [
        VerticalLayout1Component
    ],
    imports     : [
        RouterModule,

        FuseSharedModule,
        FuseSidebarModule,

        NavbarModule,
        ToolbarModule,

        // Standalone components 
        ContentComponent,
        FooterComponent
    ],
    exports     : [
        VerticalLayout1Component
    ]
})
export class VerticalLayout1Module
{
}
