import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';

import { SharedModule } from '@app/shared/shared.module';

import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUserService } from '@app/core/services';
import { User } from '@app/core/models';
import { FuseNavigationItem } from '@fuse/types';

@Component({
  selector: 'fuse-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, OnDestroy {
  shortcutItems: any[];
  navigationItems: any[];
  filteredNavigationItems: any[];
  searching: boolean;
  mobileShortcutsPanelActive: boolean;
  maxAllowedShortcuts: number;
  maxAllowedShortcutsMobile = 4;
  maxAllowedShortcustDesktop = 10;
  shorcutItemsTranslationsKey = [];
  translatedShorcutItems = [];
  currentUser: User;

  defaultShortcutItems = [
    {
      title: 'Messaging',
      translate: 'menu.messaging',
      icon: 'chat',
      url: '/chat'
    },
    {
      title: 'Clients',
      translate: 'menu.clients',
      icon: 'account_circle',
      url: '/clients'
    },
    {
      title: 'Invoices',
      translate: 'menu.invoices',
      icon: 'credit_card',
      url: '/invoices'
    },
    {
      title: 'Expenses',
      translate: 'menu.expenses',
      icon: 'receipt',
      url: '/expenses'
    },
    {
      title: 'Payouts',
      translate: 'menu.payouts',
      icon: 'money',
      url: '/payouts'
    }
  ];

  defaultShortcutItemsAccounting = [
    {
      title: 'Invoices',
      translate: 'menu.invoices',
      icon: 'credit_card',
      url: '/invoices'
    },
    {
      title: 'Expenses',
      translate: 'menu.expenses',
      icon: 'receipt',
      url: '/expenses'
    },
    {
      title: 'Payouts',
      translate: 'menu.payouts',
      icon: 'money',
      url: '/payouts'
    }
  ];

  defaultShortcutItemsTaxiCompany = [
    {
      title: 'Messaging',
      translate: 'menu.messaging',
      icon: 'chat',
      url: '/chat'
    },
    {
      title: 'Invoices',
      translate: 'menu.invoices',
      icon: 'credit_card',
      url: '/invoices'
    },
    {
      title: 'New quick invoice',
      translate: 'menu.quickSimpleInvoiceForm',
      icon: 'credit_card',
      url: '/invoices/simple-invoice?mode=standard_invoice'
    },
    {
      title: 'New KELA invoice',
      translate: 'menu.simpleInvoiceForm',
      icon: 'credit_card',
      url: '/invoices/simple-invoice?mode=kela_ov'
    },
    {
      title: 'menu.subUsers',
      translate: 'menu.subUsers',
      icon: 'account_circle',
      url: '/sub-users'
    },
    {
      title: 'menu.vehicles',
      translate: 'menu.vehicles',
      icon: 'drive_eta',
      url: '/vehicles'
    }
  ];

  defaultShortcutItemsTaxiDriver = [
    {
      title: 'Dashboard',
      translate: 'menu.dashboard',
      icon: 'dashboard',
      url: '/dashboard'
    },
    {
      title: 'Messaging',
      translate: 'menu.messaging',
      icon: 'chat',
      url: '/chat'
    },
    {
      title: 'New Quick Invoice',
      translate: 'menu.quickSimpleInvoiceForm',
      icon: 'credit_card',
      url: '/invoices/simple-invoice/standard_invoice'
    },
    {
      title: 'New KELA Invoice',
      translate: 'menu.simpleInvoiceForm',
      icon: 'credit_card',
      url: '/invoices/simple-invoice/kela_ov'
    }
  ];

  @Input() navigation: any;

  @ViewChild('searchInput') searchInputField;

  @ViewChild('shortcuts') shortcutsEl: ElementRef;

  // Private
  private _unsubscribeAll = new Subject<void>();

  /**
   * Constructor
   *
   * @param {CookieService} _cookieService
   * @param {FuseMatchMediaService} _fuseMatchMediaService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {MediaObserver} _mediaObserver
   * @param {Renderer2} _renderer
   */
  constructor(
    private _cookieService: CookieService,
    private _fuseMatchMediaService: FuseMatchMediaService,
    private _fuseNavigationService: FuseNavigationService,
    private _mediaObserver: MediaObserver,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _renderer: Renderer2,
    private _currentUserService: CurrentUserService,
  ) {
    // Set the defaults
    this.shortcutItems = [];
    this.searching = false;
    this.mobileShortcutsPanelActive = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.currentUser = this._currentUserService.getUser();
    // Get the navigation items and flatten them
    this.maxAllowedShortcuts = (this._platform.ANDROID || this._platform.IOS) ?
      this.maxAllowedShortcutsMobile : this.maxAllowedShortcustDesktop;
    // Get the navigation items and flatten them
    console.log('ShortcutsComponent/init() navigation:', this.navigation);
    this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(this.navigation);

    if (this._cookieService.check('FUSE2.shortcuts_' + this.currentUser.role)) {
      this.shortcutItems = JSON.parse(this._cookieService.get('FUSE2.shortcuts_' + this.currentUser.role));
      this.shortcutItems = this.shortcutItems.slice(0, this.maxAllowedShortcuts);
    } else {
      // User's default shortcut items
      switch (this.currentUser.role) {
        case 'operator-accounting':
          this.shortcutItems = this.defaultShortcutItemsAccounting;
          break;
        case 'taxi-company':
          this.shortcutItems = this.defaultShortcutItemsTaxiCompany;
          break;
        case 'taxi-driver':
          this.shortcutItems = this.defaultShortcutItemsTaxiDriver;
          break;
        default:
          this.shortcutItems = this.defaultShortcutItems;
          break;
      }
    }

    // Subscribe to media changes
    this._fuseMatchMediaService.onMediaChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        if (this._mediaObserver.isActive('gt-sm')) {
          this.maxAllowedShortcuts = this.maxAllowedShortcustDesktop;
          if (this.shortcutsEl && this.shortcutsEl.nativeElement) {
            this.hideMobileShortcutsPanel();
          }
        } else {
          this.maxAllowedShortcuts = this.maxAllowedShortcutsMobile;
          if (this.shortcutItems.length >= this.maxAllowedShortcuts) {
            this.shortcutItems = this.shortcutItems.slice(0, this.maxAllowedShortcuts);
          }
        }
      });

    for (const key in this.filteredNavigationItems) {

      if (this.filteredNavigationItems[key].hidden === true) {
        delete this.filteredNavigationItems[key];
      }

    }

   
    this.updateFilteredNavigationItemsWithShortcutStatus();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateFilteredNavigationItemsWithShortcutStatus() {
     // Set isInShortcusts to filteredNavigationItems
     this.filteredNavigationItems = this.filteredNavigationItems.map(item => ({
      ...item,
      isInShortcuts: this.isInShortcuts(item)
    }));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Search
   *
   * @param event
   */
  search(event): void {
    const value = event.target.value.toLowerCase();

    if (value === '') {
      this.searching = false;
      this.filteredNavigationItems = this.navigationItems;

      return;
    }

    this.searching = true;

    this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
      return navigationItem.title.toLowerCase().includes(value)
        || this._translateService.instant(navigationItem.translate).toLowerCase().includes(value);
    });

    this.updateFilteredNavigationItemsWithShortcutStatus();
  }

  toggleShortcut(event, itemToToggle): void {
    event.stopPropagation();

    for (let i = 0; i < this.shortcutItems.length; i++) {
      if (this.shortcutItems[i].url === itemToToggle.url) {
        itemToToggle.isInShortcuts = false;
        this.shortcutItems.splice(i, 1);

        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts_' + this.currentUser.role, JSON.stringify(this.shortcutItems));

        return;
      }
    }

    if (this.shortcutItems.length >= this.maxAllowedShortcuts) {
      return;
    } else {
      itemToToggle.isInShortcuts = true;
      this.shortcutItems.push(itemToToggle);
    }

    // Save to the cookies
    this._cookieService.set('FUSE2.shortcuts_   ' + this.currentUser.role, JSON.stringify(this.shortcutItems));
  }

  isInShortcuts(navigationItem: FuseNavigationItem): boolean {
    return this.shortcutItems.some(item => item.url === navigationItem.url)  
  }

  /**
   * On menu open
   */
  onMenuOpen(): void {
    setTimeout(() => {
      this.searchInputField.nativeElement.focus();
    });
  }

  /**
   * Show mobile shortcuts
   */
  showMobileShortcutsPanel(): void {
    this.mobileShortcutsPanelActive = true;
    this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
  }

  /**
   * Hide mobile shortcuts
   */
  hideMobileShortcutsPanel(): void {
    this.mobileShortcutsPanelActive = false;
    this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
  }
}
