import { User } from "./user.model";

export class TaxiVehicle {
  _id?: string;
  taxiGroup?: string;
  taxiCarUlaNumber?: string; // obsolete
  taxiCarLicensePlate?: string; // obsolete
  callsign?: string;
  licensePlate?: string;
  externalId?: string;
  make?: string;
  model?: string;
  year?: number;
  vehicleType?: string;
  color?: string;
  numOfSeats?: number;
  co2Emissions?: number;
  odometerKm?: number;
  comment?: string;
  additionalData?: any;
  // references
  owner?: User | string;
  operator?: string;
  createdByUser?: User | string;

  /**
   * Constructor
   *
   * @param vehicle
   */
  constructor(vehicle?) {
    this._id = vehicle?._id || '';
    this.taxiGroup = vehicle?.taxiGroup;
    this.taxiCarUlaNumber = vehicle?.taxiCarUlaNumber; // obsolete
    this.taxiCarLicensePlate = vehicle?.taxiCarLicensePlate; // obsolete
    this.callsign = vehicle?.callsign;
    this.licensePlate = vehicle?.licensePlate;
    this.externalId = vehicle?.externalId;
    this.make = vehicle?.make;
    this.model = vehicle?.model;
    this.year = vehicle?.year;
    this.vehicleType = vehicle?.vehicleType;
    this.color = vehicle?.color;
    this.numOfSeats = vehicle?.numOfSeats;
    this.co2Emissions = vehicle?.co2Emissions;
    this.odometerKm = vehicle?.odometerKm;
    this.comment = vehicle?.comment;
    this.additionalData = vehicle?.additionalData;
    // refs
    this.owner = vehicle?.owner?._id ? new User(vehicle?.owner) : vehicle?.owner;
    this.operator = vehicle?.operator;
    this.createdByUser = vehicle?.createdByUser?._id ? new User(vehicle?.createdByUser) : vehicle?.createdByUser;
  }
}
