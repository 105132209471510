import { HistoryItem } from './business-statement.interface';

// export interface HistoryItem {
//   time: Date;
//   status: string;
//   event: string;
//   description: string;
//   user?: string; // MongoDB ObjectId as string
// }

export interface FactoringItem {
  identifier: string;
  name?: string;
  sourcesOfIncome: any[];
  sourcesOfIncomeIdentifiers: string[];
}

export type FactoringContractStatus = 'created' | 'signed' | 'approved' | 'rejected' | 'cancelled' | 'terminated';
export type SettlementFrequency = 'daily' | 'weekly' | 'bi-weekly' | 'twice_per_month' | 'monthly';


export interface FactoringContract {
  _id?: string;
  contractNumber?: number;
  status: FactoringContractStatus;
  startsAt?: Date;
  endsAt?: Date;
  factoringItems: FactoringItem[];
  settlementFrequency?: SettlementFrequency;

  // History dates
  signedAt?: Date;
  signedAtExtraData?: string;
  approvedAt?: Date;
  rejectedAt?: Date;
  terminatedAt?: Date;
  history: HistoryItem[];

  // References
  user: string; // MongoDB ObjectId as string
  operator: string; // MongoDB ObjectId as string

  // Timestamps
  createdAt?: Date;
  updatedAt?: Date;
}

// Type guard to check if an object is a FactoringContract
export function isFactoringContract(obj: any): obj is FactoringContract {
  return obj
    && typeof obj.status === 'string'
    && Array.isArray(obj.factoringItems)
    && Array.isArray(obj.history)
    && typeof obj.user === 'string'
    && typeof obj.operator === 'string';
}

export class FactoringContractModel implements FactoringContract {
  _id?: string;
  contractNumber?: number;
  status: FactoringContractStatus;
  startsAt?: Date;
  endsAt?: Date;
  factoringItems: FactoringItem[];
  settlementFrequency?: SettlementFrequency;
  signedAt?: Date;
  signedAtExtraData?: string;
  approvedAt?: Date;
  rejectedAt?: Date;
  terminatedAt?: Date;
  history: HistoryItem[];
  user: string;
  operator: string;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(data?: Partial<FactoringContract>) {
    console.log('FactoringContractModel.constructor()', data);

    if (!data) {
      throw new Error('FactoringContract data is required');
    }

    // Required fields validation
    if (!data.status) {
      throw new Error('FactoringContract status is required');
    }
    if (!data.user) {
      throw new Error('FactoringContract user is required');
    }
    if (!data.operator) {
      throw new Error('FactoringContract operator is required');
    }

    // Initialize with defaults for required arrays
    this.factoringItems = [];
    this.history = [];

    // Assign all properties
    Object.assign(this, {
      ...data,
      // Convert date strings to Date objects
      startsAt: data.startsAt ? new Date(data.startsAt) : undefined,
      endsAt: data.endsAt ? new Date(data.endsAt) : undefined,
      signedAt: data.signedAt ? new Date(data.signedAt) : undefined,
      approvedAt: data.approvedAt ? new Date(data.approvedAt) : undefined,
      rejectedAt: data.rejectedAt ? new Date(data.rejectedAt) : undefined,
      terminatedAt: data.terminatedAt ? new Date(data.terminatedAt) : undefined,
      createdAt: data.createdAt ? new Date(data.createdAt) : undefined,
      updatedAt: data.updatedAt ? new Date(data.updatedAt) : undefined,
      // Ensure history items have proper Date objects
      history: (data.history || []).map(item => ({
        ...item,
        time: new Date(item.time)
      }))
    });

    // Validate status enum
    if (!['created', 'signed', 'approved', 'rejected', 'cancelled', 'terminated'].includes(this.status)) {
      throw new Error(`Invalid status: ${this.status}`);
    }

    // Validate settlement frequency if provided
    if (this.settlementFrequency &&
        !['daily', 'weekly', 'monthly', 'quarterly', 'yearly'].includes(this.settlementFrequency)) {
      throw new Error(`Invalid settlement frequency: ${this.settlementFrequency}`);
    }

    console.log('FactoringContractModel constructed successfully');
  }
}
