<button matTooltip="Notifications" mat-icon-button class="callHelp" [matMenuTriggerFor]="showHelp" aria-label="Help">
  <mat-icon>notifications</mat-icon>
</button>
<span *ngIf="notificationsCount" matBadgecolor="secondary" matBadgePosition="before" matBadge="{{ notificationsCount }}"
  matBadgeOverlap="false" fxHide fxShow.gt-sm></span>

<mat-menu #showHelp="matMenu" [overlapTrigger]="false" class="notification-box" fxLayout="column" fxFlex="100"
  fxFlex.gt-xs="60">
  <div class="content mx-12 my-12" fxLayout="column" fxFlex="100">

    <div fxLayout="row" fxFlex="100">
      <div fxLayoutAlign="center center">
        <div class="title">
          {{ 'notifications.generic.title' | translate}}
        </div>
        <button
          *ngIf="!['soletrader', 'company', 'taxi-company', 'taxi-driver', 'freelancer', 'operator-accounting'].includes(user.role)"
          (click)="openForm($event)" class="m-8" mat-raised-button color="primary">
          <mat-icon class="notificationAddBtn">{{ !showForm ? 'add' : 'arrow_back' }}</mat-icon>
        </button>
      </div>
    </div>
    <mat-divider class="mb-16"></mat-divider>
    <div *ngIf="!notifications || !notifications.length" class="subtitle my-12" fxLayoutAlign="start start"
      fxFlex="100">
      {{ 'notifications.generic.noEntries' | translate}}
    </div>
    <form (click)="$event.stopPropagation();"
      *ngIf="showForm && !['soletrader', 'company', 'freelancer', 'taxi-company', 'taxi-driver'].includes(user.role)"
      fxLayout="column" [formGroup]="newNotificationForm" fxFlex="100">
      <mat-form-field *ngIf="isOperatorFieldRequired" appearance="outline" fxFlex>
        <mat-label style="color: gray!important"> {{ 'forms.operatorPlaceholder' | translate }}</mat-label>
        <mat-select class="custom-select" formControlName="operator" required>
          <mat-option value="all">{{ 'forms.selectAllOptionLabel' | translate }}</mat-option>
          <mat-option *ngFor="let operator of operators$ | async" [value]="operator._id">
            {{ operator.shortName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-translate-field-form [formGroup]="newNotificationForm"
        [translationKeyLabel]="'notifications.form.title'" [controlName]="'title'" [required]="true"
        [translatedFieldContent]='{}'>
      </app-translate-field-form>
      <app-translate-field-form [formGroup]="newNotificationForm"
        [translationKeyLabel]="'notifications.form.content'" [controlName]="'content'" [required]="true"
        [translatedFieldContent]='{}'>
      </app-translate-field-form>
      <app-translate-field-form [formGroup]="newNotificationForm"
        [translationKeyLabel]="'notifications.form.actionTitle'" [controlName]="'actionTitle'" [required]="true"
        [translatedFieldContent]='{}'>
      </app-translate-field-form>

      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label style="color: gray">{{'notifications.form.actionUrl' | translate}}</mat-label>
        <input matInput formControlName="actionUrl">
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label style="color: gray">{{ 'notifications.form.startsAt' | translate }}</mat-label>
        <input matInput [matDatepicker]="startsAtPicker" name="startsAt" formControlName="startsAt">
        <mat-datepicker-toggle matSuffix [for]="startsAtPicker"></mat-datepicker-toggle>
        <mat-datepicker #startsAtPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label style="color: gray">{{ 'notifications.form.expiresAt' | translate }}</mat-label>
        <input matInput [min]="minDate" [matDatepicker]="expiresAtPicker" name="expiresAt" formControlName="expiresAt">
        <mat-datepicker-toggle matSuffix [for]="expiresAtPicker"></mat-datepicker-toggle>
        <mat-datepicker #expiresAtPicker></mat-datepicker>
        <mat-error *ngIf="newNotificationForm.get('expiresAt').hasError('mustBeOverStartDate')">{{
          'notifications.form.mustBeOverStartDate' | translate }}
        </mat-error>
      </mat-form-field>
      <app-message-box *ngIf="showError" [closable]="false" type="error">
        {{ showError | translate }}
      </app-message-box>
      <button (click)="createNotification()" mat-raised-button color="primary">Create</button>
    </form>
    <mat-divider *ngIf="showForm" class="mt-16 mb-16"></mat-divider>
    <div class="container" *ngFor="let notification of notifications" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="end end">
        <button *ngIf="notification.isRead" mat-icon-button
          matTooltip="{{ 'notifications.form.markAsUnread' | translate }}" matTooltipPosition="above"
          (click)="notificationAction(notification._id, 'markAsUnread')"><mat-icon
            style="color:gray!important">visibility_off</mat-icon></button>
        <button *ngIf="!notification.isRead" mat-icon-button
          matTooltip="{{ 'notifications.form.markAsRead' | translate }}" matTooltipPosition="above"
          (click)="notificationAction(notification._id, 'markAsRead')"><mat-icon
            style="color:gray!important">visibility</mat-icon></button>
        <button mat-icon-button matTooltip="{{ 'notifications.form.delete' | translate }}" matTooltipPosition="above"
          (click)="notificationAction(notification._id, 'markAsDelete')"><mat-icon
            style="color:gray!important">delete</mat-icon></button>
      </div>
      <div fxLayout="row">
        <div class="p-8" fxLayout="column" fxFlex="100">
          <div class="subtitle" fxLayoutAlign="start start" fxFlex="90">
            {{ notification.title[currentLang] | translate}}
          </div>
          <span class="content pl-8 mt-8" [innerHtml]="notification.content[currentLang] | translate"></span>
          <a *ngIf="notification.actionUrl && notification.actionTitle" [href]="notification.actionUrl"><button
              mat-button fxFlex="100" class="content">{{notification.actionTitle[currentLang]}}</button></a>
        </div>

      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
</mat-menu>