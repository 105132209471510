import { HistoryItem } from "./business-statement.interface";
import { Invoice } from "./invoice.model";
import { Subscription } from "./subscription.interface";
import { User } from "./user.model";

export type UserSubscriptionStatus = 'pre_active' | 'active' | 'expired' | 'cancelled';

export interface UserSubscription {
  _id: string;

  startDate: Date;
  endDate: Date;
  oneTimeStartFee: number;
  duration: string;
  fee: number;

  autoRenewal: boolean;
  status: UserSubscriptionStatus;
  feature: string;

  termsAccepted: boolean;
  dataSourcesAccepted: boolean;
  contractsResignationAccepted: boolean;
  contractsResignationSentAt: Date;

  // Model references
  user: User;
  createdByUser: string;
  operator: string;
  subscription: Subscription;
  invoices: [Invoice];

  createdAt: Date;
  updatedAt?: Date;
  history: [HistoryItem];
};
