import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationFormat'
})
export class DurationFormatPipe implements PipeTransform {
  transform(totalSeconds: number, format: 'short' | 'long' | 'days' = 'long'): string {
    // console.log('DurationFormatPipe: totalSeconds:', totalSeconds, 'format:', format);
    // tranform total seconds to days, hours, minutes, seconds
    // 1 day = 24 hours
    // 1 hour = 60 minutes
    // 1 minute = 60 seconds
    // 1 hour = 3600 seconds
    // 1 day = 86400 seconds
    // 
    // note: this function does not cumulate data so if there is 3666 seconds, this will transform into:
    // 0 days, 1 hour, 1 minute, 6 seconds
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    if (format === 'short') {
      // use short abbreaviations
      let parts = [];
      if (days > 0) parts.push(`${days}d`);
      if (hours > 0) parts.push(`${hours}h`);
      if (minutes > 0) parts.push(`${minutes}m`);
      if (seconds > 0) parts.push(`${seconds}s`);
      return parts.join(' ') || '0s';
    } else if (format === 'long') {
      // use long words
      let parts = [];
      if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`);
      if (hours > 0) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
      if (minutes > 0) parts.push(`${minutes} min${minutes > 1 ? 's' : ''}`);
      if (seconds > 0) parts.push(`${seconds} sec${seconds > 1 ? 's' : ''}`);
      return parts.join(', ') || '0 secs';
    } else {
      // only show day and hour
      let parts = [];
      if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`);
      if (hours > 0) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
      return parts.join(' ') || '0 hours';
    }
  }
}