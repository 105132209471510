
  <button mat-button class="language-button" [matMenuTriggerFor]="languageMenu">
    <div fxLayout="row" fxLayoutAlign="center center">
      <img class="flag" [ngClass.gt-sm]="'mr-8'" [src]="'assets/icons/flags/' + (selectedLanguage?.flag || 'fi') + '.png'">
      <span fxShow fxHide.lt-md class="iso text-uppercase">{{ selectedLanguage?.id || 'fi-FI' }}</span>
    </div>
  </button>
  <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
    <button mat-menu-item *ngFor="let lang of languages" (click)="selectLanguage(lang)">
      <span fxLayout="row" fxLayoutAlign="start center">
        <img class="flag mr-16" [src]="'assets/icons/flags/' + (lang?.flag || 'fi') + '.png'">
        <span class="iso">{{ lang.title || 'Finnish' }}</span>
      </span>
    </button>
  </mat-menu>

