import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, Event, NavigationEnd } from '@angular/router';
import { User, Operator } from '@app/core/models';
import { ApiService, CurrentUserService, ToasterService } from '@app/core/services';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, Subscription, lastValueFrom } from 'rxjs';

const URL_REGEXP = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

@Component({
	selector: 'notification-box',
	templateUrl: './notification-box.component.html',
	styleUrls: ['./notification-box.component.scss']
})
export class NotificationBoxComponent implements OnInit, OnDestroy {
	public title: string;
	public subtitle: string;
	public content: string;
	public showHelp = false;
	public showForm = false;
	public currentLang = 'fi-FI';
	public showError: string = null;
	public notifications = [];
	public notificationsCount = 0;
	public user: User;
	operators$: Observable<Operator[]>;
	isOperatorFieldRequired = false;
	readNotifications: number;
	minDate = new Date();

	private ms30Days = 30 * 24 * 60 * 60 * 1000;
	public newNotificationForm = this._formBuilder.group({
		actionUrl: new UntypedFormControl('', Validators.pattern(URL_REGEXP)),
		startsAt: new UntypedFormControl(new Date()),
		expiresAt: new UntypedFormControl(new Date(new Date().getTime() + this.ms30Days)),
		operator: new UntypedFormControl('')
	}, { validators: this.rangeDateValidator });

	private sub: Subscription;
	private subRoute: Subscription;
	constructor(
		private router: Router,
		private _formBuilder: UntypedFormBuilder,
		private _apiService: ApiService,
		private _translateService: TranslateService,
		private _currentUserService: CurrentUserService,
		private _toasterService: ToasterService
	) { }

	ngOnInit(): void {
		this.user = this._currentUserService.getUser();
		this.currentLang = this._translateService.currentLang;

		this.isOperatorFieldRequired = ['root-admin', 'root-finance', 'root-support'].includes(this.user.role);

		if (this.isOperatorFieldRequired) {
			this.operators$ = this._apiService.getOperators('', { listType: 'filter' });
		}

		this.getNotifications();

		// To detect lang change
		this.sub = this._translateService.onLangChange
			.subscribe((event: LangChangeEvent) => {
				this.currentLang = event.lang;
			});

		this.subRoute = this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				console.log('Route changed: ', event.urlAfterRedirects);
				this.hideHelp();
			}
		});
	}

	rangeDateValidator(formGroup: UntypedFormGroup) {
		const condition2 = formGroup.get('startsAt')!.value > formGroup.get('expiresAt')!.value;
		formGroup.controls['expiresAt'].setErrors(condition2 ? { mustBeOverStartDate: true } : null);
	}

	hideHelp() {
		this.showHelp = false;
	}

	getNotifications() {
		lastValueFrom(this._apiService.getNotifications(this.user.publicId))
			.then(result => {
				console.log('getNotifications()', result);
				let readedNotifications = result.readAndDeletedNotifications.totalReadBy;
				if (readedNotifications === undefined) {
					readedNotifications = 0;
				}
				this.notifications = result.notifications;
				this.notificationsCount = this.notifications.length - readedNotifications;
			});
	}

	openForm(event: any = undefined) {
		this.showForm = !this.showForm;
		event.stopPropagation();
	}

	async createNotification() {
		const data = this.newNotificationForm.value;
		const hasTitle = Object.values(data.title).every(it => String(it).trim() !== "");
		const hasContent = Object.values(data.content).every(it => String(it).trim() !== "");
		const hasActionTitle = Object.values(data.actionTitle).every(it => String(it).trim() !== "");
		this.showError = null;
		if (!hasTitle) {
			this.showError = 'notifications.errors.titleRequired';
			return;
		}
		if (!hasContent) {
			this.showError = 'notifications.errors.contentRequired';
			return;
		}
		if (!hasActionTitle && data.actionUrl) {
			this.showError = 'notifications.errors.actionTitleRequired';
			return;
		}
		if (hasActionTitle && !data.actionUrl) {
			this.showError = 'notifications.errors.actionUrlRequired';
			return;
		}
		if (this.newNotificationForm.valid && hasTitle && hasContent) {
			await lastValueFrom(this._apiService.createNotification(data));
			this.showForm = false;
			this.newNotificationForm.reset();
			this.notificationsCount++;
			this.hideHelp();
			this.getNotifications();
			this._toasterService.showToaster('notifications.form.success', { duration: 'long', type: 'success' });

		}
	}

	async notificationAction(id, action) {
		console.log('notificationAction()');
		const result = lastValueFrom(this._apiService.notificationAction(id, this.user.publicId, action));
		result
			.finally(() => {
				this.getNotifications();
			})
	}


	ngOnDestroy() {
		this.sub.unsubscribe();
		this.subRoute.unsubscribe();
	}
}
