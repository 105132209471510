export class Campaign {
  _id?: string;
  code?: string;
  status?: string;
  createdAt?: Date;
  startsAt?: Date;
  expiresAt?: Date;
  description?: string;
  createdByUser?: string;
  operator?: string;

  /**
   * Constructor
   *
   * @param expense
   */
  constructor( campaign? )
  {
    this._id = campaign._id || '';
    this.code = campaign.code;
    this.status = campaign.status || 'draft';
    this.createdAt = campaign.createdAt || new Date();
    this.startsAt = campaign.startsAt  || new Date();
    this.expiresAt = campaign.expiresAt;
    this.description = campaign.description || '';
    this.createdByUser = campaign.createdByUser;
    this.operator = campaign.operator;
  }
}
