export class Insurance {
  _id?: string;
  uuid?: string;
  type?: string;
  translationTagPath?: string;
  title?: string;
  description?: string;
  startDate?: Date;
  endDate?: Date;
  status?: string;
  feeInEur?: number;
  feeInPct?: number;
  additionalData?: any;
  ownedByUser?: string;
  createdAt?: Date;

  /**
   * Constructor
   *
   * @param insurance
   */
  constructor(insurance?) {
    this._id = insurance._id || '';
    this.uuid = insurance.uuid || '';
    this.type = insurance.type || '';
    this.translationTagPath = insurance.translationTagPath || '';
    this.title = insurance.title || '';
    this.description = insurance.description || '';
    this.additionalData = insurance.additionalData || {};
    this.startDate = insurance.startDate;
    this.endDate = insurance.endDate;
    this.status = insurance.status;
    this.ownedByUser = insurance.ownedByUser;
    this.createdAt = insurance.createdAt;
    this.feeInEur = insurance.feeInEur || 0;
    this.feeInPct = insurance.feeInPct || 0;
  }
}
