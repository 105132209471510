import { Invoice, Expense, BankTransferTransaction, UserFee, HistoryItem } from '@app/core/models';

export class OtherReduction {
  description?: string;
  amount?: number;
}

export class Payout {
  _id?: string;
  createdAt?: Date;
  uuid?: string;
  publicId?: string;
  amount?: number;
  currency?: string;
  description?: string;
  status?: string;
  additionalData?: any;
  debtCollection?: number;
  debtCollectionOption?: string;
  debtCollectionEnabled?: boolean;
  debtCollectionReference?: string;
  isUrgentBankTransfer?: boolean;
  grossSalary?: number;
  netSalary?: number;
  personTaxes?: number;
  salaryPayoutGross?: number;
  totalNet?: number;
  taxableIncome?: number;
  socialSecurity?: number;
  insuranceFee?: number;
  accidentFee?: number;
  liabilityFee?: number;
  bonusToBeUsed?: number;
  totalDeductions?: number;
  serviceFee?: number;
  totalUserFees?: number;
  serviceFeeEditable?: boolean;
  quickSalaryServiceFee?: number;
  quickSalaryServiceFeeEditable?: number;
  deductionGenericCost?: number;
  veroWageReport?: any;
  veroWageReportResponse?: any;
  veroWageReportErrors?: any;
  salaryYelMonthlyReduction?: number;
  calculatedYel?: number;

  taxRate?: any;

  payoutToUser?: any;
  invoices?: [Invoice];
  deductions?: [Expense];
  userFees?: [UserFee];
  bankTransfers?: [BankTransferTransaction];

  otherReductions?: OtherReduction[];
  paidAt?: Date;
  preCumulativeYtd?: any;


  // virtuals
  expenseTotalsNetTaxFree?: number;
  totalCostsForGeneratingIncome?: number;
  salaryPaid?: number;
  reductionsFromNetSalary?: number;
  otherReductionsAmount?: number;
  bankTransferAmount?: number;

  history: [HistoryItem];

  /**
   * Constructor
   *
   * @param payout
   */
  constructor(payout?) {
    this._id = payout._id || '';
    this.createdAt = payout.createdAt || new Date();
    this.uuid = payout.uuid || '';
    this.publicId = payout.publicId || '';
    this.amount = Number(payout.amount || 0.00);
    this.currency = payout.currency || 'EUR';  // TODO: get default currency from operator config?
    this.description = payout.description || '';
    this.status = payout.status;
    this.additionalData = payout.additionalData || {};
    this.debtCollection = payout.debtCollection || 0;
    this.debtCollectionOption = payout.debtCollectionOption || 'from-net-income';
    this.debtCollectionEnabled = payout.debtCollectionEnabled || false;
    this.debtCollectionReference = payout.debtCollectionReference || '';
    this.taxRate = payout.taxRate || 0;
    this.isUrgentBankTransfer = payout.isUrgentBankTransfer || false;
    this.grossSalary = payout.grossSalary || 0;
    this.netSalary = payout.netSalary || 0;
    this.personTaxes = payout.personTaxes || 0;
    this.salaryPayoutGross = payout.salaryPayoutGross || 0;
    this.totalNet = payout.totalNet || 0;
    this.taxableIncome = payout.taxableIncome || 0;
    this.socialSecurity = payout.socialSecurity || 0;
    this.totalDeductions = payout.totalDeductions || 0;
    this.serviceFee = payout.serviceFee || 0;
    this.totalUserFees = payout.totalUserFees || 0;
    this.serviceFeeEditable = payout.serviceFeeEditable || false;
    this.bonusToBeUsed = payout.bonusToBeUsed || 0;
    this.insuranceFee = payout.insuranceFee || 0;
    this.accidentFee = payout.accidentFee || 0;
    this.liabilityFee = payout.liabilityFee || 0;
    this.quickSalaryServiceFee = payout.quickSalaryServiceFee || 0;
    this.quickSalaryServiceFeeEditable = payout.quickSalaryServiceFeeEditable || false;
    this.deductionGenericCost = payout.deductionGenericCost || 0;
    this.veroWageReport = payout.veroWageReport;
    this.veroWageReportErrors = payout.veroWageReportErrors;
    this.salaryYelMonthlyReduction = payout.salaryYelMonthlyReduction || 0;
    this.calculatedYel = payout.calculatedYel || 0;

    this.payoutToUser = payout.payoutToUser;
    this.invoices = payout.invoices || [];
    this.deductions = payout.deductions || [];
    this.userFees = payout.userFees || [];
    this.bankTransfers = payout.bankTransfers || [];

    this.otherReductions = payout.otherReductions || [];
    this.paidAt = payout.paidAt || new Date();
    this.preCumulativeYtd = payout.preCumulativeYtd || {};

    this.history = payout.history || [];

    // virtuals
    this.expenseTotalsNetTaxFree = Number(payout.expenseTotalsNetTaxFree || 0);
    this.totalCostsForGeneratingIncome = Number(payout.totalCostsForGeneratingIncome || 0);
    this.salaryPaid = Number(payout.salaryPaid || 0);
    this.reductionsFromNetSalary = Number(payout.reductionsFromNetSalary || 0);
    this.otherReductionsAmount = Number(payout.otherReductionsAmount || 0);
    this.bankTransferAmount = Number(payout.bankTransferAmount || 0);
  }
}
