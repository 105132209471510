<div fxHide.gt-xs class="mr-6 ml-6" >
  <button mat-icon-button (click)="toggleVisibility()">
    <mat-icon *ngIf="(showField === false && !selectedOperator)">supervised_user_circle </mat-icon>
    <mat-icon *ngIf="(showField === false && selectedOperator)" class="changeColorIcon">warning</mat-icon>
    <mat-icon *ngIf="(showField === true && !selectedOperator)">supervised_user_circle </mat-icon>
    <mat-icon *ngIf="(showField === true && selectedOperator)"  class="changeColorIcon">warning</mat-icon>
  </button>
</div>

<div *ngIf="showField" [ngClass]="currentUser.role === 'root-admin' ? 'right-border-radius search-wrapper' : 'search-wrapper'" id="search-operator">
  <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
    <mat-icon fxHide.xs *ngIf="!selectedOperator" >business</mat-icon>
    <mat-icon fxHide.xs *ngIf="selectedOperator" class="selected">warning</mat-icon>
    <input class="fontFamily" [readOnly]="selectedOperator" type="text" placeholder="{{ 'toolbar.selectOperator' | translate }}" aria-label="Select operator" matInput [formControl]="operatorsCtrl" [matAutocomplete]="auto">
    <mat-autocomplete class="w-100-p" #auto="matAutocomplete" (optionSelected)="selectOperator($event)">
      <mat-option value="">{{ 'forms.selectAllOptionLabel' | translate }}</mat-option>
      <mat-option *ngFor="let operator of operators$ | async" [value]="operator._id"  style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;">
        {{ operator.shortName }}
      </mat-option>
    </mat-autocomplete>
    <button *ngIf="selectedOperator" mat-icon-button>
      <mat-icon *ngIf="selectedOperator" (click)="selectOperator()">clear</mat-icon>
    </button>
  </div>
</div>

