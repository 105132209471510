import { NgModule } from '@angular/core';

import { SplitNameBySpace } from 'app/shared/pipes/split-name-by-space.pipe';
import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { DurationFormatPipe } from './durationFormat.pipe';
import { SafeResourceUrlPipe } from './safeResourceUrl.pipe';

const toDeclareExport = [
    KeysPipe,
    GetByIdPipe,
    HtmlToPlaintextPipe,
    FilterPipe,
    CamelCaseToDashPipe,
    SplitNameBySpace,
    DurationFormatPipe,
    SafeResourceUrlPipe
];

@NgModule({
    declarations: [
        ...toDeclareExport
    ],
    imports: [],
    exports: [
        ...toDeclareExport
    ]
})
export class FusePipesModule {
}
