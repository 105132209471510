import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Observable, of, lastValueFrom } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  catchError,
  filter
} from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

import { MatAutocomplete } from '@angular/material/autocomplete';
import { User } from '@app/core/models';

import { FiltersService, ApiService, CurrentUserService, ToasterService } from '@app/core/services';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { MediaObserver } from '@angular/flex-layout';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})

export class UserFilterComponent implements OnInit {
  usersCtrl = new UntypedFormControl('');
  users$: Observable<any[]>;

  currentUser: User;
  selectedUser$: Observable<boolean>;

  filteredUsers: any[];
  selectedUser: boolean;
  showField: boolean = false;
  isOperatorCompanyUser: boolean = false;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private filtersService: FiltersService,
    private apiService: ApiService,
    private currentUserService: CurrentUserService,
    private _fuseMatchMediaService: FuseMatchMediaService,
    private _mediaObserver: MediaObserver,
    private _platform: Platform,
    private _toasterService: ToasterService
  ) { }

  ngOnInit(): void {
    console.log('UserFilterComponent ngOnInit()');
    this.currentUser = this.currentUserService.getUser();
    // console.log('UserFilterComponent ngOnInit() currentUser:', this.currentUser);

    this.users$ = this.usersCtrl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(value => value !== null && value.length > 0),
        switchMap((search) =>
          lastValueFrom(this.apiService.getUsers(search, { listType: 'filter' }))
            .catch(e => {
              this._toasterService.showToaster((e.translationKey || 'generic') + '.valueNotAllowed', { type: 'error' });
              return of(null);
            })
            .then(response => response)
        ),
        tap(users => this.filteredUsers = users),
        catchError(error => of(null))
      );

    this.toggleVisibility();

    this.filtersService.selectedUser
      .subscribe((status) => {
        this.selectedUser = status;
      });

    this.filtersService.selectedUserText
      .subscribe((userText) => {
        console.log('userText', userText);
        this.usersCtrl.setValue(userText);
      });

    // Subscribe to media changes
    this._fuseMatchMediaService.onMediaChange
      .subscribe(() => {
        this.showField = this._mediaObserver.isActive('gt-xs') ? true : false;
      });
  }

  getUser() {
    return this.filtersService.userValue;
  }

  toggleVisibility() {
    this.showField = !this.showField;
  }

  onUserSelect(event) {
    if (['freelancer', 'soletrader', 'company', 'taxi-company', 'taxi-driver'].includes(this.currentUser?.role)) {
      console.log('selectUser() do nothing; normal user');
      return;
    }

    let userId = event?.option.value;
    if (userId) {
      console.log('selectUser() userId:', userId);
      let userSelected = this.filteredUsers.find(user => user._id === userId);
      this.filtersService.setSelectedUserStatus(true);
      this.filtersService.getSelectedUserInfo(userSelected, userId, this.currentUser.role);
    }

    if (!this._platform.isBrowser && (this._platform.ANDROID || this._platform.IOS)) {
      console.log('selectUser() is mobile --> toggle visibility');
      this.document.body.classList.add('is-mobile');
      this.toggleVisibility();
    }
  }

  unselectUser() {
    this.filtersService.clearUser(false, this.currentUser.role);
    this.isOperatorCompanyUser = false;
    console.log('selectUser() clear user and trigger data update..');
    this.filtersService.remove();
  }

  selectOperatorCompanyUser() {
    console.log('selectOperatorCompanyUser() event:', event);
    console.log('selectOperatorCompanyUser() currentUser:', this.currentUser);
    const operatorCompanyUser = this.currentUser?.operator?.rootConfiguration?.operatorCompanyUser;

    // select operator company user if not selected user, if already selected user, clear selection
    if (!this.selectedUser && operatorCompanyUser) {
      console.log('selectOperatorCompanyUser() select operator company user..');
      console.log('selectOperatorCompanyUser() operatorCompanyUser:', operatorCompanyUser);
      this.filtersService.selectUser(operatorCompanyUser, this.currentUser.role);
      this.isOperatorCompanyUser = true;
    } else {
      console.log('selectOperatorCompanyUser() clear selected user..');
      this.filtersService.clearUser(false, this.currentUser.role);
      this.filtersService.remove();
    }
  }

}
