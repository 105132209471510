export class Competence {
  _id?: string;
  createdAt?: Date;
  
  title?: string;

  /**
   * Constructor
   *
   * @param competence
   */
  constructor( competence? )
  {
    this._id = competence._id || '';
    this.createdAt = competence.createdAt || new Date();
    this.title = competence.title || '';
  }
}
