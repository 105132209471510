import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/core/services';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const PUBLIC_ROUTES = ['client-review'];
    const isPublicRoute = PUBLIC_ROUTES.some(route => state.url.includes(route));
    if (this.authenticationService.isAuthenticated() || isPublicRoute) {
      console.log('AuthGuard/canActivate() is authenticated');
      return true;
    } else {
      console.log('AuthGuard/canActivate() is not authenticated nor public route; isPublicRoute:', isPublicRoute, 'state.url:', state.url);
      // Do not include redirection to dashboard as it is default route after login.
      let queryParams = !state.url.includes('dashboard') ? { returnUrl: state.url } : route.queryParams;

      // DEBUG DEBUG DEBUG
      // console.log('AuthGuard/canActivate() route:', route);
      // console.log('AuthGuard/canActivate() state:', state);

      console.log('AuthGuard/canActivate() navigate to login page; queryParams:', queryParams);
      this.router.navigate(['/auth/login'], { queryParams });
      return false;
    }
  }
}
