import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl
} from '@angular/forms';
import { lastValueFrom } from 'rxjs';

import { AppSettingsConfig } from '@app/core/configs/app-settings.config';
import { ApiService, ToasterService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';

@Component({
  selector: 'app-translate-field-form',
  templateUrl: './translate-field-form.component.html',
  styleUrls: ['./translate-field-form.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FuseSharedModule,
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TranslateFieldFormComponent implements OnInit {
  languages: string[];
  lang: string;
  @Input() translatedFieldContent: any;
  @Input() translationKeyLabel: string;
  @Input() controlName: string;
  @Input() formGroup: UntypedFormGroup;
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private appConfig: AppSettingsConfig,
    private _apiService: ApiService,
    private _toasterService: ToasterService,
    private _translateService: TranslateService,
    private ctrlContainer: FormGroupDirective
  ) { }

  ngOnInit(): void {
    this.lang = this._translateService.currentLang;
    this.languages = AppSettingsConfig.supportedLanguages;
    this.form = this.formGroup || this.ctrlContainer.form;

    this.form.addControl(this.controlName, this.createLanguageForm());
  }

  createLanguageForm() {
    let formGroup = this.fb.group({});
    this.languages.map(lang => {
      formGroup.addControl(lang, new UntypedFormControl((this.translatedFieldContent && this.translatedFieldContent[lang]) || ''))
    })
    return formGroup;
  }

  async translateText(formControlName: string, locale: string) {
    // use API service to translate text (postAiTranslate)

    let formData = this.form.get(formControlName).value;
    let text = formData[locale];
    console.log('translateText() text:', text);

    try {
      let translations = await lastValueFrom(this._apiService.postAiTranslate(text, locale));
      console.log('translateText() translations:', translations);

      // set translated text to form control
      this.form.get(formControlName).setValue(translations);
    } catch (err) {
      // log error and show error toaster
      console.log('translateText() catchl; err:', err);
      this._toasterService.showToaster(err.translationKey || err.message || 'Something went wrong!', { type: 'error', duration: 'long' });
    }
  }
}
