import { NgModule } from '@angular/core';

import { registerLocaleData } from '@angular/common';

import localeEn from '@angular/common/locales/en';
import localeFi from '@angular/common/locales/fi';
import localeSv from '@angular/common/locales/sv';
import localeEt from '@angular/common/locales/et';
import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeEn, 'en-US');
registerLocaleData(localeFi, 'fi-FI');
registerLocaleData(localeSv, 'sv-SE');
registerLocaleData(localeEt, 'et-EE');
registerLocaleData(localeRu, 'ru-RU');

@NgModule({
  imports: [],
  exports: [],
  declarations: []
})
export class LocaleModule { }
