import { User, Operator, Payout, Settlement } from '@app/core/models';

export class BankTransferTransaction {
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  uuid?: string;
  status?: 'created' | 'sent' | 'completed' | 'reviewing' | 'failed' | 'duplicate' | 'rejected';
  type?: string;
  recipientName?: string;
  recipientIban?: string;
  recipientBic?: string;
  reference?: string;
  message?: string;
  amount?: number;
  currency?: string;
  isPayImmediately?: boolean;
  remark?: string;
  processingErrors?: object[];
  additionalData?: object;

  // Model references
  relatedToUser?: User;
  operator?: Operator;
  payout?: Payout;
  settlement?: Settlement;

  /**
   * Constructor
   *
   * @param transaction
   */
  constructor(transaction?) {
    this._id = transaction._id;
    this.createdAt = transaction.createdAt || '';
    this.updatedAt = transaction.updatedAt || '';
    this.uuid = transaction.uuid || '';
    this.status = transaction.status || 'created';
    this.type = transaction.type || 'SEPA';
    this.recipientName = transaction.recipientName;
    this.recipientIban = transaction.recipientIban;
    this.recipientBic = transaction.recipientBic;
    this.reference = transaction.reference;
    this.message = transaction.message;
    this.amount = transaction.amount;
    this.currency = transaction.currency || 'EUR';
    this.isPayImmediately = transaction.isPayImmediately || false;
    this.remark = transaction.remark;
    this.processingErrors = transaction.processingErrors || [];
    this.additionalData = transaction.additionalData;

    this.relatedToUser = transaction.relatedToUser;
    this.operator = transaction.operator;
    this.payout = transaction.payout;
    this.settlement = transaction.settlement;
  }
}
