import { User } from './user.model';
import { Operator } from './operator.model';

export class Expenditure {
  _id?: string;
  createdAt?: Date;
  uuid?: string;
  idNumber?: number;
  date?: Date;
  status?: string;
  expenditureType?: string;
  expenditureSubType?: string;
  quantity?: number;
  netPrice?: number;
  vatClass?: string;
  vatRate?: number;
  vatPrice?: number;
  grossPrice?: number;
  currency?: string;
  reference?: string;
  description?: string;
  acceptablePct?: number;
  justificationReason?: string;
  sellerOrigin?: string;
  sellerCompanyVatId?: string;
  additionalData?: any;
  // references
  ownedByUser?: User;
  operator?: Operator;
  //virtuals
  totalNet?: number;
  totalVat?: number;
  totalGross?: number;
  filepath?: string;

  // product, service, other, representation
  fileId?: string | null;

  // representation
  participants?: string;
  place?: string;

  // TODO: travel-kilometers, daily, meal
  // Travel-kilometers
  travelFrom?: any;
  travelTo?: any;
  isBackAndForth?: boolean;
  travelBy?: string;
  licensePlate?: string;
  reason?: string;
  waypoints?: any;
  isWithSpecialIndustryFees?: boolean;
  skipGoogleMap?: boolean;

  // Daily-allowance
  startTime?: Date;
  endTime?: Date;
  streetAddress?: string;
  countryCode?: string;

  // Meal-allowance
  isTwice?: boolean;


  /**
   * Constructor
   *
   * @param expenditure
   */
  constructor( expenditure? )
  {
    this._id = expenditure._id || '';
    this.createdAt = expenditure.createdAt || new Date();
    this.uuid = expenditure.uuid || '';
    this.idNumber = expenditure.idNumber;
    this.date = expenditure.date || new Date();
    this.status = expenditure.status || 'created';
    this.expenditureType = expenditure.expenditureType;
    this.expenditureSubType = expenditure.expenditureSubType;
    this.quantity = expenditure.quantity || 1;
    this.netPrice = expenditure.netPrice || 0;
    this.vatClass = expenditure.vatClass || 'vat25_5';
    this.vatRate = expenditure.vatRate || 25.5;
    this.vatPrice = expenditure.vatPrice || 0;
    this.grossPrice = expenditure.grossPrice || 0;
    this.currency = expenditure.currency || 'EUR';
    this.reference = expenditure.reference || '';
    this.description = expenditure.description || '';
    this.acceptablePct = expenditure.acceptablePct || 0;
    this.justificationReason = expenditure.justificationReason || '';
    this.sellerOrigin = expenditure.sellerOrigin || 'fi';
    this.sellerCompanyVatId = expenditure.sellerCompanyVatId || '';
    this.additionalData = expenditure.additionalData || {};
    this.ownedByUser = expenditure.ownedByUser;
    this.operator = expenditure.operator;
    this.totalNet = expenditure.totalNet || 0;
    this.totalVat = expenditure.totalVat || 0;
    this.totalGross = expenditure.totalGross || 0;
    this.filepath = expenditure.filepath || '';
    this.participants = expenditure.participants || '';
    this.place = expenditure.place || '';
  }
}
