import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})

export class SplitNameBySpace implements PipeTransform {

    transform(text: string, by: string, index: number = text.split(by).length-1) {
    let arr = text.split(by);
    return arr[index]
  }
}