import { HistoryItem, Operator, User } from "@app/core/models"

export interface BusinessTaxReturn {
  _id: string;
  uuid: string,
  publicId: string,
  description: string,
  status: string,
  report: any;
  approvedAt: Date,
  rejectedAt: Date,
  rejectMessage: string;
  sentAt: Date,
  year: number,
  autoApproved: boolean;


  history: [HistoryItem],
  additionalData: any,

  veroCalcs: any,
  isVeroReported: boolean,
  isVeroCanceled: boolean,
  veroReportResponse: any,
  veroReport: any,
  veroReplacementNumber: number,
  veroDeliveryFilename: string,

  // Mongoose references
  businessTaxReturnToUser: User,
  operator: Operator

};
