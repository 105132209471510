import { RowItem, User, Operator, Client, Payout, Settlement, Expense, WorkHours } from '@app/core/models';

export class Invoice {
  _id?: string;
  createdAt?: Date;
  uuid?: string;
  invoiceNumber?: number;
  invoiceId?: string;
  type?: string;
  status?: string;
  invoiceDate?: Date;
  dueInDays?: number;
  dueDate?: Date;
  invoiceStartAt?: Date;
  invoiceEndAt?: Date;
  totalNet?: number;
  totalVat?: number;
  totalVatBreakdown?: any;
  vatClass?: string;
  totalGross?: number;
  currency?: string;
  description?: string;
  recipientReference?: string;
  senderReference?: string;
  rowItems?: RowItem[];
  feeItems?: RowItem[];
  totalFeeItemsNet?: number;
  totalFeeItemsVat?: number;
  totalFeeItemsGross?: number;
  totalNetWithFees?: number;
  totalGrossWithFees?: number;
  expenseItems?: Expense[];
  deductions?: Expense[];
  additionalData?: any;
  history?: any;
  paymentMethod?: string;
  paymentTerms?: string;
  paymentTransactionUuid?: string;
  bankReference?: string;
  virtualBarCode?: string;
  paidAt?: Date;
  lastPaymentAt?: Date;
  amountPendingAtLastPayment?: number;
  amountPaidAtLastPayment?: number;
  paymentActivities?: any[];
  refundedAt?: Date;
  rejectMessage?: string;
  cancelMessage?: string;
  refundMessage?: string;
  refundedInvoiceNumber?: number;
  quickSalaryEnabled?: boolean;
  quickSalaryByOperator?: boolean;
  automaticPayoutApprovalEnabled?: boolean;
  creditedInvoice?: Invoice;
  ownedByUser?: User;
  createdByUser?: User;
  payout?: Payout;
  settlement?: Settlement;
  groupInvoice?: Invoice;
  sentByOperator?: Operator;
  recipientClient?: Client;
  totalExpensesSum?: any;
  financeInfoComment?: string;
  tags?: [string];
  attachments?: [string];
  freelancerComments?: string;
  invoiceRemindersEnabled?: boolean;
  sendVia?: string;
  sendLocale: string;
  isClientReviewEnabled: boolean;
  clientReviewResponse: string;
  clientReviewData: {
    userIp: string,
    userAgent: string,
    date: Date
  };
  processingErrors?: [any];
  workHoursItems?: WorkHours[];
  isDueDateMissed?: boolean;

  /**
   * Constructor
   *
   * @param invoice
   */
  constructor(invoice?) {
    this._id = invoice?._id;
    this.createdAt = invoice?.createdAt;
    this.uuid = invoice?.uuid;
    this.invoiceNumber = invoice?.invoiceNumber;
    this.invoiceId = invoice?.invoiceId;
    this.type = invoice?.type || 'standard';
    this.status = invoice?.status;
    this.invoiceDate = invoice?.invoiceDate;
    this.dueInDays = invoice?.dueInDays;
    this.dueDate = invoice?.dueDate;
    this.invoiceStartAt = invoice?.invoiceStartAt;
    this.invoiceEndAt = invoice?.invoiceEndAt;
    this.totalNet = Number(invoice?.totalNet || 0.00);
    this.totalVat = Number(invoice?.totalVat || 0.00);
    this.totalVatBreakdown = invoice?.totalVatBreakdown || {};
    this.totalGross = Number(invoice?.totalGross || 0.00);
    this.vatClass = invoice?.vatClass || 'vat24';
    this.currency = invoice?.currency || 'EUR';  // TODO: get default currency from operator config?
    this.description = invoice?.description || '';
    this.recipientReference = invoice?.recipientReference || '';
    this.senderReference = invoice?.senderReference || '';
    this.rowItems = invoice?.rowItems || [];
    this.feeItems = invoice?.feeItems || [];
    this.totalFeeItemsNet = Number(invoice?.totalFeeItemsNet || 0.00);
    this.totalFeeItemsVat = Number(invoice?.totalFeeItemsVat || 0.00);
    this.totalFeeItemsGross = Number(invoice?.totalFeeItemsGross || 0.00);
    this.totalNetWithFees = Number(invoice?.totalNetWithFees || 0.00);
    this.totalGrossWithFees = Number(invoice?.totalGrossWithFees || 0.00);
    this.expenseItems = invoice?.expenseItems || [];
    this.deductions = invoice?.deductions || [];
    this.additionalData = invoice?.additionalData || {};
    this.history = invoice?.history || [];
    this.paymentMethod = invoice?.paymentMethod;
    this.paymentTerms = invoice?.paymentTerms;
    this.paymentTransactionUuid = invoice?.paymentTransactionUuid;
    this.bankReference = invoice?.bankReference;
    this.virtualBarCode = invoice?.virtualBarCode;
    this.rejectMessage = invoice?.rejectMessage;
    this.cancelMessage = invoice?.cancelMessage;
    this.refundMessage = invoice?.refundMessage;
    this.paidAt = invoice?.paidAt;
    this.lastPaymentAt = invoice?.lastPaymentAt;
    this.amountPendingAtLastPayment = invoice?.amountPendingAtLastPayment;
    this.amountPaidAtLastPayment = invoice?.amountPaidAtLastPayment;
    this.paymentActivities = invoice?.paymentActivities || [];
    this.refundedAt = invoice?.refundedAt;
    this.refundedInvoiceNumber = invoice?.refundedInvoiceNumber;
    this.ownedByUser = invoice?.ownedByUser;
    this.createdByUser = invoice?.createdByUser;
    this.quickSalaryEnabled = invoice?.quickSalaryEnabled;
    this.quickSalaryByOperator = invoice?.quickSalaryByOperator;
    this.totalExpensesSum = invoice?.totalExpensesSum;
    this.financeInfoComment = invoice?.financeInfoComment || '';
    this.automaticPayoutApprovalEnabled = invoice?.automaticPayoutApprovalEnabled;
    this.sentByOperator = invoice?.sentByOperator;
    this.recipientClient = invoice?.recipientClient;
    this.payout = invoice?.payout;
    this.settlement = invoice?.settlement;
    this.tags = invoice?.tags || [];
    this.attachments = invoice?.attachments || [];
    this.freelancerComments = invoice?.freelancerComments || '';
    this.invoiceRemindersEnabled = invoice?.invoiceRemindersEnabled;
    this.sendVia = invoice?.sendVia || 'email';
    this.sendLocale = invoice?.sendLocale || 'fi-FI';
    this.isClientReviewEnabled = invoice?.isClientReviewEnabled;
    this.clientReviewResponse = invoice?.clientReviewResponse;
    this.clientReviewData = invoice?.clientReviewData;
    this.processingErrors = invoice?.processingErrors || [];
    this.workHoursItems = invoice?.workHoursItems || [];
    this.isDueDateMissed = invoice?.isDueDateMissed;
  }
}
