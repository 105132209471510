import { TaxiContractItem } from './taxi-contract-item.model';
import { User } from './user.model';
import { TaxiVehicle } from './taxi-vehicle.model';
import { Operator } from './operator.model';

export class TaxiContract {
  _id?: string;
  contractNumber?: string;
  contractType?: string;
  contractVersion?: number;
  status?: string;
  title?: string;
  description?: string;
  startsAt?: Date;
  endsAt?: Date;
  items?: {
    taxiContractItem: TaxiContractItem | string;
    startsAt: Date;
    endsAt: Date;
    discountInEur: number;
    discountInPct: number;
  }[];
  invoicingFrequency?: string;
  invoicingDayNumber?: number;
  invoiceDueInDays?: number;
  invoiceVatClass?: string;
  terminationConditions?: string;
  history?: {
    time?: Date;
    status?: string;
    event?: string;
    description?: string;
    user?: User | string;
  }[];
  // references
  createdByUser?: User | string;
  taxiCompany?: User | string;
  taxiVehicle?: TaxiVehicle | string;
  operator?: Operator | string;
  // virtuals
  totalNetPrice?: number;
  totalGrossPrice?: number;

  constructor(data?: any) {
    this._id = data?._id;
    this.contractNumber = data?.contractNumber || '';
    this.contractType = data?.contractType || 'business';
    this.contractVersion = data?.contractVersion || 1;
    this.status = data?.status || 'created';
    this.title = data?.title || '';
    this.description = data?.description || '';
    this.startsAt = data?.startsAt ? new Date(data.startsAt) : undefined;
    this.endsAt = data?.endsAt ? new Date(data.endsAt) : undefined;
    // this.items = data?.items?.map((item: any) => item._id ? new TaxiContractItem(item) : item) || [];
    this.items = data?.items?.map((item: any) => ({
      taxiContractItem: item.item?._id ? new TaxiContractItem(item.taxiContractItem) : item.taxiContractItem,
      startsAt: new Date(item.startsAt),
      endsAt: new Date(item.endsAt),
      discountInEur: item.discountInEur || 0,
      discountInPct: item.discountInPct || 0
    })) || [];
    this.invoicingFrequency = data?.invoicingFrequency || 'monthly';
    this.invoicingDayNumber = data?.invoicingDayNumber || 1;
    this.invoiceDueInDays = data?.invoiceDueInDays || 14;
    this.invoiceVatClass = data?.invoiceVatClass || 'vat25_5';
    this.terminationConditions = data?.terminationConditions || '';
    this.history = data?.history || [];
    this.createdByUser = data?.createdByUser?._id ? new User(data.createdByUser) : data?.createdByUser;
    this.taxiCompany = data?.taxiCompany?._id ? new User(data.taxiCompany) : data?.taxiCompany;
    this.taxiVehicle = data?.taxiVehicle?._id ? new TaxiVehicle(data.taxiVehicle) : data?.taxiVehicle;
    this.operator = data?.operator?._id ? new Operator(data.operator) : data?.operator;
    this.totalNetPrice = data?.totalNetPrice || 0;
    this.totalGrossPrice = data?.totalGrossPrice || 0;
  }
}
