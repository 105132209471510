import { HistoryItem } from './business-statement.interface';

// export interface HistoryItem {
//   time: Date;
//   status: string;
//   event: string;
//   description: string;
//   user?: string; // MongoDB ObjectId as string
// }

export interface SourceOfIncomeData {
  identifier: string;
  factoringPartnerCommissionInPct?: number;
  factoringPartnerCommissionInEur?: number;
  estimatedNumOfDaysForPayment?: number;
  fundingAmountAdvanceInPct?: number;
  fundingAmountTotalInPct?: number;
  fundingFeeInEur?: number;
  fundingFeeInPctAnnual?: number;
  fundingFeeInPct?: number;
}

export interface FactoringPaymentItem {
  invoice?: string; // MongoDB ObjectId as string
  income?: string; // MongoDB ObjectId as string
  identifier: string;
  sourceOfIncomeData: SourceOfIncomeData;
  totalGross?: number;
  factoringPartnerCommission?: number;
  fundingFee?: number;
  fundingAmountAdvance?: number;
  fundingAmountTotal?: number;
  fundingAmountRemaining?: number;
}

export interface Totals {
  totalCount?: number;
  totalGross?: number;
  factoringPartnerCommission?: number;
  fundingFee?: number;
  fundingAmountAdvance?: number;
  fundingAmountTotal?: number;
}

export type FactoringPaymentStatus = 'created' | 'approved' | 'rejected' | 'pending' | 'completed' | 'failed';

export interface FactoringPayment {
  _id?: string;
  factoringItems: FactoringPaymentItem[];
  totals: Totals;
  date?: Date;
  status: FactoringPaymentStatus;
  publicId?: string;
  uuid?: string;
  paidAt?: Date;
  user: string; // MongoDB ObjectId as string
  operator: string; // MongoDB ObjectId as string
  bankTransferTransaction?: string; // MongoDB ObjectId as string
  createdAt?: Date;
  updatedAt?: Date;
}

// Type guard to check if an object is a FactoringPayment
export function isFactoringPayment(obj: any): obj is FactoringPayment {
  return obj
    && typeof obj.status === 'string'
    && Array.isArray(obj.factoringItems)
    && typeof obj.user === 'string'
    && typeof obj.operator === 'string'
    && obj.totals !== undefined;
}

export class FactoringPaymentModel implements FactoringPayment {
  _id?: string;
  factoringItems: FactoringPaymentItem[];
  totals: Totals;
  date?: Date;
  status: FactoringPaymentStatus;
  publicID?: string;
  uuid?: string;
  paidAt?: Date;
  user: string;
  operator: string;
  bankTransferTransaction?: string;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(data?: Partial<FactoringPayment>) {
    console.log('FactoringPaymentModel.constructor()', data);

    if (!data) {
      throw new Error('FactoringPayment data is required');
    }

    // Required fields validation
    if (!data.status) {
      throw new Error('FactoringPayment status is required');
    }
    if (!data.user) {
      throw new Error('FactoringPayment user is required');
    }
    if (!data.operator) {
      throw new Error('FactoringPayment operator is required');
    }

    // Initialize with defaults
    this.factoringItems = [];
    this.totals = {};

    // Assign all properties
    Object.assign(this, {
      ...data,
      // Convert date strings to Date objects
      date: data.date ? new Date(data.date) : undefined,
      paidAt: data.paidAt ? new Date(data.paidAt) : undefined,
      createdAt: data.createdAt ? new Date(data.createdAt) : undefined,
      updatedAt: data.updatedAt ? new Date(data.updatedAt) : undefined,
    });

    // Validate status enum
    if (!['created', 'approved', 'rejected', 'pending', 'completed', 'failed'].includes(this.status)) {
      throw new Error(`Invalid status: ${this.status}`);
    }

    console.log('FactoringPaymentModel constructed successfully');
  }
}
