<div fxHide.gt-xs class="mr-6 ml-6">
  <button mat-icon-button (click)="toggleVisibility()">
    <mat-icon *ngIf="(showField === false && !selectedUser)">supervised_user_circle </mat-icon>
    <mat-icon *ngIf="(showField === false && selectedUser)" class="changeColorIcon">warning</mat-icon>
    <mat-icon *ngIf="(showField === true && !selectedUser)">supervised_user_circle </mat-icon>
    <mat-icon *ngIf="(showField === true && selectedUser)" class="changeColorIcon">warning</mat-icon>
  </button>
</div>

<div *ngIf="showField" class="search-users-box"
  [ngClass]="currentUser.role === 'root-admin' ? 'left-border-radius search-wrapper' : 'search-wrapper'"
  id="search-user">
  <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
    <mat-icon fxHide.xs *ngIf="!selectedUser">supervised_user_circle</mat-icon>
    <mat-icon fxHide.xs *ngIf="selectedUser" class="selected">warning</mat-icon>
    <input class="fontFamily" [readOnly]="selectedUser" type="text" placeholder="{{ 'toolbar.selectUser' | translate }}"
      aria-label="Select user" matInput [formControl]="usersCtrl" [matAutocomplete]="auto">
    <mat-autocomplete class="w-100-p users-list" #auto="matAutocomplete" (optionSelected)="onUserSelect($event)">
      <mat-option value="">{{ 'forms.selectAllOptionLabel' | translate }}</mat-option>
      <mat-option *ngFor="let user of users$ | async" [value]="user._id"
        style="min-height: 48px; line-height: normal; height: auto; padding: 10px 20px;">
        <div *ngIf="user.businessProfile?.businessName?.length > 0">
          <span>{{ user.businessProfile?.businessName }}</span>
          <small *ngIf="user.userProfile?.fullName?.length > 0">{{ ' · ' + user.userProfile?.fullName }}</small>
        </div>
        <div *ngIf="!user.businessProfile?.businessName || user.businessProfile?.businessName?.length === 0">
          <span>{{ user.userProfile?.fullName || user.username }}</span>
          <small *ngIf="user.businessProfile?.businessName?.length > 0">{{ ' · ' + user.businessProfile.businessName
            }}</small>
        </div>
        <small>{{ user.userProfile?.city ? ' · ' + user.userProfile?.city : ''}}</small>
        <small>{{ user.publicId ? ' · ' + user.publicId : '' }}</small>
        <br />
        <small *ngIf="['root-admin', 'root-support'].includes(currentUser.role)"> {{ user.operator.shortName }}</small>
      </mat-option>
    </mat-autocomplete>
    <button mat-icon-button>
      <mat-icon *ngIf="selectedUser" (click)="unselectUser()">clear</mat-icon>
    </button>
  </div>

  <!-- for operator, show "select my company user" button-->
  <button
    *ngIf="(currentUser.isRoot || currentUser.isAdmin) && currentUser?.operator?.rootConfiguration?.operatorCompanyUser"
    mat-icon-button class="mt-4 mr-4" [ngClass]="isOperatorCompanyUser ? 'warn' : 'btnOperatorCompany'">
    <mat-icon (click)="selectOperatorCompanyUser()">business</mat-icon>
  </button>
</div>