import { User, Operator } from '@app/core/models';

export class Job {
  _id?: string;
  createdAt?: Date;

  status?: string;
  source?: string;
  title?: string;
  description?: string;
  company?: string;
  imageUrl?: string;
  location?: string;
  publishedAt?: Date;
  websiteUrl?: string;
  additionalInfo?: object;
  history?: [object];

  // Model references
  createdByUser?: User;
  operator?: Operator;

  /**
   * Constructor
   *
   * @param job
   */
  constructor(job?) {
    this._id = job._id || '';
    this.createdAt = job.createdAt || new Date();

    this.status = job.status;
    this.source = job.source;
    this.title = job.title;
    this.description = job.description;
    this.company = job.company;
    this.imageUrl = job.imageUrl;
    this.location = job.location;
    this.publishedAt = job.publishedAt;
    this.websiteUrl = job.websiteUrl;
    this.additionalInfo = job.additionalInfo;
    this.history = job.history;

    this.createdByUser = job.createdByUser;
    this.operator = job.operator;
  }
}
