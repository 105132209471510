import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettingsConfig } from '@app/core/configs/app-settings.config';

@Injectable({
  providedIn: 'root'
})

export class IpService {
  httpOptions: any = {};

  constructor(
    private http: HttpClient,
    private appConfig: AppSettingsConfig
  ) {
    console.log('IpService/init() appConfig.operator.baseUrl:', appConfig.operator?.baseUrl);
    this.httpOptions = {
      // Angular 14: not supporting headers anymore..? https://update.angular.io/?l=2&v=13.0-14.0
      //
      // headers: new HttpHeaders({
      //   'Content-Type': 'application/json',
      //   'Origin': this.appConfig.operator.baseUrl,
      //   'Access-Control-Allow-Origin': this.appConfig.operator.baseUrl
      // }),
      mode: 'cors'
    };
  }

  public getExtIpAddress(): Observable<any> {
    // fetch external IP address from client side and sent to BE
    console.log('IpService/getExtIpAddress()');
    return this.http.get('https://www.myexternalip.com/json?jsonp=callback', this.httpOptions);
  }
}
