import { Operator } from './operator.model';
export class WorkNotice {
  _id?: string;
  createdAt?: Date;
  uuid?: string;
  workNoticeNumber?: number;
  startsAt?: Date;
  expiresAt?: Date;
  description?: string;
  status?: string;
  ownedByUser?: string;
  operator?: string;

  /**
   * Constructor
   *
   * @param expense
   */
  constructor( expense? )
  {
    this._id = expense._id || '';
    this.createdAt = expense.createdAt || new Date();
    this.uuid = expense.uuid || '';
    this.workNoticeNumber = expense.workNoticeNumber;
    this.status = expense.status;
    this.startsAt = expense.startsAt  || new Date();
    this.expiresAt = expense.expiresAt;
    this.description = expense.description || '';
    this.ownedByUser = expense.ownedByUser;
    this.operator = expense.operator;
  }
}
