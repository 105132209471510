import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CurrentUserService } from '@app/core/services';
import { AppSettingsConfig } from '@app/core/configs/app-settings.config';

@Injectable()
export class FeatureGuard implements CanActivate {
  /** To map feature name to a custom path 
   * i.e 'contractorLiabilityData' feature mapped to '/subscriptions' module path 
  */
  customFeatureRoutesMap: Map<string, string> = new Map([
    ['subscriptions', 'contractorLiabilityData'],
    ['user-fees', 'usersFees']
  ]);

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private appConfig: AppSettingsConfig
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = this.currentUserService.getUser();
    let feature = route.routeConfig.path;
   
    if (this.customFeatureRoutesMap.has(feature)) {
      feature = this.customFeatureRoutesMap.get(feature);
    }

    if (user.role === 'root-admin' || !feature || this.appConfig.operator.features[feature] !== 'disabled') {
      // access ok
      return true;
    }

    // otherwise no access
    this.router.navigate(['/dashboard/' + user.role], {
      state: { requestedUrl: state.url, insufficientPermissions: true }
    });

    console.log('FeatureGuard() No access ...');
    return false;
  }
}