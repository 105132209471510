import { Operator, User } from '@app/core/models';

export class Message {
  _id?: string;
  createdAt?: Date;
  type?: string;
  content?: string;
  status?: string;
  expiresAt?: Date;
  hiddenForSender?: boolean;
  hiddenForReceiver?: boolean;

  fromUser?: User;
  fromOperator?: string;
  toUser?: User;
  attachmentsId?: string[];

  shouldShowContactAvatar?: boolean; // UI
  isDayChange?: boolean;
}