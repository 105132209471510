<div id="fuse-shortcuts" #shortcuts>
  <div class="shortcuts-mobile-toggle" *ngIf="!mobileShortcutsPanelActive" fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.lt-md>
    <button mat-icon-button (click)="showMobileShortcutsPanel()">
      <mat-icon class="amber-600-fg">star</mat-icon>
    </button>
  </div>

  <div class="shortcuts" fxLayout="row" fxHide fxShow.gt-sm>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="0 1 auto">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="w-40 h-40 p-4" fxLayout="row" fxLayoutAlign="center center" *ngFor="let shortcutItem of shortcutItems">
          <a mat-icon-button matTooltip="{{shortcutItem.translate | translate}}" [routerLink]="shortcutItem.url">
            <mat-icon class="icon " *ngIf="shortcutItem.icon">{{ shortcutItem.icon }}</mat-icon>
            <span *ngIf="!shortcutItem.icon" class="h1  text-bold">
              {{ shortcutItem.translate | translate }}
            </span>
          </a>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="addMenu" matTooltip="{{ 'toolbar.starToolTip' | translate }}" (menuOpened)="onMenuOpen()">
          <mat-icon class="amber-600-fg starticon">star</mat-icon>
        </button>
      </div>
      <div class="shortcuts" fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.lt-md>
        <button mat-icon-button (click)="hideMobileShortcutsPanel()">
          <mat-icon class="icon">close</mat-icon>
        </button>
      </div>
    </div>
    <mat-menu #addMenu="matMenu" class="w-240">
      <mat-form-field class="px-16 w-100-p" (click)="$event.stopPropagation()" floatLabel="never">
        <input #searchInput matInput placeholder="{{ 'toolbar.searchForPage' | translate }}" (input)="search($event)">
      </mat-form-field>
      <mat-divider></mat-divider>
      <mat-nav-list *ngIf="!searching" style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
        <mat-list-item *ngFor="let shortcutItem of shortcutItems" (click)="toggleShortcut($event, shortcutItem)">
          <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon mat-list-icon class="mr-8 " *ngIf="shortcutItem.icon">
              {{ shortcutItem.icon }}
            </mat-icon>
            <span class="h2 w-32 h-32 p-4 mr-8  text-bold" fxLayout="row" fxLayoutAlign="center center" *ngIf="!shortcutItem.icon">
              {{ shortcutItem.translate | translate }}
            </span>
            <p matLine fxFlex>{{ shortcutItem.translate | translate }}</p>
            <mat-icon class="ml-8 amber-fg">star</mat-icon>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="shortcutItems.length === 0">
          <p>
            <small>{{ 'toolbar.noShortcuts' | translate }}</small>
          </p>
        </mat-list-item>
      </mat-nav-list>

      <mat-nav-list *ngIf="searching" style="max-height: 312px; overflow: auto" fusePerfectScrollbar>
        <mat-list-item>
          <div *ngIf="!_platform.ANDROID && !_platform.IOS" class="message-box info" style="" fxLayoutAlign="start center"><p>{{ 'toolbar.onlyTenAllowedShortcuts' | translate }}</p></div>
          <div *ngIf="_platform.ANDROID && !_platform.IOS"class="message-box info" fxLayoutAlign="start center"><p>{{ 'toolbar.onlyFiveAllowedShortcuts' | translate}}</p></div>
        </mat-list-item>

        <mat-list-item *ngFor="let navigationItem of filteredNavigationItems" (click)="toggleShortcut($event, navigationItem)">
          <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon mat-list-icon class="mr-8 " *ngIf="navigationItem.icon">
              {{navigationItem.icon}}
            </mat-icon>
            <span class="h2 w-32 h-32 p-4 mr-8  text-bold" fxLayout="row" fxLayoutAlign="center center" *ngIf="!navigationItem.icon">
              {{navigationItem.translate | translate}}
            </span>
            <p matLine fxFlex>{{navigationItem.translate | translate}}</p>
            <mat-icon class="ml-8 amber-fg" *ngIf="navigationItem.isInShortcuts">star</mat-icon>
          </div>
        </mat-list-item>
      </mat-nav-list>
    </mat-menu>
  </div>
</div>
