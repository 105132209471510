import { User } from './user.model';
import { Operator } from './operator.model';
import { Invoice } from './invoice.model';
import { Income } from './income.model';
import { Expenditure } from './expenditure.model';
import { Settlement } from './settlement.model';
import { BusinessStatement } from './business-statement.interface';

type EventName = 'invoice-sent' | 'invoice-paid' | 'invoice-cancelled' | 'invoice-refunded' |
  'income-created' | 'income-paid' | 'income-cancelled' | 'income-refunded' |
  'expenditure-created' | 'expenditure-paid' | 'expenditure-cancelled' | 'expenditure-refunded' |
  'vat-debt-increase' | 'vat-debt-decrease' | 'vat-debt-paid';

type EventType = 'income' | 'expense' | 'transfer' | 'adjustment';

type RelatedDocumentType = 'Invoice' | 'Income' | 'Expenditure' | 'Settlement' | 'BusinessStatement';

export class AccountingEvent {
  _id?: string;
  status?: 'created' | 'pending' | 'processed' | 'locked' | 'cancelled' | 'error';
  uuid?: string;
  publicId?: string;
  eventName?: EventName;
  eventType?: EventType;
  description?: string;
  eventDate?: Date;
  amount?: number;
  amountType?: string;
  vatClass?: string;
  currency?: string;
  debitAccount?: string;
  creditAccount?: string;
  tags?: string[];
  additionalData?: any;
  history?: {
    time?: Date;
    status?: string;
    event?: string;
    description?: string;
    userId?: User | string;
  }[];
  operator?: Operator | string;
  user?: User | string;
  relatedDocument?: string;
  relatedDocumentType?: RelatedDocumentType;

  constructor(data?: any) {
    this._id = data?._id;
    this.status = data?.status || 'created';
    this.uuid = data?.uuid;
    this.publicId = data?.publicId;
    this.eventName = data?.eventName;
    this.eventType = data?.eventType;
    this.description = data?.description || '';
    this.eventDate = data?.eventDate ? new Date(data.eventDate) : undefined;
    this.amount = data?.amount || 0;
    this.amountType = data?.amountType || 'totalGross';
    this.vatClass = data?.vatClass || 'vat25_5';
    this.currency = data?.currency || 'EUR';
    this.debitAccount = data?.debitAccount;
    this.creditAccount = data?.creditAccount;
    this.tags = data?.tags || [];
    this.additionalData = data?.additionalData || {};
    this.history = data?.history || [];
    this.operator = data?.operator?._id ? new Operator(data.operator) : data?.operator;
    this.user = data?.user?._id ? new User(data.user) : data?.user;
    this.relatedDocumentType = data?.relatedDocumentType;
    this.relatedDocument = data?.relatedDocument;

    // if (data?.relatedDocument) {
    //   switch (this.relatedDocumentType) {
    //     case 'Invoice':
    //       this.relatedDocument = new Invoice(data.relatedDocument);
    //       break;
    //     case 'Income':
    //       this.relatedDocument = new Income(data.relatedDocument);
    //       break;
    //     case 'Expenditure':
    //       this.relatedDocument = new Expenditure(data.relatedDocument);
    //       break;
    //     case 'Settlement':
    //       this.relatedDocument = new Settlement(data.relatedDocument);
    //       break;
    //     case 'BusinessStatement':
    //       this.relatedDocument = data.relatedDocument as BusinessStatement;
    //       break;
    //     default:
    //       this.relatedDocument = data.relatedDocument;
    //   }
    // }
  }
}
