import { Competence } from '@app/core/models';

export class Skill {
  _id?: string;
  createdAt?: Date;
  
  title?: string;
  counter?: number;
  competence?: Competence;

  /**
   * Constructor
   *
   * @param skill
   */
  constructor( skill? )
  {
    this._id = skill._id || '';
    this.createdAt = skill.createdAt || new Date();
    this.title = skill.title || '';
    this.counter = skill.counter || 0;
    this.competence = skill.competence || {};
  }
}
