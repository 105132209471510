import { NgModule, Optional, SkipSelf, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RouterModule } from '@angular/router';

import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LayoutModule } from 'app/layout/layout.module';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { NgxCaptchaModule } from "ngx-captcha";

/* Singleton services */
import {
  ToasterService,
  LocalStorageService,
  FiltersService,
  AppInitializerService,
  ReCaptchaService,
  FileService
} from '@app/core/services';

/* Guards */
import { AuthGuard, RoleGuard, FeatureGuard } from '@app/core/guards';

/* Interceptors */
import { AuthInterceptor, ErrorInterceptor } from '@app/core/interceptors';

/* Configs */
import { AppSettingsConfig } from '@app/core/configs/app-settings.config';

/* Material */
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function init_app(appLoadService: AppInitializerService) {
  return () => appLoadService.initializeApp();
}

const toImportExport = [
  BrowserAnimationsModule,
  BrowserModule,
  RouterModule,
   // Material
   MatSnackBarModule,
   MatButtonModule,
   MatIconModule,
   MatDialogModule,
   /* Fuse Modules */
   FuseProgressBarModule,
   FuseSharedModule,
   LayoutModule
];

@NgModule({
  imports: [
    ...toImportExport,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpClient]},
      isolate: false
    }),
    NgIdleKeepaliveModule.forRoot(),
   
    NgxCaptchaModule,
    // Fuse modules
    FuseModule.forRoot(fuseConfig),
  ],
  declarations: [], // Do not add declarations into core module.
  exports: [
    ...toImportExport,
    TranslateModule,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        /* Configs */
        AppSettingsConfig,
        /* Guards */
        AuthGuard,
        RoleGuard,
        FeatureGuard,
        /* Singleton services */
        ToasterService,
        LocalStorageService,
        AppInitializerService,
        FiltersService,
        ReCaptchaService,
        FileService,
        { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppInitializerService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
      ]
    };
  }
}
