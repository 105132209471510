import { HistoryItem } from "./business-statement.interface";
import { Operator, User } from "@app/core/models";

type BusinessRegistryType = 'commercial' | 'prepayment' | 'employer' | 'vat';

export interface BusinessRegistry {
  type: BusinessRegistryType;
  status: string;
  registeredAt: Date;
  registeredYear: number;
  authority: string;
  registryData: string;
};

interface BusinessInfo {
  //dunsNumber: string;
  //tradenames: [string];
  //annualNetSales: number;
  hasEmployees: boolean;
  //numOfEmployees: number;
  //shareCapital: number;
  //numOfShares: number;
  //employeeCitizenships: [string];
  //employeeTitles: [string];
}

export interface Owner {
  type: string;
  name: string;
  idNumber: string;
  ownershipPct: number;
}

export interface Management {
  name: string;
  role: string;
}

export interface BusinessProhibition {
  name: string;
  reason: string;
  validFrom: Date;
  validTo: Date;
}

export interface OccupationalHealthCare {
  organization: string;
  details: string;
  expiresAt: Date;
}

export interface Activities {
  status: string;
  countries: [string];
}

type TypeOfPartnership = 'client' | 'supplier' | 'partner';

export interface BusinessPartner {
  name: string;
  companyId: string;
  country: string;
  typeOfPartnership: TypeOfPartnership
}

type PensionInsuranceProvider = 'Ilmarinen' | 'Varma' | 'Elo' | 'Veritas' | 'Keva' | 'Other';

export interface EmployeePensionInsurance {
  paid: boolean;
  provider: PensionInsuranceProvider;
  otherProvider: string;
}

type AccidentInsuranceProvider = 'IF Vahinkovakuutusyhtiö' | 'LähiTapiola' | 'Pohjola Insurance' |
  'Fennia' | 'Turva' | 'Alandia' | 'Folksam' | 'Other';

export interface AccidentInsurance {
  active: boolean;
  provider: AccidentInsuranceProvider;
  otherProvider: string;
  coverageAmount: number;
  coverageArea: string;
}

type OccupationalHealthCareProvider = 'Terveystalo' | 'Mehiläinen' | 'Pihlajalinna' | 'Aava' | 'Diacor' | 'Other';

export interface OccupationalHealthCare {
  active: boolean;
  provider: OccupationalHealthCareProvider
  otherProvider: string;
}

type LiabilityInsuranceProvider = 'IF Vahinkovakuutusyhtiö' | 'LähiTapiola' | 'Pohjola Insurance' |
  'Fennia' | 'Turva' | 'Alandia' | 'Folksam' | 'Other';

export interface LiabilityInsurance {
  active: boolean;
  provider: LiabilityInsuranceProvider;
  otherProvider: string;
  validFrom: Date;
  validTo: Date;
  coverageAmount: number;
  coverageArea: string;
}

type CollectiveAgreementName = 'technology1' | 'technology2' | 'technology3' |
  'construction1' | 'construction2' |
  'retail' |
  'transport1' | 'transport2' |
  'social' |
  'information' |
  'other' |
  'not_in_sector';

export interface CollectiveAgreement {
  active: boolean;
  name: CollectiveAgreementName;
  otherProvider: string;
}

export type FileUploadStatus = 'pending' | 'approved' | 'no_obligation' | 'to_be_clarified' | 'not_approved';
export type LiabilityDataStatus = 'draft' | 'reviewing' | 'approved' | 'rejected' | 'expired';

export class LiabilityData {
  _id: string;

  createdAt: Date;
  updatedAt: Date;
  status: LiabilityDataStatus;
  lastConfirmedByUserAt: Date;
  verificationEmail: string;

  businessInfo: BusinessInfo;

  employeePensionInsurance: EmployeePensionInsurance;
  accidentInsurance: {
    employee: AccidentInsurance,
    entrepreneur: AccidentInsurance
  };
  //entrepreneurAccidentInsurance: AccidentInsurance;
  occupationalHealthCare: OccupationalHealthCare;
  //liabilityInsurance: LiabilityInsurance;
  collectiveAgreement: CollectiveAgreement;

  businessRegistries: [BusinessRegistry];
  businessProhibitions: [BusinessProhibition];

  /* File uploads */
  employeePensionInsuranceFileId: string;
  employeeAccidentInsuranceFileId: string;
  //entrepreneurAccidentInsuranceFileId: string;
  occupationalHealthCareServiceFileId: string;
  //liabilityInsuranceFileId: string;
  taxDebtCertificateFileId: string;
  /* File upload statuses */
  employeePensionInsuranceFileStatus: FileUploadStatus;
  employeeAccidentInsuranceFileStatus: FileUploadStatus;
  //entrepreneurAccidentInsuranceFileStatus: FileUploadStatus;
  occupationalHealthCareServiceFileStatus: FileUploadStatus;
  //liabilityInsuranceFileStatus: FileUploadStatus;
  taxDebtCertificateFileStatus: FileUploadStatus;

  prhVirreTradeRegisterTranslationResponse: string;
  prhVirreTradeRegisterTranslationExtract: string;
  prhVirreTradeRegisterTranslationStoredAt: Date;

  isBusinessInfoCompleted: boolean;
  isContractorLiabilityCompleted: boolean;
  isTradeRegisterExtractCompleted: boolean;
  isYtjEntriesCompleted: boolean;

  history: [HistoryItem];

  /* Model references */
  user: User;
  operator: Operator;

  constructor(liabilityData?: LiabilityData) {
    this._id = liabilityData._id;
    this.status = liabilityData.status;
    this.lastConfirmedByUserAt = liabilityData.lastConfirmedByUserAt;
    this.verificationEmail = liabilityData.verificationEmail;
    this.businessInfo = liabilityData.businessInfo;

    this.employeePensionInsurance = liabilityData.employeePensionInsurance;
    this.accidentInsurance = {
      employee: liabilityData.accidentInsurance?.employee,
      entrepreneur: liabilityData.accidentInsurance?.entrepreneur
    };
    //this.entrepreneurAccidentInsurance = liabilityData.entrepreneurAccidentInsurance;
    this.occupationalHealthCare = liabilityData.occupationalHealthCare;
    //this.liabilityInsurance = liabilityData.liabilityInsurance;
    this.collectiveAgreement = liabilityData.collectiveAgreement;

    this.businessRegistries = liabilityData.businessRegistries;
    this.businessProhibitions = liabilityData.businessProhibitions;

    this.employeePensionInsuranceFileId = liabilityData.employeePensionInsuranceFileId;
    this.employeeAccidentInsuranceFileId = liabilityData.employeeAccidentInsuranceFileId;
    //this.entrepreneurAccidentInsuranceFileId = liabilityData.entrepreneurAccidentInsuranceFileId;
    this.occupationalHealthCareServiceFileId = liabilityData.occupationalHealthCareServiceFileId;
    //this.liabilityInsuranceFileId = liabilityData.liabilityInsuranceFileId;
    this.taxDebtCertificateFileId = liabilityData.taxDebtCertificateFileId;


    this.employeePensionInsuranceFileStatus = liabilityData.employeePensionInsuranceFileStatus;
    this.employeeAccidentInsuranceFileStatus = liabilityData.employeeAccidentInsuranceFileStatus;
    //this.entrepreneurAccidentInsuranceFileStatus = liabilityData.entrepreneurAccidentInsuranceFileStatus;
    this.occupationalHealthCareServiceFileStatus = liabilityData.occupationalHealthCareServiceFileStatus;
    //this.liabilityInsuranceFileStatus = liabilityData.liabilityInsuranceFileStatus;
    this.taxDebtCertificateFileStatus = liabilityData.taxDebtCertificateFileStatus;


    this.prhVirreTradeRegisterTranslationResponse = liabilityData.prhVirreTradeRegisterTranslationResponse;
    this.prhVirreTradeRegisterTranslationStoredAt = liabilityData.prhVirreTradeRegisterTranslationStoredAt;
    this.prhVirreTradeRegisterTranslationExtract = liabilityData.prhVirreTradeRegisterTranslationExtract;

    this.isBusinessInfoCompleted = this.businessInfo?.hasEmployees !== undefined;

    this.isTradeRegisterExtractCompleted = this.prhVirreTradeRegisterTranslationExtract && this.prhVirreTradeRegisterTranslationStoredAt ? true : false;

    this.isContractorLiabilityCompleted = this.businessInfo.hasEmployees
      ? (this.accidentInsurance?.employee?.active !== undefined &&
        //this.accidentInsurance?.entrepreneur?.active !== undefined &&
        this.employeePensionInsurance?.paid !== undefined &&
        this.occupationalHealthCare?.active !== undefined &&
        this.collectiveAgreement?.active !== undefined
        //this.liabilityInsurance?.active !== undefined
      )
      :  (this.accidentInsurance?.employee?.active !== undefined
        //this.accidentInsurance?.entrepreneur?.active !== undefined &&
        //this.liabilityInsurance?.active !== undefined
      );

    this.isYtjEntriesCompleted = this.businessRegistries?.length > 0;

    this.user = liabilityData.user;
    this.operator = liabilityData.operator;

  }
};
