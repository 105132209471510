export class TaxCard {
  _id?: string;

  taxRate?: number;
  thresholdAmount?: number;
  taxRateSecondary?: number;
  expiresAt?: Date;
  approvedAt?: Date;
  incomePrevious?: number;
  validSince?: Date;
  status?: string;
  ownedByUser?: string;
  fileId?: string;
  fileUrl?: string;
  isFetchedFromVeroApi?: boolean;

  /**
   * Constructor
   *
   * @param taxcard
   */
  constructor(taxcard?) {
    this._id = taxcard._id || '';
    this.taxRate = taxcard.taxRate || 0.0;
    this.thresholdAmount = taxcard.thresholdAmount || 0.0;
    this.taxRateSecondary = taxcard.taxRateSecondary || 60.0;
    this.expiresAt = taxcard.expiresAt;
    this.status = taxcard.status || 'new';
    this.fileId = taxcard.fileId || '';
    this.fileUrl = taxcard.fileUrl || '';
    this.incomePrevious = taxcard.incomePrevious || 0;
    this.validSince = taxcard.validSince;
    this.isFetchedFromVeroApi = taxcard.isFetchedFromVeroApi;
  }
}
