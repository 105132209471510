
import { HistoryItem } from "./business-statement.interface";
import { User, Operator, Payout, Settlement, Invoice, Income, UserSubscription, FactoringPayment } from "@app/core/models";

export interface UserFee {
  _id?: string;

  type: string;
  status: string;
  title: string;
  description: string;
  feeDate?: Date;
  netPrice: number;
  vatClass: string;
  currency: string;
  paidAt?: string;

  isReducible: boolean;

  user: string | User;
  operator: string | Operator;
  userSubscription?: string | UserSubscription;
  invoice?: string | Invoice;
  income?: string | Income;
  settlement?: string | Settlement;
  payout?: string | Payout;
  factoringPayment?: string | FactoringPayment; 

  //Virtuals
  grossPrice: number;

  createdAt?: Date;
  updatedAt?: Date;
  history?: [HistoryItem];
}


