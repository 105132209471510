import { HistoryItem } from "./business-statement.interface";
import { I18nObject, Operator } from "./operator.model";

import { AppSettingsConfig } from "../configs/app-settings.config";

export class Subscription {
  _id: string;
  oneTimeStartFee: number;
  status: string;
  feature: string;

  monthlyFee: number;
  quarterlyFee: number;
  yearlyFee: number;
  availableInRoles: [string];
  title: string;
  tnTitle: I18nObject;
  tnDescription: I18nObject;
  
  createdAt: Date;
  updatedAt?: Date;
  history: [HistoryItem];
  createdByUser: string;
  operator: Operator;

  constructor(subscription?) {
    this._id = subscription?._id || '';
    this.title = subscription?.title || '';
    this.oneTimeStartFee = subscription?.oneTimeStartFee || 0;
    this.availableInRoles = subscription?.availableInRoles || ['soletrader'];
    this.feature = subscription?.feature || '';
    this.status = subscription?.status || 'unavailable';
    this.monthlyFee = subscription?.monthlyFee || 0;
    this.quarterlyFee = subscription?.quarterlyFee || 0;
    this.yearlyFee = subscription?.yearlyFee || 0;

    this.tnTitle = subscription?.tnTitle || AppSettingsConfig.supportedLanguages.reduce(
      (acc, lang) => Object.assign({ [lang]: '' }), 
      {}
    );

    this.tnDescription = subscription?.tnDescription || AppSettingsConfig.supportedLanguages.reduce(
      (acc, lang) => Object.assign({ [lang]: '' }), 
      {}
    );
  }
};