import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import { Router, NavigationEnd } from '@angular/router';

import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';

import { FuseConfigService } from '@fuse/services/config.service';

import { navigation } from 'app/navigation/navigation';

import { environment } from '@env/environment';

import { User } from '@app/core/models';

import { AppSettingsConfig } from '@app/core/configs/app-settings.config';
import { LocalStorageService, CurrentUserService, AuthenticationService, NavigationService } from '@app/core/services';

@Component({
	selector: 'app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	fuseConfig: any;
	navigation: any;
	timedOut = false;
	user: User;
	username: string;
	browsersWithToolbarIssues = ['SAFARI']

	// Private
	private _unsubscribeAll = new Subject<void>();

	/**
	 * Constructor
	 *
	 * @param {DOCUMENT} document
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {Platform} _platform
	 * @param {TranslateService} _translateService
	 * @param {appConfig} _appSettingsConfig,
	 * @param {localStorageService} _localStorageService
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _fuseConfigService: FuseConfigService,
		private _translateService: TranslateService,
		private _platform: Platform,
		private _appConfig: AppSettingsConfig,
		private _localStorageService: LocalStorageService,
		private _navigationService: NavigationService,
		private currentUserService: CurrentUserService,
		private authenticationService: AuthenticationService,
		private idle: Idle,
		private dialogRef: MatDialog,
		private router: Router,
		private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag
	) {
		// Get default navigation
		this.navigation = navigation;

		// Get user
		this.user = this.currentUserService.getUser();

		// Register navigation
		// this._navigationService.registerNavigation(this.navigation);
		this._navigationService.getNavigation();

		// Add is-mobile class to the body if the platform is mobile
		if (this._platform.ANDROID || this._platform.IOS) {
			this.document.body.classList.add('is-mobile');

			// Add item to the local storage if the browser has the
			// bottom toolbar hiding add item button (invoices, clients...) issue
			this.browsersWithToolbarIssues.forEach(browser => {
				if (this._platform[browser]) {
					localStorage.setItem('bottom-toolbar-issue', 'true');
				};
			});
		}
		// start google analytics
		angulartics2GoogleGlobalSiteTag.startTracking();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.configureI18n();
		this.configureIdleTimeout();

		// Subscribe to config changes
		this._fuseConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				console.log('AppComponent/init() config changed');
				this.fuseConfig = config;

				// Boxed
				if (this.fuseConfig.layout.width === 'boxed') {
					this.document.body.classList.add('boxed');
				}
				else {
					this.document.body.classList.remove('boxed');
				}
			});

		// Subscribe to router events (NavigatioEnd)
		this.router.events
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					// Code to execute when the route has changed
					console.log('AppComponent/init() route changed:', event.url);

					if (!event.urlAfterRedirects.includes('documentation')) {
						console.log('AppComponent/init() not documentation; add operator script; event.urlAfterRedirects:', event.urlAfterRedirects);
						this.loadOperatorChatScript();
						this.loadChatbaseChatbot();
					}

					// FIXME: quick fix to use delay to see what page has loaded and
					// only then change the chat bubble button position

					// const addItemButton = document.getElementById('add-item-button');
					// if (addItemButton) {
					//   console.log('AppComponent/init() adapt chatbot button on route changed with delay..');
					//   setTimeout(() => {
					//     this.adaptChatBubbleButtonPosition();
					//   }, 1500);
					// }
				}
			});

		// FIXME: quick fix to use timeout to detect page load initially
		setTimeout(() => {
			console.log('AppComponent/init() page loaded..');
			this.adaptChatBubbleButtonPosition();
		}, 0);
	}

	private configureIdleTimeout() {
		console.log('AppComponent/configureIdleTimeout()');
		// sets an idle timeout of 5 seconds, for testing purposes.
		this.idle.setIdle(5);

		// sets a timeout period of NN minutes, after that the user will be considered timed out.
		const idleTimeOut = environment.idleTimeOutInSecs || 60 * 60 * 1; // 1 hour default
		console.log('AppComponent/configureIdleTimeout() set timeout to (secs):', idleTimeOut);
		this.idle.setTimeout(idleTimeOut);

		// sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
		this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		this.idle.onIdleEnd
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				console.log('AppComponent/configureIdleTimeout() onIdleEnd');
				this.resetIdle();
			});

		// Logout user after idle timeout
		this.idle.onTimeout
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				console.log('AppComponent/configureIdleTimeout() onTimeout');
				this.timedOut = true;

				// Close any opened dialog
				this.dialogRef.closeAll();

				// sign out
				this.authenticationService.signOut();

				// Get current URL
				const returnUrl = this.router.url;

				// and show lock screen
				const url = returnUrl.includes('auth') ? '/auth/lock' : '/auth/lock?returnUrl=' + returnUrl;
				console.log('AppComponent/configureIdleTimeout() onTimeout.. navigate to:', url);
				this.router.navigate([url], { state: { username: this.username } });
			});

		this.currentUserService.isLoggedIn
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(userLoggedIn => {
				if (userLoggedIn) {
					console.log('AppComponent/configureIdleTimeout() user logged in; set idle watch');
					let user = this.currentUserService.getUser();
					this.username = user.username;
					this.idle.watch()
					this.timedOut = false;
				} else {
					console.log('AppComponent/configureIdleTimeout() user not logged; no idle watch');
					this.idle.stop();
				}
			})
	}

	resetIdle() {
		console.log('AppComponent/resetIdle()');
		this.idle.watch();
		this.timedOut = false;
	}

	private configureI18n() {
		console.log('AppComponent/configureI18n()');
		this._translateService.addLangs(AppSettingsConfig.supportedLanguages);
		this._translateService.setDefaultLang(this._appConfig?.defaultLocale || 'fi-FI');
		const userLang = this._localStorageService.getUserLang();
		this._translateService.use(userLang || this._appConfig?.defaultLocale || 'fi-FI');
	}

	private loadOperatorChatScript() {
		console.log('AppComponent/loadOperatorChatScript()');

		if (this.user?.operator?.rootConfiguration?.chatScript?.length > 0) {
			console.log('loadOperatorChatScript() loading chat script..');

			const scriptElement = document.getElementById('operatorChatScript');
			const isScriptExisting = (scriptElement !== null && scriptElement.tagName === 'SCRIPT');

			if (!isScriptExisting) {
				let chatScript = document.createElement("script");
				chatScript.id = 'operatorChatScript';
				chatScript.type = "text/javascript";
				chatScript.async = true;
				chatScript.text = this.user.operator.rootConfiguration.chatScript;
				document.body.appendChild(chatScript);
			} else {
				console.log('AppComponent/loadChatbaseChatbot() operator chat script already loaded; do nothing..');
			}
		}
	}

	private loadChatbaseChatbot() {
		console.log('AppComponent/loadChatbaseChatbot()');

		const operator = this.user?.operator;
		if (operator?.features?.aiFeatureChatbase === 'enabled' && operator?.rootConfiguration?.chatbaseChatId?.length > 0) {
			console.log('AppComponent/loadChatbaseChatbot() loading chatbot script..');

			const scriptElement = document.getElementById(operator.rootConfiguration.chatbaseChatId);
			const isScriptExisting = (scriptElement !== null && scriptElement.tagName === 'SCRIPT');

			if (!isScriptExisting) {
				const chatbotScript = document.createElement('script');
				chatbotScript.src = 'https://www.chatbase.co/embed.min.js';
				chatbotScript.id = operator.rootConfiguration.chatbaseChatId;
				chatbotScript.defer = true;
				chatbotScript.onload = () => {
					console.log('AppComponent/loadChatbaseChatbot() onload..');
					setTimeout(() => {
						this.adaptChatBubbleButtonPosition();
					}, 1000);
				};
				chatbotScript.onerror = (error: any) => {
					console.log('AppComponent/loadChatbaseChatbot() onerror.. error:', error);
				};
				document.head.appendChild(chatbotScript);
			} else {
				console.log('AppComponent/loadChatbaseChatbot() chatbot script already loaded; do nothing..');
				setTimeout(() => {
					this.adaptChatBubbleButtonPosition();
				}, 1000);
			}
		}
	}

	private adaptChatBubbleButtonPosition() {
		console.log('AppComponent/adaptChatBubbleButtonPosition()');

		// Adapt chat bubble location in case add-new-button is visible in the page
		var addItemButton = document.getElementById('add-item-button');
		var chatbaseButton = document.getElementById('chatbase-bubble-button');

		// console.log('adaptChatBubbleButtonPosition() addItemButton:', addItemButton);
		// console.log('adaptChatBubbleButtonPosition() chatbaseButton:', chatbaseButton);

		if (addItemButton && chatbaseButton) {
			// console.log('adaptChatBubbleButtonPosition() chat button: up');
			chatbaseButton.style.bottom = '80px';
		} else if (!addItemButton && chatbaseButton) {
			// console.log('adaptChatBubbleButtonPosition() chat button: down');
			chatbaseButton.style.bottom = '1rem';
			// } else {
			//   console.log('adaptChatBubbleButtonPosition() no add new button or chatbase button');
		}
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

}
