<div class="navbar-top">
  <div (click)="returnToDashboard()" class="logo" style="cursor: pointer;">
    <div class="logo-icon" ></div>
    <span class="logo-text">{{ this.appConfig.serviceName }}</span>
  </div>
  <div class="buttons">
    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon>menu</mat-icon>
    </button>
    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
</div>
<div class="navbar-scroll-container" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
  <div *ngIf="user" class="user" fxLayout="column">
     <div class="username" *ngIf="user.userProfile">{{ user.userProfile.fullName !== 'undefined' ? user.userProfile.fullName : "menu.user.isUndefined" | translate }}</div>
    <div class="h2 username " *ngIf="!user.userProfile?.fullName">{{ (user.userProfile?.firstName || '') | split: " ": 0 }}  {{ (user.userProfile?.lastName || '') | split: " "}}</div>
    <div class="h3 email hint-text mt-8">{{'user.roles.' + user.role | translate}}</div>
    <div class="avatar-container">
      <img *ngIf="user.userProfile && user.userProfile.profileImageUrl" class="avatar" [src]="user.userProfile.profileImageUrl">
    </div>
    <ngx-avatars [size]="(navbar.opened && (!navbar.folded || navbar.unfolded)) ? 72 : 50" *ngIf="!user.userProfile || !user.userProfile.profileImageUrl" [name]="user.userProfile ? user.userProfile.firstName + ' ' + user.userProfile.lastName : user.username">
    </ngx-avatars>
  </div>
  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
</div>

