export class WorkHours {
  _id?: string;
  title?: string;
  workDate?: Date;
  history?: any;
  location?: any;
  quantity?: number;
  netPrice?: number;
  status?: string;
  invoice?: any;
  totalNet?: number;
  tags?: string[];

  ownedByUser?: string;
  client?: string;

  /**
   * Constructor
   *
   * @param workHours
   */
  constructor(workHours?) {
    this._id = workHours._id || '';
    this.title = workHours.title || '';
    this.workDate = workHours.startsAt || new Date();
    this.history = workHours?.history || [];
    this.location = workHours.location;
    this.quantity = workHours.quantity;
    this.netPrice = workHours.netPrice;
    this.status = workHours.status;
    this.invoice = workHours.invoice;
    this.totalNet = workHours.totalNet;
    this.tags = workHours.tags;

    this.ownedByUser = workHours.ownedByUser;
    this.client = workHours.client;
  }
}