export class Wallet {
  _id?: string;
  uuid?: string;
  type?: string;
  saldo?: number;
  currency?: string;
  ownedByUser?: string;
  description?: string;
  status?: string;
  additionalData?: any;
  createdAt?: Date;

  /**
   * Constructor
   *
   * @param wallet
   */
  constructor( wallet? )
  {
    this._id = wallet._id || '';
    this.uuid = wallet.uuid || '';
    this.type = wallet.type || '';
    this.saldo = wallet.saldo || '';
    this.currency = wallet.currency;
    this.ownedByUser = wallet.ownedByUser;
    this.description = wallet.description || '';
    this.additionalData = wallet.additionalData || {};
    this.status = wallet.status;
    this.createdAt = wallet.createdAt;
  }
}
