import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'helper-box',
	templateUrl: './helper-box.component.html',
	styleUrls: ['./helper-box.component.scss']
})
export class HelperBoxComponent implements OnInit {
	public title: string;
	public subtitle: string;
	public content: string;
	public showHelp = false;
	private url = '';
	private subRoute: Subscription;

	constructor(
		private router: Router,
		private translateService: TranslateService
	) { }

	ngOnInit(): void {
		this.subRoute = this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				console.log('Route changed: ', event.urlAfterRedirects);
				this.url = event.urlAfterRedirects.substr(1, event.urlAfterRedirects.length - 1)
				this.hideHelp();
			}
		});
	}

	updateContent(url: string) {
		let translationKeyObject = 'helperBox.' + url.replace(/\W+(.)/g, (match, chr) => chr.toUpperCase());
		console.log('updateContent() key. ', translationKeyObject);

		let objTranslations = this.translateService.instant(translationKeyObject);

		this.title = typeof objTranslations !== 'string' ? translationKeyObject + '.title' : undefined;
		this.subtitle = typeof objTranslations !== 'string' ? translationKeyObject + '.subtitle' : undefined;
		this.content = typeof objTranslations !== 'string' ? translationKeyObject + '.content' : undefined;
	}

	hideHelp() {
		this.showHelp = false;
	}

	openHelp() {
		this.updateContent(this.url);
		this.showHelp = true;
	}

	ngOnDestroy(){
		this.subRoute.unsubscribe();
	}
}
