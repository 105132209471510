import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

interface ToasterOptions {
  duration?: string;
  type?: string;
  color?: string;
  goUrl?: string;
  action?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  toasters: any[] = [];

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private _translateService: TranslateService
  ) { }

  displayToaster(message: string, options: ToasterOptions, config: any) {
    const action = options.action || 'HIDE';
    const actionTranslationTag = 'notifications.generic.' + action.toLowerCase();
    const actionTranslation = this._translateService.instant(actionTranslationTag);
    const snackBarRef = this.snackBar.open(message, actionTranslation, config);
    snackBarRef.afterDismissed().subscribe(result => {
      if (result.dismissedByAction && action === 'GO' && options.goUrl) {
        this.router.navigate(['.' + options.goUrl]);
      }
      this.toasters.shift();
      if (this.toasters.length > 0) {
        let toaster = this.toasters[0];
        this.displayToaster(toaster.message, toaster.options, toaster.config);
      }
    });
  }

  showToaster(translateKey: string, options?: ToasterOptions, translateParams?: any) {
    const duration = options && options.duration && options.duration === 'long' ? 5000 : 3000;
    let colorClass = 'info-snack-bar';

    if (options) {
      switch (options.type) {
        case 'success': colorClass = 'success'; break;
        case 'error': colorClass = 'error'; break;
        case 'warning': colorClass = 'warning'; break;
        default: colorClass = 'info'; break;
      }
    }

    const config = new MatSnackBarConfig();
    config.panelClass = [colorClass];
    config.duration = duration;

    // check that language is defined
    if (!this._translateService.currentLang) {
      console.log('ToasterService/showToaster() no current language set; defaulting to en-US');
      this._translateService.use('en-US');
    }

    this._translateService.get(translateKey, translateParams)
      .subscribe((message: string) => {
        this.toasters.push({ message, options, config });
        if (this.toasters.length === 1) {
          this.displayToaster(message, options, config);
        }
      });
  }
}
