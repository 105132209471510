export class ClientRegister {
  _id?: string;
  type?: string;
  firstName?: string; // Private
  lastName?: string; // Private
  ssn?: string; // Private
  streetAddress1?: string;
  streetAddress2?: string;
  postalCode?: string;
  city?: string;
  stateOrProvince?: string;
  countryCode?: string;
  mobilePhone?: string;
  businessSectorName?: string;
  businessSectorCode?: string;
  companyName?: string; // Non-profit
  companyId?: string; // Non-profit
  companyVatId?: string; // Non-profit
  companyRegistrationDate?: string; // Business
  companyRegisterData?: any; // Business
  companyRegisterDataUpdatedAt?: string; // Business
  logoUrl?: string;
  locale?: string;
  createdAt?: Date;

  clientName?: string;
  clientId?: string;


  /**
   * Constructor
   *
   * @param clientRegister
   */
  constructor( clientRegister? )
  {
    this._id = clientRegister._id || '';
    this.type = clientRegister.type || '';
    this.firstName = clientRegister.firstName || '';
    this.lastName = clientRegister.lastName || '';
    this.ssn = clientRegister.ssn;
    this.streetAddress1 = clientRegister.streetAddress1 || '';
    this.streetAddress2 = clientRegister.streetAddress2 || '';
    this.postalCode = clientRegister.postalCode || '';
    this.city = clientRegister.city || '';
    this.stateOrProvince = clientRegister.stateOrProvince || '';
    this.countryCode = clientRegister.countryCode || 'FI';
    this.mobilePhone = clientRegister.mobilePhone || '';
    this.businessSectorName = clientRegister.businessSectorName;
    this.businessSectorCode = clientRegister.businessSectorCode;
    this.companyName = clientRegister.companyName;
    this.companyId = clientRegister.companyId;
    this.companyVatId = clientRegister.companyVatId,
    this.companyRegistrationDate = clientRegister.companyRegistrationDate,
    this.companyRegisterData = clientRegister.companyRegisterData,
    this.companyRegisterDataUpdatedAt = clientRegister.companyRegisterDataUpdatedAt,
    this.logoUrl = clientRegister.logoUrl,
    this.locale = clientRegister.locale || 'fi-FI',
    this.createdAt = clientRegister.createdAt,
    this.clientName = clientRegister.clientName,
    this.clientId = clientRegister.clientId
  }
}
