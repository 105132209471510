export class CreditCheck {
  _id?: string;
  createdAt?: Date;
  success?: Boolean;
  client?: string;
  user?: number;

  /**
   * Constructor
   *
   * @param expense
   */
  constructor( creditCheck? )
  {
    this._id = creditCheck._id || '';
    this.createdAt = creditCheck.createdAt || new Date();
    this.success = creditCheck.success || false;
    this.client = creditCheck.client;
    this.user = creditCheck.user;
  }
}
