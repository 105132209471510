<mat-toolbar *ngIf="!inIframe() && isLoggedIn$ | async" class="main-toolbar p-0 mat-elevation-z1">
  <div *ngIf="user" fxFlex fxFill fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex.gt-lg="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon>menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>
      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <div class="logo-icon"></div>
        </div>
      </div>
      <div class="px-8 px-md-16">
        <fuse-shortcuts
          *ngIf="kycVerified || ['root-admin', 'root-support', 'operator-admin', 'operator-accounting', 'operator-support'].includes(user.role)"
          [navigation]="navigation">
        </fuse-shortcuts>
      </div>
      <div
        *ngIf="kycVerified || ['root-admin', 'root-support', 'operator-admin', 'operator-accounting', 'operator-support'].includes(user.role)"
        class="toolbar-separator"></div>

      <app-user-filter class="w-100-p"
        *ngIf="['root-admin', 'root-support', 'operator-admin', 'operator-accounting', 'operator-support'].includes(user.role)"></app-user-filter>
      <div class="toolbar-separator"></div>

      <app-operator-filter class="w-70-p"
        *ngIf="['root-admin', 'root-support'].includes(user.role)"></app-operator-filter>
    </div>
    <div *ngIf="user" class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <!--
      <div *ngIf="user.role !== 'root-finance'" class="mr-4" fxHide.xs fxLayout="row" fxLayoutAlign="center center" [routerLink]="['/wallets']" style="cursor: pointer;">
        <mat-icon class="mr-4">money</mat-icon>
        <span *ngIf="this.mainWallet" class="saldo text-uppercase">{{ this.mainWallet.saldo | currency: this.mainWallet.currency :'symbol-narrow':'1.2-2' }}</span>
        <span *ngIf="!this.mainWallet" class="saldo text-uppercase"> No saldo</span>
      </div>
      <div fxHide.xs class="toolbar-separator"></div>
      -->
      <notification-box class="helper-box"></notification-box>
      <div fxHide.xs class="toolbar-separator"></div>

      <helper-box class="helper-box" fxHide.xs></helper-box>
      <div class="toolbar-separator"></div>

      <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->
      <app-locale-selector></app-locale-selector>

      <div class="toolbar-separator"></div>
      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img *ngIf="user.userProfile && user.userProfile.profileImageUrl" class="avatar mr-0 mr-sm-16"
            [src]="user.userProfile.profileImageUrl">
          <ngx-avatars size="40" *ngIf="!user.userProfile || !user.userProfile.profileImageUrl"
            class="avatar mr-0 mr-sm-16"
            [name]="user.userProfile? user.userProfile.firstName + ' ' + user.userProfile.lastName : user.username">
          </ngx-avatars>
          <span *ngIf="user.userProfile && user.unreadMessages" class="username mr-12" matBadgecolor="secondary"
            matBadgePosition="before" matBadge="{{ user.unreadMessages }}" matBadgeOverlap="false" fxHide
            fxShow.gt-sm>{{ user.userProfile?.fullName !== 'undefined' ? ((user.userProfile?.fullName || '') | split: "
            ": 0) : "menu.user.isUndefined" | translate }}</span>
          <span *ngIf="user.userProfile && !user.unreadMessages" class="username mr-12" fxHide fxShow.gt-sm>{{
            user?.userProfile?.fullName !== 'undefined' ? ((user?.userProfile?.fullName || '') | split: " ": 0) :
            "menu.user.isUndefined" | translate }}</span>
          <mat-icon class="s-16 arrow">keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <!-- ID verification -->
        <div
          *ngIf="['freelancer', 'soletrader', 'company', 'taxi-company'].includes(user.role) && registrationCompleted && (!user.userProfile.idVerified)">
          <button mat-menu-item [routerLink]="['/account/id-verification']">
            <mat-icon matBadge="!" matBadgeColor="warn">alarm</mat-icon>
            <span>{{ 'toolbar.userMenu.idVerification' | translate }}</span>
          </button>
        </div>

        <!-- Messaging -->
        <button *ngIf="!['root-finance', 'operator-accounting'].includes(user.role)" mat-menu-item
          [routerLink]="['/chat']">
          <mat-icon *ngIf="user.unreadMessages" matBadge="{{ user.unreadMessages }}">mail</mat-icon>
          <mat-icon *ngIf="!user.unreadMessages">mail</mat-icon>
          <span>{{ 'toolbar.userMenu.messaging' | translate }}</span>
        </button>

        <!-- My profile -->
        <button
          *ngIf="['taxi-driver'].includes(user.role) || (['freelancer', 'soletrader', 'company', 'taxi-company'].includes(user.role) && kycVerified)"
          mat-menu-item [routerLink]="['/account/profile']">
          <mat-icon>face</mat-icon>
          <span>{{ 'toolbar.userMenu.myProfile' | translate }}</span>
        </button>

        <!-- Business information -->
        <button
          *ngIf="['freelancer', 'soletrader', 'company', 'taxi-company', 'taxi-driver'].includes(user.role) && kycVerified"
          mat-menu-item [routerLink]="['/account/business-profile']">
          <mat-icon>business</mat-icon>
          <span>{{ 'toolbar.userMenu.businessInformation' | translate }}</span>
        </button>

        <!-- Talent profile -->
        <button
          *ngIf="user.operator.features.usersTalentProfile === 'enabled' && ['freelancer', 'soletrader'].includes(user.role) && kycVerified"
          mat-menu-item [routerLink]="['/account/talent-profile']">
          <mat-icon>account_circle</mat-icon>
          <span>{{ 'toolbar.userMenu.usersTalentProfile' | translate }}</span>
        </button>

        <!-- Tax information -->
        <button *ngIf="['freelancer'].includes(user.role) && kycVerified" mat-menu-item
          [routerLink]="['/account/tax-card']">
          <mat-icon>account_balance</mat-icon>
          <span>{{ 'toolbar.userMenu.taxInformation' | translate }}</span>
        </button>

        <!-- Complete registration -->
        <div
          *ngIf="!registrationCompleted && ['freelancer', 'soletrader', 'company', 'taxi-company'].includes(user.role)">
          <button mat-menu-item [routerLink]="['/account/complete-registration']">
            <mat-icon matBadge="!" matBadgeColor="warn">alarm</mat-icon>
            <span>{{ 'toolbar.userMenu.completeRegistration' | translate }}</span>
          </button>
        </div>

        <!-- Settings -->
        <button *ngIf="(user.isFreelancer || user.isSoletrader) && kycVerified" mat-menu-item
          [routerLink]="['/account/settings']">
          <mat-icon>settings</mat-icon>
          <span>{{ 'toolbar.userMenu.settings' | translate }}</span>
          <!-- TODO: add badge if need user actions-->
        </button>

        <!-- User subscriptions -->
        <button *ngIf="user.isBusiness && user.operator.features.contractorLiabilityData === 'enabled' && kycVerified" mat-menu-item [routerLink]="['/user-subscriptions/my-subscriptions']">
          <mat-icon>list</mat-icon>
          <span>{{ 'toolbar.userMenu.userSubscriptions' | translate }}</span>
        </button>

        <!-- Change password -->
        <button mat-menu-item [routerLink]="['/account/change-password']">
          <mat-icon>lock</mat-icon>
          <span>{{ 'toolbar.userMenu.changePassword' | translate }}</span>
        </button>

        <!-- Logout -->
        <button mat-menu-item (click)="signOut()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ 'toolbar.userMenu.logout' | translate }}</span>
        </button>
      </mat-menu>
      <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
      <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>
      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
