import { Operator, User } from "@app/core/models"

export interface VatClassItem {
  vatClass: string,
  totalNet: number,
  totalVat: number,
  totalGross: number
};

export interface HistoryItem {
  time: Date,
  status: string,
  event: string,
  description: string,
  userId: string
}

export interface BusinessStatement {
  _id: string;
  uuid: string,
  publicId: string,
  amount: number,
  invoicesVatClasses: [VatClassItem],
  expendituresVatClasses: [VatClassItem],
  incomesVatClasses: [VatClassItem],
  currency: string,
  description: string,
  status: string,
  approvedAt: Date,
  rejectedAt: Date,
  rejectMessage: string;
  sentAt: Date,
  fromDate: Date,
  autoApproved: boolean;
  toDate: Date,
  history: [HistoryItem],
  additionalData: any,
  veroCalcs: any,
  isVeroVatReported: boolean,
  isVeroVatCanceled: boolean,
  veroVatReportResponse: any,
  veroVatReport: any,
  veroReplacementNumber: number,
  veroDeliveryNumber: number,
  businessStatementToUser: User,
  operator: Operator

  // Mongoose Virtuals
  profit: number
  netProfit: number;
  profitVatClasses: [VatClassItem];

  invoicesTotalVat: number;
  invoicesTotalNet: number;
  invoicesTotalGross: number;
  expendituresTotalVat: number;
  expendituresTotalNet: number;
  expendituresTotalGross: number;
  incomesTotalVat: number;
  incomesTotalNet: number;
  incomesTotalGross: number;
};
