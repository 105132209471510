import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, lastValueFrom } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { AppSettingsConfig } from '@app/core/configs/app-settings.config';
import { LocalStorageService, CurrentUserService, ApiService } from '@app/core/services';
import { filter } from 'rxjs/operators';
import { User } from '@app/core/models';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.scss'],
  standalone: true,
  imports: [
    FuseSharedModule,
    MatButtonModule,
    MatMenuModule
  ]
})
export class LocaleSelectorComponent implements OnInit, OnDestroy {
  languages: any;
  selectedLanguage: any;
  sub: Subscription;
  user: User;

  constructor(
    private translateService: TranslateService,
    private appConfig: AppSettingsConfig,
    private apiService: ApiService,
    private localStorage: LocalStorageService,
    private currentUserService: CurrentUserService
  ) {
  }

  ngOnInit(): void {
    console.log('LocaleSelectorComponent/ngOnInit()');

    this.languages = this.appConfig.locales;
    console.log('LocaleSelectorComponent/ngOnInit() this.languages:', this.languages);

    // Set the selected language from default languages
    this.selectedLanguage = this.findLocaleById(this.translateService.currentLang);
    console.log('LocaleSelectorComponent/ngOnInit() this.selectedLanguage:', this.selectedLanguage);

    this.sub = this.currentUserService.currentUser()
      .pipe(
        filter(value => value !== null && value._id !== undefined)
      )
      .subscribe((user: User) => {
        this.user = user;
        this.setLanguage(this.findLocaleById(this.user.userProfile.locale));
      });
  }

  /**
 * Set the language
 *
 * @param lang
 */
  setLanguage(lang): void {
    // Set the selected language for the toolbar (id, flag)
    this.selectedLanguage = lang;
    // Use the selected language for translations
    setTimeout(_ => {
      if (lang && lang.id) {
        this.translateService.use(lang.id);
      }
    }, 200);
    // Store language in cookies to be used next time
    if (lang?.id) {
      this.localStorage.setUserLang(lang.id);
    }
  }

  selectLanguage(lang): void {
    this.setLanguage(lang);
    if (this.user) {
      lastValueFrom(this.apiService.updateUser(this.user._id, { userProfile: { locale: lang.id } }));
      this.currentUserService.updateUserProfile({ locale: lang.id });
    }
  }

  findLocaleById(lang) {
    return this.appConfig.locales.find(l => l.id === lang);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  // eof
}
