import { User } from './user.model';
import { Operator } from './operator.model';

export class TaxiContractItem {
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  sku?: string;
  version?: number;
  status?: string;
  title?: string;
  description?: string;
  startsAt?: Date;
  endsAt?: Date;
  netPrice?: number;
  vatClass?: string;
  grossPrice?: number;
  costFrequency?: string;
  history?: any[];
  // references
  createdByUser?: User | string;
  operator?: Operator | string;

  constructor(data?: any) {
    this._id = data?._id;
    this.createdAt = data?.createdAt ? new Date(data.createdAt) : new Date();
    this.updatedAt = data?.updatedAt ? new Date(data.updatedAt) : new Date();
    this.sku = data?.sku || '';
    this.version = data?.version || 1;
    this.status = data?.status || 'created';
    this.title = data?.title || '';
    this.description = data?.description || '';
    this.startsAt = data?.startsAt ? new Date(data.startsAt) : new Date();
    this.endsAt = data?.endsAt ? new Date(data.endsAt) : undefined;
    this.netPrice = data?.netPrice || 0;
    this.vatClass = data?.vatClass || 'vat25_5';
    this.grossPrice = data?.grossPrice || 0;
    this.costFrequency = data?.costFrequency || 'monthly';
    this.history = data?.history || [];
    // references
    this.createdByUser = data?.createdByUser?._id ? new User(data.createdByUser) : data?.createdByUser;
    this.operator = data?.operator?._id ? new Operator(data.operator) : data?.operator;
  }
}
