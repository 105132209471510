import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';

import { User } from '@app/core/models';

import { AuthenticationService, CurrentUserService } from '@app/core/services';
import { HelperBoxComponent } from '@app/layout/components/helper-box/helper-box.component';

@Component({
	selector: 'toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
	horizontalNavbar: boolean;
	rightNavbar: boolean;
	hiddenNavbar: boolean;
	navigation: any;
	userStatusOptions: any[];
	user: User;
	// mainWallet: Wallet;
	registrationCompleted: boolean = false;
	kycVerified = false;
	isLoggedIn$: Observable<boolean>;

	// Private
	private _unsubscribeAll = new Subject<void>();

	/**
	 * Constructor
	 *
	 * @piram {FuseConfigService} _fuseConfigService
	 * @param {FuseSidebarService} _fuseSidebarService
	 * @param {AppSettingsConfig} _appConfig
	 * @param {LocalStorageService} _localStorage
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _fuseSidebarService: FuseSidebarService,
		private authenticationService: AuthenticationService,
		private currentUserService: CurrentUserService,
		private router: Router,
		public dialog: MatDialog,
	) {
		// Set the defaults
		this.userStatusOptions = [
			{
				title: 'Online',
				icon: 'icon-checkbox-marked-circle',
				color: '#4CAF50'
			},
			{
				title: 'Away',
				icon: 'icon-clock',
				color: '#FFC107'
			},
			{
				title: 'Do not Disturb',
				icon: 'icon-minus-circle',
				color: '#F44336'
			},
			{
				title: 'Invisible',
				icon: 'icon-checkbox-blank-circle-outline',
				color: '#BDBDBD'
			},
			{
				title: 'Offline',
				icon: 'icon-checkbox-blank-circle-outline',
				color: '#616161'
			}
		];

		this.navigation = navigation;
	}

	openDialog() {
		this.dialog.open(HelperBoxComponent, {
			height: 'auto',
			width: 'auto',
		});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		console.log('ToolbarComponent/init()');
		this.isLoggedIn$ = this.currentUserService.isLoggedIn;

		this.currentUserService.currentUser()
			.pipe(
				filter(value => value !== null && value._id !== undefined),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe((user: User) => {
				this.user = user;
				// this.mainWallet = user.wallets.find(wa => wa.type === 'credit');

				this.registrationCompleted = user.businessProfile &&
					user.businessProfile.isCompleted &&
					user.userProfile.isCompleted;
				this.kycVerified = this.registrationCompleted && (user.userProfile.idVerified || (user.operator?.features?.usersSkipIdVerification === 'enabled'));
			});

		// Subscribe to the config changes
		this._fuseConfigService.config
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((settings) => {
				this.horizontalNavbar = settings.layout.navbar.position === 'top';
				this.rightNavbar = settings.layout.navbar.position === 'right';
				this.hiddenNavbar = settings.layout.navbar.hidden === true;
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebarOpen(key): void {
		this._fuseSidebarService.getSidebar(key).toggleOpen();
	}

	/**
	 * Search
	 *
	 * @param value
	 */
	search(value): void {
		// Do your search here...
		console.log(value);
	}

	signOut(expired = false) {
		this.authenticationService.signOut(expired);
		this.router.navigate(['/auth/login']);
	}

	inIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

}
