import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CurrentUserService } from '@app/core/services';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const forbiddenRoles: string = route.data.forbiddenRoles;
    const expectedRoles: string = route.data.expectedRoles;
    const allowUncompletedRegistration: boolean = route.data.allowUncompletedRegistration || false;
    const allowUnverifiedIdentity: boolean = route.data.allowUnverifiedIdentity || false;
    const user = this.currentUserService.getUser();
    // console.log('RoleGuard/canActivate() user:', user);
    const usersSkipIdVerification = user?.operator?.features?.usersSkipIdVerification === 'enabled' || false;
    // console.log('RoleGuard/canActivate() usersSkipIdVerification:', usersSkipIdVerification);

    if (forbiddenRoles && forbiddenRoles.includes(user?.role)) {
      console.log('RoleGuard() Forbidden! Go to dashboard..');
      this.router.navigate(['/dashboard/' + user?.role], { state: { insufficientPermissions: true } });
    }

    if (!expectedRoles || (user?.role && expectedRoles.includes(user?.role))) {
      if (['freelancer', 'soletrader', 'company', 'taxi-company'].includes(user?.role) &&
        !allowUncompletedRegistration &&
        user?.registrationSource !== 'api' &&
        (!user?.userProfile?.isCompleted || !user?.businessProfile?.isCompleted)) {
        // Do not allow navigation until registration is completed
        console.log('RoleGuard() go to complete registration, missing some data.. username:', user.username);
        console.log('RoleGuard() userProfile:', JSON.stringify(user.userProfile, null, 2));
        console.log('RoleGuard() businessProfile:', JSON.stringify(user.businessProfile, null, 2));
        this.router.navigate([`/account/complete-registration`]);
        return false;
      } else if (['freelancer', 'soletrader', 'company'].includes(user?.role) &&
        !allowUncompletedRegistration &&
        !allowUnverifiedIdentity &&
        user?.registrationSource !== 'api' &&
        (!user?.userProfile?.idVerified && !usersSkipIdVerification)) {
        // Do not allow navigating until ID is verified (skip for taxi roles)
        console.log('RoleGuard() ID is not verified; navigation disabled..');
        this.router.navigate(['/dashboard/' + user?.role], { state: { insufficientPermissions: true } });
        return false;
      } else {
        return true;
      }
    } else {
      console.log('RoleGuard() insufficient permissions; go to dashboard..');
      // console.log('RoleGuard() user:', user);
      // console.log('RoleGuard() expectedRoles:', expectedRoles);
      // console.log('RoleGuard() state.url:', state.url);

      this.router.navigate(['/dashboard/' + user?.role], {
        state: { requestedUrl: state.url, insufficientPermissions: true }
      });
      return false;
    }

  }

}
