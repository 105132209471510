<div [formArrayName]="controlName">
  <mat-label>{{ translationKeyLabel | translate }}</mat-label>
  <div class="mt-12" *ngFor="let language of languages; index as i">
    <mat-form-field fxFlex="100%" appearance="outline" floatLabel="always">
      <mat-label style="color: gray">{{ language }}</mat-label>
      <textarea matInput formControlName="{{ language }}"
        placeholder="{{ lang === language ? (translationKeyLabel | translate): '' }}"></textarea>
      <!-- button to translate form control content text with language -->
    </mat-form-field>
    <button mat-icon-button matTooltip="{{ 'forms.translate' | translate }}"
      (click)="translateText(controlName, language)">
      <mat-icon style="color:gray!important">translate</mat-icon>
    </button>
  </div>
</div>