import { HistoryItem } from "./business-statement.interface";
import { Operator, User } from "@app/core/models";

type BusinessRegistryType = 'commercial' | 'prepayment' | 'employer' | 'vat';
const PENDING_STATUSES = ['pending', 'to_be_clarified'];

export interface BusinessRegistry {
  type: BusinessRegistryType;
  status: string;
  register: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8';
  registeredAt: Date;
  registeredYear: number;
  authority: string;
  registryData: string;
  registerEndsAt: Date;
};

interface BusinessInfo {
  //dunsNumber: string;
  //tradenames: [string];
  //annualNetSales: number;
  hasEmployees: boolean;
  //numOfEmployees: number;
  //shareCapital: number;
  //numOfShares: number;
  //employeeCitizenships: [string];
  //employeeTitles: [string];
}

export interface Owner {
  type: string;
  name: string;
  idNumber: string;
  ownershipPct: number;
}

export interface Management {
  name: string;
  role: string;
}

export interface BusinessProhibition {
  name: string;
  reason: string;
  validFrom: Date;
  validTo: Date;
}

export interface OccupationalHealthCare {
  organization: string;
  details: string;
  expiresAt: Date;
}

export interface Activities {
  status: string;
  countries: [string];
}

type TypeOfPartnership = 'client' | 'supplier' | 'partner';

export interface BusinessPartner {
  name: string;
  companyId: string;
  country: string;
  typeOfPartnership: TypeOfPartnership
}

type PensionInsuranceProvider = 'Ilmarinen' | 'Varma' | 'Elo' | 'Veritas' | 'Keva' | 'Other';

export interface EmployeePensionInsurance {
  paid: boolean;
  provider: PensionInsuranceProvider;
  otherProvider: string;
}

type AccidentInsuranceProvider = 'IF Vahinkovakuutusyhtiö' | 'LähiTapiola' | 'Pohjola Insurance' |
  'Fennia' | 'Turva' | 'Alandia' | 'Folksam' | 'Other';

export interface AccidentInsurance {
  active: boolean;
  provider: AccidentInsuranceProvider;
  otherProvider: string;
  coverageAmount: number;
  coverageArea: string;
}

type OccupationalHealthCareProvider = 'Terveystalo' | 'Mehiläinen' | 'Pihlajalinna' | 'Aava' | 'Diacor' | 'Other';

export interface OccupationalHealthCare {
  active: boolean;
  provider: OccupationalHealthCareProvider
  otherProvider: string;
}

type LiabilityInsuranceProvider = 'IF Vahinkovakuutusyhtiö' | 'LähiTapiola' | 'Pohjola Insurance' |
  'Fennia' | 'Turva' | 'Alandia' | 'Folksam' | 'Other';

export interface LiabilityInsurance {
  active: boolean;
  provider: LiabilityInsuranceProvider;
  otherProvider: string;
  coverageAmount: number;
}

type CollectiveAgreementName = 'technology1' | 'technology2' | 'technology3' |
  'construction1' | 'construction2' |
  'retail' |
  'transport1' | 'transport2' |
  'social' |
  'information' |
  'other' |
  'not_in_sector';

export interface CollectiveAgreement {
  active: boolean;
  name: CollectiveAgreementName;
  otherProvider: string;
}

export type FileUploadStatus = 'pending' | 'approved' | 'no_obligation' | 'to_be_clarified' | 'not_approved';
export type LiabilityDataStatus = 'draft' | 'reviewing' | 'approved' | 'rejected' | 'expired';

export interface LiabilityData {
  _id: string;

  companyId: string;
  createdAt: Date;
  updatedAt: Date;
  status: LiabilityDataStatus;
  lastConfirmedByUserAt: Date;
  verificationEmail: string;

  businessInfo: BusinessInfo;

  employeePensionInsurance: EmployeePensionInsurance;
  accidentInsurance: {
    employee: AccidentInsurance,
    entrepreneur: AccidentInsurance
  };
  //entrepreneurAccidentInsurance: AccidentInsurance;
  occupationalHealthCare: OccupationalHealthCare;
  liabilityInsurance: LiabilityInsurance;
  collectiveAgreement: CollectiveAgreement;

  businessRegistries: [BusinessRegistry];
  businessProhibitions: [BusinessProhibition];

  /* File uploads */
  employeePensionInsuranceFileId: string;
  employeeAccidentInsuranceFileId: string;
  //entrepreneurAccidentInsuranceFileId: string;
  occupationalHealthCareServiceFileId: string;
  liabilityInsuranceFileId: string;
  taxDebtCertificateFileId: string;
  /* File upload statuses */
  employeePensionInsuranceFileStatus: FileUploadStatus;
  employeeAccidentInsuranceFileStatus: FileUploadStatus;
  //entrepreneurAccidentInsuranceFileStatus: FileUploadStatus;
  occupationalHealthCareServiceFileStatus: FileUploadStatus;
  liabilityInsuranceFileStatus: FileUploadStatus;
  taxDebtCertificateFileStatus: FileUploadStatus;

  prhVirreTradeRegisterTranslationResponse: string;
  prhVirreTradeRegisterTranslationExtract: string;
  prhVirreTradeRegisterTranslationStoredAt: Date;

  isBusinessInfoCompleted: boolean;
  isContractorLiabilityCompleted: boolean;
  isTradeRegisterExtractCompleted: boolean;
  isYtjEntriesCompleted: boolean;

  isRegisteredForVat: boolean;
  isRegisteredToPreTaxRegistry: boolean;
  isRegisteredToCommercialRegistry: boolean;
  isInBankruptcy: boolean;
  isActivityCeased: boolean;
  bankrupctyAt: Date;

  history: [HistoryItem];

  /* Model references */
  user: User;
  operator: Operator;
  usersList: string[]; // Users where this liability data is saved to watchlist list
}
