import { Invoice, Income, Expense } from '@app/core/models';

class OtherReduction {
  description?: string;
  amount?: number;
}

export class Settlement {
  _id?: string;
  createdAt?: Date;
  uuid?: string;
  publicId?: string;
  status?: string;
  type?: string;
  description?: string;
  amount?: number;
  bankTransferAmount?: number;
  currency?: string;
  additionalData?: any;
  debtCollection?: number;
  debtCollectionOption?: string;
  debtCollectionEnabled?: boolean;
  debtCollectionReference?: string;
  isUrgentBankTransfer?: boolean;
  netSettlement?: number;
  grossSettlement?: number;
  totalNet?: number;
  totalGross?: number;
  totalFeeItemsNet?: number;
  totalFeeItemsGross?: number;
  serviceFee?: number;
  serviceFeeEditable?: boolean;
  bonusToBeUsed?: number;
  quickSalaryServiceFee?: number;
  quickSalaryServiceFeeEditable?: number;

  invoices?: [Invoice];
  incomes?: [Income];
  deductions?: [Expense];

  settlementToUser?: string;

  otherReductions?: [OtherReduction];
  otherReductionsAmount: number;
  salaryYelMonthlyReduction?: number;

  /**
   * Constructor
   *
   * @param settlement
   */
  constructor(settlement?) {
    this._id = settlement._id || '';
    this.createdAt = settlement.createdAt || new Date();
    this.uuid = settlement.uuid || '';
    this.publicId = settlement.publicId || '';
    this.amount = Number(settlement.amount || 0.00);
    this.bankTransferAmount = Number(settlement.bankTransferAmount || 0.00);
    this.currency = settlement.currency || 'EUR';  // TODO: get default currency from operator config?
    this.description = settlement.description || '';
    this.status = settlement.status;
    this.additionalData = settlement.additionalData || {};
    this.debtCollection = settlement.debtCollection || 0;
    this.debtCollectionOption = settlement.debtCollectionOption || 'from-net-income';
    this.debtCollectionEnabled = settlement.debtCollectionEnabled;
    this.debtCollectionReference = settlement.debtCollectionReference || '';
    this.isUrgentBankTransfer = settlement.isUrgentBankTransfer;
    this.totalNet = Number(settlement.totalNet || 0.00);
    this.totalGross = Number(settlement.totalGross || 0.00);
    this.totalFeeItemsNet = Number(settlement.totalFeeItemsNet || 0.00);
    this.totalFeeItemsGross = Number(settlement.totalFeeItemsGross || 0.00);
    this.netSettlement = Number(settlement.netSettlement || 0.00);
    this.grossSettlement = Number(settlement.grossSettlement || 0.00);
    this.serviceFee = Number(settlement.serviceFee || 0.00);
    this.serviceFeeEditable = settlement.serviceFeeEditable;
    this.bonusToBeUsed = Number(settlement.bonusToBeUsed || 0.00);
    this.quickSalaryServiceFee = Number(settlement.quickSalaryServiceFee || 0.00);
    this.quickSalaryServiceFeeEditable = settlement.quickSalaryServiceFeeEditable;

    this.invoices = settlement.invoices || [];
    this.incomes = settlement.incomes || [];
    this.deductions = settlement.deductions || [];
    this.settlementToUser = settlement.settlementToUser;

    this.otherReductions = settlement.otherReductions || [];
    this.otherReductionsAmount = settlement.otherReductionsAmount || 0;
    this.salaryYelMonthlyReduction = settlement.salaryYelMonthlyReduction || 0;
  }
}
