import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import Bugsnag from '@bugsnag/js'

import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { environment } from '@env/environment';
import { ToasterService } from '@app/core/services/toaster.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { CurrentUserService } from '@app/core/services/current-user.service';
import { ApiService } from '@app/core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private localStorage: LocalStorageService,
    private currentUserService: CurrentUserService,
    private toasterService: ToasterService,
    private apiService: ApiService,
    private angulartics2: Angulartics2,
    private dialogRef: MatDialog,
    private _fuseSplashScreenService: FuseSplashScreenService
   ) {}

  async checkIfUserTokenAlreadyExists() {
    if (this.isAuthenticated()) {
      console.log('User already authenticated. Refreshing data..');
      // Refresh User data
      try {
        const user = await lastValueFrom(this.apiService.getUserInfo());

        this.currentUserService.setUser(user);
        this.currentUserService.setLoggedIn(true);

        // Notify GA
        this.angulartics2.eventTrack.next({
          action: 'V2-User-LoginTokenExists',
          properties: { category: (user?.operator?.shortName || 'na'), label: user?.publicId }
        });
      } catch (err) {
        console.error('checkIfUserTokenAlreadyExists() catch; err:', err);
        this.removeLoggedUser();
      }
    }
  }

  async signIn(credentials: any) {
    const response = await lastValueFrom(this.apiService.login(credentials));
    this.authenticateUser(response);

    return response.user;
  }

  async signInViaOtp(token: string, tosAccepted: boolean) {
    const response = await lastValueFrom(this.apiService.otpLogin(token, tosAccepted));
    this.authenticateUser(response);

    return response.user;
  }

  async signInViaPlusId(connectId: string, tosAccepted: boolean) {
    const response = await lastValueFrom(this.apiService.loginWithPlusId(connectId, tosAccepted));
    this.authenticateUser(response);

    return response.user;
  }

  authenticateUser(authData: any) {
    if (!authData || !authData.token || !authData.user) {
      throw new Error('Invalid auth response');
    }

    this.localStorage.setLoggedAccount(authData.token, authData.expiresAt);
    this.currentUserService.setUser(authData.user);
    this.currentUserService.setLoggedIn(true);

    // Notify GA
    this.angulartics2.eventTrack.next({
      action: 'V2-User-Login',
      properties: { category: (authData?.user?.operator?.shortName || 'na'), label: authData?.user?.publicId }
    });

    if (authData?.user?._id && environment.bugsnagEnabled) {
      // Set user info for Bugsnag error reporter
      Bugsnag.addMetadata('user', {
        userId: authData.user._id,
        publicId: authData.user.publicId
      });
    }
  }

  async signOut(expired = false) {
    console.log('AuthenticationService/signOut()');

    const user = this.currentUserService.getUser();
    try {
      this.removeLoggedUser();
      if (expired) {
        this.toasterService.showToaster('login.sessionExpired', { duration: 'long', type: 'info' });
      } else {
        this.toasterService.showToaster('login.successfulLogout', { duration: 'short', type: 'info' });
      }
      //prevent load data the last user logged only for roots
      if( ['root-admin', 'root-support', 'root-finance'].includes(user.role)){
        this._fuseSplashScreenService.show();
        await this.sleep(3000);
        location.reload();
        this._fuseSplashScreenService.hide();
      }
    } catch (err) {
      console.error('signOut() catch; err:', err);
      this.toasterService.showToaster('login.failedLogout', { duration: 'short', type: 'error' });
    }
  }

  removeLoggedUser(): void {
    this.currentUserService.remove();
    this.localStorage.removeLoggedAccount();
    // Drop dialog if any
    this.dialogRef.closeAll();
  }

  public isAuthenticated(): boolean {
    const currentDate: Date = new Date();
    const userData = this.localStorage.getCurrentUser();

    if (userData && userData.token && userData.expiresAt) {
      const expirationDate: Date = new Date(userData.expiresAt);
      // Check token expiration
      if (currentDate.getTime() > expirationDate.getTime()) {
        console.log('AuthenticationService/isAuthenticated() token expired; expirationDate:', expirationDate);
        this.removeLoggedUser();
        this.toasterService.showToaster('login.tokenExpired', { duration: 'long', type: 'info' });
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  async register(registration: any) {
    const response = await lastValueFrom(this.apiService.register(registration));
    this.authenticateUser(response);

    // Notify GA
    this.angulartics2.eventTrack.next({
      action: 'V2-User-New',
      properties: { category: 'general' }
    });

    return response.user;
  }

  async registerViaPlusId(requestId: string) {
    const response = await lastValueFrom(this.apiService.checkRegistrationStatus(requestId));

    if (response.status === 'completed') {
      this.authenticateUser(response);

      // Notify GA
      this.angulartics2.eventTrack.next({
        action: 'V2-User-New',
        properties: { category: 'plusid' }
      });
    }

    return response;
  }

  async sleep (milliseconds) {
    await new Promise(resolve => {
        return setTimeout(resolve, milliseconds)
    });
  };

}
