import { Injectable } from '@angular/core';

import { Operator } from '@app/core/models';
import { ExpenseConfig } from '@app/shared/helpers/expense-helper';

export interface VatClassItemId {
  id: string;
  value: number;
  name: string;
  description: string;
  accountingAccountId?: number;
}

@Injectable()
export class AppSettingsConfig {
  static supportedLanguages: Array<string> = ['fi-FI', 'en-US', 'sv-SE', 'et-EE'];

  operatorCode: string;
  baseUrl = 'localhost:4205';
  baseApiUrl = 'localhost:10030';

  defaultLocale = 'fi-FI';
  defaultTaxRate = 60;
  defaultVatClass = 'vat25_5';
  defaultVatRate = 25.5;
  yelThreshold = 9010.28; // 2023: 8575.45; // 2022: 8261.71;
  minRowNetPriceForConstruction = 20;
  currency = 'EUR';
  monthLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

  vatClasses: VatClassItemId[] = [
    { id: "vat25_5", value: 25.5, name: "VAT 25.5", description: "invoice.editor.vatClasses.invoice.vat25_5", accountingAccountId: 3000 },
    { id: "vat24", value: 24, name: "VAT 24", description: "invoice.editor.vatClasses.invoice.vat24", accountingAccountId: 3000 },
    { id: 'vat14', value: 14, name: "VAT 14", description: "invoice.editor.vatClasses.invoice.vat14", accountingAccountId: 3001 },
    { id: 'vat10', value: 10, name: "VAT 10", description: "invoice.editor.vatClasses.invoice.vat10", accountingAccountId: 3002 },
    { id: 'vat0_construction', value: 0, name: "VAT 0 Contruction", description: "invoice.editor.vatClasses.invoice.vat0ConstructionIndustry", accountingAccountId: 3188 },
    { id: 'vat0_artist', value: 0, name: "VAT 0 Artists", description: "invoice.editor.vatClasses.invoice.vat0ArtisticWork" },
    { id: 'vat0_eu', value: 0, name: "VAT 0 EU", description: "invoice.editor.vatClasses.invoice.vat0ReverseChargeForForeignEUCompany", accountingAccountId: 3343 },
    { id: 'vat0_marine', value: 0, name: "VAT 0 Marine work", description: "invoice.editor.vatClasses.invoice.vat0MarineWork" },
    { id: 'vat0_noVat', value: 0, name: "VAT 0% Product", description: "invoice.editor.vatClasses.invoice.vat0NoVat", accountingAccountId: 3004 },
    { id: 'vat0_notLiable', value: 0, name: "VAT 0% Not liable", description: "invoice.editor.vatClasses.vat0NotLiableDescription", accountingAccountId: 3004 }
  ];
  businessSectorCodesVATExempt = ['77400']; // TODO: SET THE CONSTRUCTION/EXEMPT BUSINESS SECTOR CODES
  clientTypes = [
    { title: 'Business', id: 'business' },
    { title: 'Private', id: 'private' },
    { title: 'Non-profit', id: 'non-profit' }
  ];
  countryList: [];
  locales = [
    { title: 'Suomi', id: 'fi-FI', flag: 'fi' },
    { title: 'English', id: 'en-US', flag: 'us' },
    { title: 'Svenska', id: 'sv-SE', flag: 'se' },
    { title: 'Eesti keel', id: 'et-EE', flag: 'ee' }
  ];
  invoiceTypes = ['standard', 'proforma', 'group', 'virtual'];
  invoiceStatuses = ['draft', 'created', 'reviewing', 'rejected', 'processing', 'sent', 'paid', 'cancelled', 'refunded', 'credit'];
  expensesConfig: [ExpenseConfig];
  workNoticeStatuses = ['draft', 'active', 'expired'];
  serviceFeeTypes = ['email', 'ovt', 'paper'];
  invoiceFeeTypes: string[] = ['email', 'ovt', 'paper'];
  insuranceFeeTypes = ['insurance', 'accident', 'liability', 'yel'];
  expensesOtherTaxTypes = ['taxable', 'acceptable-deduction-in-taxes', 'tax-free'];

  theme = {
    primary: '#4f4f4f',
    secondary: '#e2e2e2',
    accent: '#2375ab',
    bg1: '#f5f5f5', // mainContent
    bg2: '#ffffff', // card
    info: '#B3E5FC',
    warn: '#FFC107',
    success: '#43A047',
    error: '#e53935',
    plusIdBlue: '#002640',
    logoUrl: 'https://storage.googleapis.com/ab-public0/images/generic_logo_v1.png',
    iconUrl: 'https://storage.googleapis.com/ab-public0/images/generic_icon_v1.png',
    favIconUrl: 'https://storage.googleapis.com/ab-public0/images/favicon.ico',
    backgroundImage: 'https://storage.googleapis.com/ab-public0/images/generic_bg_v1.png',
    titleFont: 'Noto Sans',
    bodyFont: 'Noto Sans',
    containersOpacity: 1,
  };

  operator: Operator;
  serviceName = 'Service';
  shortName = 'demo-service';
  operatorName = 'Example Company Oy';

  defaultSmsCost = 0.29;

  google = {
    cloudBucketName: 'aalto-business-development'
  }

  appConfig = {};

  defaultPagination = {
    length: 0,
    pageIndex: 0,
    pageSize: 20
  }

  fileUploadAcceptedTypes = [
    'application/pdf',
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif'
  ];
}
