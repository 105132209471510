import { User, Operator, TaxiVehicle, TaxiRide } from '@app/core/models';

export type BreakTimeReason = 'break' | 'lunch' | 'service' | 'other';

export interface BreakTime {
  startedAt: Date;
  endedAt: Date | null;
  reason: BreakTimeReason;
  // virtuals
  duration?: number;
}

export class WorkShift {
  _id?: string;
  createdAt?: Date;

  status?: string;
  shiftNumber?: number;
  description?: string;
  startedAt?: Date;
  endedAt?: Date;
  breakTimes?: BreakTime[];
  additionalData?: object;
  history?: [object];
  // optional for taxi
  taxiVehicleStartKm?: number;
  taxiVehicleEndKm?: number;

  // Model references
  user?: User;
  parentUser?: User;
  operator?: Operator;
  // optional for taxi
  taxiVehicle?: TaxiVehicle;

  // Virtual properties
  totalGrossDuration?: number;
  totalBreakTimesDuration?: number;
  totalNetDuration?: number;
  taxiRides?: [TaxiRide];

  /**
   * Constructor
   *
   * @param workShift
   */
  constructor(workShift?) {
    this._id = workShift._id || '';
    this.createdAt = workShift.createdAt || new Date();

    this.status = workShift.status;
    this.shiftNumber = workShift.shiftNumber;
    this.description = workShift.description;
    this.startedAt = workShift.startedAt;
    this.endedAt = workShift.endedAt;
    this.breakTimes = workShift.breakTimes || [];
    this.additionalData = workShift.additionalData;
    this.history = workShift.history;
    // taxi
    this.taxiVehicleStartKm = workShift.taxiVehicleStartKm;
    this.taxiVehicleEndKm = workShift.taxiVehicleEndKm;
    // references
    this.user = workShift.user;
    this.parentUser = workShift.parentUser;
    this.operator = workShift.operator;
    this.taxiVehicle = workShift.taxiVehicle;
    // virtual properties
    this.taxiRides = workShift.taxiRides;
    // calc. in ui.. not coming from backend
    this.totalGrossDuration = workShift.totalGrossDuration;
    this.totalBreakTimesDuration = workShift.totalBreakTimesDuration;
    this.totalNetDuration = workShift.totalNetDuration;
  }
}