import { Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss'],
    standalone: true,
    imports: [
        RouterModule,
        MatToolbarModule,
        FuseSharedModule
    ]
})
export class FooterComponent
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
