<button  matTooltip="Help" mat-icon-button class="callHelp" [matMenuTriggerFor]="openHelp" (click)="$event.stopPropagation();" aria-label="Help">
  <mat-icon>help_outline</mat-icon>
</button>

<mat-menu #openHelp="matMenu" fxLayout="column" fxFlex="80" fxFlex.gt-xs="40">
  <div class="content mx-12 my-12" fxLayout="column" fxFlex="100" >

  	<div fxLayout="row" fxFlex="100" >
    	<div class="title" fxLayoutAlign="start start" fxFlex="90">
     		 {{ title || "helperBox.generic.title" | translate}}
    	</div>
  	</div>

	  <div class="subtitle" fxLayoutAlign="start start" fxFlex="100">
   		 {{ subtitle || "helperBox.generic.subtitle" | translate}}
  	</div>

    <div class="content pl-8 mt-8" fxLayout="column" fxFlex="100">
      <div [innerHtml]="content || 'helperBox.generic.content' | translate"></div>
    </div>
  </div>
</mat-menu >
