import uiPackagesInfo from '../../package.json';
import bePackagesInfo from '../../../ab-backend/package.json';

export const environment = {
  OFFLINE_MODE: false, // careful with this one! DEBUG DEBUG DEBUG
  apiVersion: bePackagesInfo.version,
  appVersion: uiPackagesInfo.version,
  name: 'development',
  idleTimeOutInSecs: 60 * 60 * 2, // 2 hours
  production: false,
  hmr: false,
  baseApiUrl: 'https://dev-api.aaltobusiness.com/api/',
  baseUrl: 'https://dev.aaltobusiness.com',
  googleWebFontsApiKey: 'AIzaSyARPJqslZ0ThyiJpkRaxDBYY_NilIZJ8r8',
  googleReCaptchaV3Key: '6LfM-aoZAAAAAGDpmjKpzk-Kx0uIgQ7uQI22sOVs',
  googleMapsApiKey: 'AIzaSyBBTgxLVKkkZy_Xj2h6l7HuBlgB-kAsFJQ',
  signicatEnabled: true,
  personaEnabled: true,
  plusIdConnectEnabled: true,
  bugsnagEnabled: false,
  bugsnagApiKey: '',
  recaptchaEnabled: true
};
