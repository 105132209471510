import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, of, lastValueFrom } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  catchError,
  filter,
  takeUntil
} from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

import { MatAutocomplete } from '@angular/material/autocomplete';
import { User } from '@app/core/models';

import { FiltersService, ApiService, CurrentUserService } from '@app/core/services';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { MediaObserver } from '@angular/flex-layout';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-operator-filter',
  templateUrl: './operator-filter.component.html',
  styleUrls: ['./operator-filter.component.scss']
})

export class OperatorFilterComponent implements OnInit {
  operatorsCtrl = new UntypedFormControl('');
  operators$: Observable<any[]>;

  filteredOperators: any[];
  currentUser: User;
  prevOperator: string;
  selectedOperator: boolean = false;
  showField: boolean = false;
  isEnabled: boolean = false;
  reloading: boolean;

  private _unsubscribeAll = new Subject<void>();

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private filtersService: FiltersService,
    private apiService: ApiService,
    private currentUserService: CurrentUserService,
    private _fuseMatchMediaService: FuseMatchMediaService,
    private _mediaObserver: MediaObserver,
    private _platform: Platform,
  ) {}

  ngOnInit(): void {
    this.currentUser = this.currentUserService.getUser();

    this.operators$ = this.operatorsCtrl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(value => value !== null && value.length > 0),
        switchMap((search) => this.apiService.getOperators(search, { listType: 'filter' })),
        tap(operators => this.filteredOperators = operators),
        catchError(error => of(null))
      );
    this.toggleVisibility();

    // Subscribe to media changes
    this._fuseMatchMediaService.onMediaChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.showField = this._mediaObserver.isActive('gt-xs') ? true : false;
      });

    this.filtersService.newinputSelectedOperatorText
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(operatorInput => {
        console.log('OperatorFilterComponent/init() newinputSelectedOperatorText subscribe; operatorInput:', operatorInput);
        this.operatorsCtrl.setValue(operatorInput);
        if (operatorInput && operatorInput.length > 0) {
          this.filtersService.setSelectedOperatorStatus(true);
          this.selectedOperator = true;
        } else {
          console.log('OperatorFilterComponent/init() newinputSelectedOperatorText subscribe; operatorInput was empty');
        }
      });

    this.filtersService.newSelectedOperatorstate
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(operatorStatus => {
        console.log('OperatorFilterComponent/init() newSelectedOperatorState subscribe; operatorStatus:', operatorStatus);
        if (!operatorStatus) {
          console.log('OperatorFilterComponent/init() newSelectedOperatorState subscribe; operatorStatus is false; clear it..');
          this.selectedOperator = false;
          this.operatorsCtrl.setValue('');
          this.filtersService.setOperatorInput('');
          this.filtersService.setOperator(undefined);
          this.filtersService.operatorData.next(undefined);
        }
      });
  }

  async selectOperator(event, id = null, storePrevOperator = false) {
    let operatorId = event?.option.value || id;
    console.log('selectOperator() operatorId:', operatorId);

    if (storePrevOperator) {
      this.prevOperator = this.filtersService.operatorValue;
      console.log('selectOperator() store previous operator; prevOperator:', this.prevOperator);
    }

    if (operatorId) {
      if (!event && id) {
        const operator = await lastValueFrom(this.apiService.getOperator(operatorId));
        this.getSelectedOperatorInfo(operator, operator._id.toString());
      } else {
        let operatorSelected = this.filteredOperators.find(operator => operator._id === operatorId);
        this.getSelectedOperatorInfo(operatorSelected, operatorId);
      }
    } else {
      this.clearOperator();
      console.log('selectOperator() clear operator and trigger data update..');
      this.filtersService.removeOperator();
    }

    if (!this._platform.isBrowser && (this._platform.ANDROID || this._platform.IOS)) {
      console.log('selectOperator() is mobile --> toggle visibility');
      this.document.body.classList.add('is-mobile');
      this.toggleVisibility();
    }
  }

  getOperator() {
    return this.filtersService.operatorValue;
  }

  private getSelectedOperatorInfo(operatorSelected, operatorId) {
    console.log('getSelectedOperatorInfo()', operatorSelected, operatorId);
    this.filtersService.setOperatorInput(operatorSelected.shortName);
    this.filtersService.setOperator(operatorId);
    this.filtersService.operatorData.next(operatorSelected);
  }

  clearOperator(returnPrevOperator = false) {
    console.log('clearOperator()');

    if (returnPrevOperator && this.prevOperator) {
      console.log('clearOperator() restore previous operator..');
      this.selectOperator(null, this.prevOperator);
    } else {
      this.filtersService.setSelectedOperatorStatus(false);
    }
  }

  toggleVisibility() {
    this.showField = !this.showField;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
