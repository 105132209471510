import { Expense } from "./expense.model";
import { User } from "./user.model";

export type MultiExpenseStatus = 'draft' | 'created' | 'reviewing' | 'approved' | 'rejected';

export class MultiExpense {
  _id?: string;

  externalId?: string;
  status: MultiExpenseStatus;

  createdAt?: Date;
  updatedAt?: Date;

  mileages?: Expense[];
  dailyAllowance?: Expense;
  mealAllowance?: Expense;
  expenseReceipts?: Expense[];

  // References
  ownedByUser?: User;
  operator?: any;

  // UI virtuals
  totalNet?: number;
  totalVat?: number;
  totalGross?: number;

  calculateTotals(): void {
    this.totalNet = [
      ...(this.mileages || []),
      ...(this.expenseReceipts || []),
      this.dailyAllowance,
      this.mealAllowance
    ].reduce((sum, expense) => sum + (expense?.totalNet || 0), 0);

    this.totalVat = [
      ...(this.mileages || []),
      ...(this.expenseReceipts || []),
      this.dailyAllowance,
      this.mealAllowance
    ].reduce((sum, expense) => sum + (expense?.totalVat || 0), 0);

    this.totalGross = [
      ...(this.mileages || []),
      ...(this.expenseReceipts || []),
      this.dailyAllowance,
      this.mealAllowance
    ].reduce((sum, expense) => sum + (expense?.totalGross || 0), 0);
  }

  constructor(multiExpense?: MultiExpense) {
    this._id = multiExpense?._id || '';

    this.externalId = multiExpense?.externalId || '';
    this.status = multiExpense?.status || 'draft';

    this.createdAt = multiExpense?.createdAt || new Date();
    this.updatedAt = multiExpense?.updatedAt || new Date();

    this.mileages = multiExpense?.mileages || [];
    this.dailyAllowance = multiExpense?.dailyAllowance || {};
    this.mealAllowance = multiExpense?.mealAllowance || {};
    this.expenseReceipts = multiExpense?.expenseReceipts || [];

    this.ownedByUser = multiExpense?.ownedByUser || {};
    this.operator = multiExpense?.operator || {};

    this.calculateTotals();
  }
}
