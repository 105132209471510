<div class="nav" [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">

        <ng-container *ngFor="let item of navigation">

            <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></fuse-nav-vertical-group>
            <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'"
                                           [item]="item"></fuse-nav-vertical-collapsable>
            <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>

        </ng-container>

    </ng-container>

    <!-- App version -->
    <div class="pt-36 pb-24 font-size-10 text-center" fxLayoutAlign="center center">
        <span>UI v{{ uiVersion || '?' }}, BE v{{ beVersion || '?' }}</span>
    </div>

</div>

