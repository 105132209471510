import { User, Skill, Competence } from '@app/core/models';

export class RatedSkill {
  skill?: Skill;
  myRating?: number;
  clientRatings?: any
}

export class TalentProfile {
  _id?: string;
  shareWithClients?: boolean;
  shareWithExternalPartners?: boolean;
  birthdate?: Date;
  gender?: string;
  yearsOfExperience?: number;
  experienceDescription?: string;
  highestDegree?: string;
  yearOfGraduation?: number;
  educationDescription?: string;
  languages?: any;
  hobbies?: [string];
  cvLink?: string;
  introductionVideoUrl?: string;
  linkedInPage?: string;
  facebookPage?: string;
  twitterPage?: string;
  instagramPage?: string;
  tiktokPage?: string;
  carInUse?: boolean;
  carLicenses?: [string];
  workLocation?: string;
  currentStatus?: string;
  availability?: string;
  incorporate?: string;
  termsAccepted?: boolean;
  acceptanceDate?: Date;
  isCompleted?: boolean;

  // Model references
  skills?: [RatedSkill];
  competences?: [Competence];

  /**
   * Constructor
   *
   * @param talentProfile
   */
  constructor(talentProfile?) {
    this._id = talentProfile._id || '';
    this.shareWithClients = talentProfile.shareWithClients || false;
    this.shareWithExternalPartners = talentProfile.shareWithExternalPartners || false;
    this.birthdate = talentProfile.birthdate || '';
    this.gender = talentProfile.gender || '';
    this.yearsOfExperience = talentProfile.yearsOfExperience || 0;
    this.experienceDescription = talentProfile.experienceDescription || '';
    this.highestDegree = talentProfile.highestDegree || '';
    this.yearOfGraduation = talentProfile.yearOfGraduation || 0;
    this.educationDescription = talentProfile.educationDescription || '';
    this.languages = talentProfile.languages || [];
    this.hobbies = talentProfile.hobbies || [];
    this.cvLink = talentProfile.cvLink || '';
    this.introductionVideoUrl = talentProfile.introductionVideoUrl || '';
    this.linkedInPage = talentProfile.linkedInPage || '';
    this.facebookPage = talentProfile.facebookPage || '';
    this.twitterPage = talentProfile.twitterPage || '';
    this.instagramPage = talentProfile.instagramPage || '';
    this.tiktokPage = talentProfile.tiktokPage || '';
    this.carInUse = talentProfile.carInUse || false;
    this.carLicenses = talentProfile.carLicenses || [];
    this.workLocation = talentProfile.workLocation || '';
    this.currentStatus = talentProfile.currentStatus || '';
    this.availability = talentProfile.availability || '';
    this.incorporate = talentProfile.incorporate || '';
    this.termsAccepted = talentProfile.termsAccepted || false;
    this.acceptanceDate = talentProfile.acceptanceDate;
    this.isCompleted = talentProfile.isCompleted || false;
    this.skills = talentProfile.skills || [];
    this.competences = talentProfile.competences || [];
  }
}
