import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

import { HelperBoxComponent } from '@app/layout/components/helper-box/helper-box.component';
import { NotificationBoxComponent } from '@app/layout/components/notification-box/notification-box.component';
import { TranslateFieldFormComponent } from '@app/layout/components/translate-field-form/translate-field-form.component';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { UserFilterComponent } from 'app/layout/components/user-filter/user-filter.component';
import { OperatorFilterComponent } from 'app/layout/components/operator-filter/operator-filter.component';
import { AvatarModule } from 'ngx-avatars';
import { MatDividerModule } from '@angular/material/divider';
//import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { LocaleSelectorComponent } from '../locale-selector/locale-selector.component';

@NgModule({
    declarations: [
        ToolbarComponent,
        UserFilterComponent,
        OperatorFilterComponent,
        HelperBoxComponent,
        NotificationBoxComponent,
    ],
    imports: [
        RouterModule,
        /* Material modules */
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatBadgeModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatInputModule,
        MatDividerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        /* 
          Toolbar module is part of app/core module. Do not include Shared Module here.
          Shared Module can be imported only in feature modules for lazy loading approach and reduce main bundle size
        */
        //SharedModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        AvatarModule,

        // Standalone components
        LocaleSelectorComponent,
        TranslateFieldFormComponent
    ],
    exports: [
        ToolbarComponent
    ],
    providers: [
        //{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true} }
    ]
})
export class ToolbarModule {
}
