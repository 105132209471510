import { Injectable } from "@angular/core";
import { ToasterService } from "@app/core/services";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";

//import { saveAs } from 'file-saver';

const EXCEL_EXTENSION = '.xlsx';
const PDF_EXTENSION = '.pdf';

@Injectable({
  providedIn: 'root'
})
export class FileService {
 
  constructor(
    private _toasterService: ToasterService,
    private _fuseProgressBarService: FuseProgressBarService,
  ) {}

  download(src: string, filename: string, tnErrorKey = 'apiError.pdfGenerationError') {
    try {
      this._fuseProgressBarService.setMode('query');
      this._fuseProgressBarService.show();

      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";

      const blob = new Blob([src], { type: "octet/stream" });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();

      return true;
    } catch (err) {
      console.error('downloadFile() err: ', err);
      this._toasterService.showToaster(err.translationKey || tnErrorKey, { duration: 'long', type: 'info' }, err.translationParams);
    } finally {
      this._fuseProgressBarService.hide();
    }
  }

  /*saveAsExcel(buffer: string, filename: string) {
    const todayString = moment().format('YYYY-MM-DD');
    saveAs(buffer, filename + '_exported_' + todayString + EXCEL_EXTENSION);
  }*/

  downloadExcel(src: string, name: string, tnErrorKey = 'apiError.excelGenerationError') {
    const todayString = new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit'});
    const filename =  name + '_exported_' + todayString + EXCEL_EXTENSION;
    this.download(src, filename, tnErrorKey);
  }
}
