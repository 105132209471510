import { Injectable } from '@angular/core';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { navigation } from 'app/navigation/navigation';
import { User, Operator } from '@app/core/models';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  // private MAIN_ITEMS = [
  //   'user_menu',
  //   'admin_menu',
  //   'finance_menu',
  //   'taxi_menu'
  // ];

  private MENU_NAVIGATION_PER_ROLE = {
    freelancer: [
      'user_menu',
      'dashboards',
      'messaging',
      'clients',
      'invoices',
      'jobs',
      'work-notices',
      'work-shifts',
      'work-shifts-list',
      'work-shifts-app',
      'work-hours',
      'expenses',
      'multi-expenses',
      'salaries',
      'salaries-sales_payouts',
      'salaries-salary_calculator',
      'wallet',
      'insurances',
      'insurances-my_insurances',
      'insurances-yel_calculator',
      'reports',
      'reports-income_report',
      'reports-cumulative_report',
      'reports-salary_report',
      'reports-payouts_report',
      'reports-invoices_report',
      'faq',
      'marketing_menu',
      'marketing_referrals'
    ],
    soletrader: [
      'user_menu',
      'dashboards',
      'messaging',
      'clients',
      'invoices',
      'incomes',
      'my-liability-data',
      'jobs',
      'liability-data-search',
      // 'work-notices',
      'work-shifts',
      'work-shifts-list',
      'work-shifts-app',
      'work-shifts-for-review',
      'work-shifts-ongoing',
      'work-shifts-rejected',
      'work-hours',
      // 'expenses',
      'expenditures',
      'settlements',
      'business-statements',
      'settlements-sales_settlements',
      // 'settlements-settlement_calculator',
      'wallet',
      // 'insurances',
      // 'insurances-my_insurances',
      // 'insurances-yel_calculator',
      'reports',
      'reports-income_report',
      'reports-cumulative_report',
      //'reports-salary_report',
      'reports-invoices_report',
      'reports-settlements_report',
      'reports-business-statement_report',
      'reports-accounting_events_report',
      'faq',
      'marketing_menu',
      'marketing_referrals',
      'accounting_events'
    ],
    company: [
      'user_menu',
      'dashboards',
      'messaging',
      'clients',
      'invoices',
      'incomes',
      'my-liability-data',
      'jobs',
      'liability-data-search',
      'work-notices',
      'work-shifts',
      'work-shifts-list',
      'work-shifts-app',
      'work-shifts-for-review',
      'work-shifts-ongoing',
      'work-shifts-rejected',
      'work-hours',
      // 'expenses',
      'expenditures',
      'settlements',
      'settlements-sales_settlements',
      'wallet',
      'business-statements',
      'reports',
      //'reports-income_report',
      //'reports-cumulative_report',
      'reports-invoices_report',
      'reports-settlements_report',
      'reports-accounting_events_report',
      'faq',
      'marketing_menu',
      'marketing_referrals',
      'accounting_events'
    ],
    'taxi-company': [
      'user_menu',
      'dashboards',
      'messaging',
      'clients',
      'work-shifts',
      'work-shifts-list',
      'work-shifts-app',
      'work-shifts-for-review',
      'work-shifts-ongoing',
      'work-shifts-rejected',
      'invoices',
      'incomes',
      'my-liability-data',
      'liability-data-search',
      // 'expenses',
      // 'expenditures',
      'settlements',
      'settlements-sales_settlements',
      // 'wallet',
      'reports',
      // 'reports-income_report',
      // 'reports-cumulative_report',
      'reports-invoices_report',
      'reports-settlements_report',
      'reports-work_shifts_report',
      'reports-taxi_rides_report',
      'faq',
      // 'marketing_menu',
      // 'marketing_referrals',
      'accounting_events',
      'taxi_menu',
      'taxi_invoice-form',
      'taxi_simple-invoice-form',
      'taxi_kelaov-simple-invoice-form',
      'taxi_contracts',
      'taxi_contracts-list',
      'taxi_contract-items',
      'taxi_sub-users',
      'taxi_vehicles',
      'taxi_rides'
    ],
    'taxi-driver': [
      'user_menu',
      'dashboards',
      'messaging',
      'work-shifts',
      'work-shifts-list',
      'work-shifts-app',
      'taxi_menu',
      'taxi_simple-invoice-form',
      'taxi_kelaov-simple-invoice-form',
      // 'taxi_rides'
    ],
    'root-admin': [
      'user_menu',
      'dashboards',
      'messaging',
      'clients',
      'invoices',
      'incomes',
      'expenses',
      'multi-expenses',
      'expenditures',
      'salaries',
      'salaries-sales_payouts',
      'salaries-salary_calculator',
      'settlements',
      'settlements-sales_settlements',
      // 'settlements-settlement_calculator',
      'jobs',
      'liability-data-search',
      'work-notices',
      'work-shifts',
      'work-shifts-list',
      'work-shifts-app',
      'work-shifts-for-review',
      'work-shifts-ongoing',
      'work-shifts-rejected',
      'work-hours',
      'wallet',
      'insurances',
      'insurances-my_insurances',
      'insurances-yel_calculator',
      'offers',
      'contracts',
      'business-statements',
      'reports',
      'reports-income_report',
      'reports-cumulative_report',
      'reports-salary_report',
      'reports-business-statement_report',
      // 'reports-work_certificate',
      // 'reports-tax_report',
      'reports-work_shifts_report',
      'reports-taxi_rides_report',
      'reports-accounting_events_report',
      'other-services',
      'other-services-work_id_card',
      'other-services-loans',
      'subscriptions',
      'faq',
      // 'marketing_menu',
      // 'marketing_referrals',
      // 'marketing_campaigns',
      'accounting_events',
      'admin_menu',
      'admin_operators',
      // 'admin_operator_details',
      'admin_tax_cards',
      'admin_invoices_review',
      'admin_payouts_review',
      'admin_settlements_review',
      'admin_jobs_review',
      'admin_bank_transfer_transactions_review',
      'admin_users',
      // 'admin_users_taxi-companies',
      // 'admin_users_taxi-drivers',
      'admin_user_fees',
      'admin_liability_data',
      'admin_talent-search',
      'admin_marketing',
      'admin_referrals',
      'admin_campaigns',
      'admin_reports',
      'admin_reports-accounting',
      'admin_reports-accounting-v2',
      'admin_reports-income_report',
      'admin_reports-monthly_report',
      'admin_reports-invoices_report',
      'admin_reports-payouts_report',
      'admin_reports-settlements_report',
      'admin_reports-bank-account-transactions',
      'admin_adminbot',
      'admin_operator_fees',
      'admin_importdata',
      'admin_report-tool',
      'taxi_menu',
      'taxi_invoice-form',
      'taxi_simple-invoice-form',
      'taxi_kelaov-simple-invoice-form',
      'taxi_contracts',
      'taxi_contracts-list',
      'taxi_contract-items',
      'taxi_taxi-companies',
      'taxi_sub-users',
      'taxi_vehicles',
      'taxi_rides'
    ],
    'root-support': [
      'user_menu',
      'dashboards',
      'messaging',
      'clients',
      'invoices',
      'incomes',
      'expenses',
      'multi-expenses',
      'expenditures',
      'jobs',
      'liability-data-search',
      'work-notices',
      'work-shifts',
      'work-shifts-list',
      'work-shifts-app',
      'work-shifts-for-review',
      'work-shifts-ongoing',
      'work-shifts-rejected',
      'work-hours',
      'salaries',
      'salaries-sales_payouts',
      'salaries-salary_calculator',
      'settlements',
      'business-statements',
      'settlements-sales_settlements',
      // 'settlements-settlement_calculator',
      'jobs',
      'work-notices',
      'work-shifts',
      'work-hours',
      'wallet',
      'insurances',
      'insurances-my_insurances',
      'insurances-yel_calculator',
      'reports',
      'reports-income_report',
      'reports-cumulative_report',
      'reports-salary_report',
      'reports-work_shifts_report',
      'reports-taxi_rides_report',
      'reports-accounting_events_report',
      'faq',
      'subscriptions',
      // 'marketing_menu',
      // 'marketing_referrals',
      // 'marketing_campaigns',
      'accounting_events',
      'admin_menu',
      'admin_tax_cards',
      'admin_invoices_review',
      'admin_payouts_review',
      'admin_settlements_review',
      'admin_jobs_review',
      'admin_bank_transfer_transactions_review',
      'admin_users',
      // 'admin_users_taxi-companies',
      // 'admin_users_taxi-drivers',
      'admin_user_fees',
      'admin_liability_data',
      'admin_talent-search',
      'admin_marketing',
      'admin_referrals',
      'admin_campaigns',
      'admin_adminbot',
      'admin_operator_fees',
      'admin_importdata',
      'taxi_menu',
      'taxi_contracts',
      'taxi_contracts-list',
      'taxi_contract-items',
      // 'taxi_taxi-companies',
      'taxi_sub-users',
      'taxi_vehicles',
      'taxi_rides'
    ],
    'root-finance': [
      'user_menu',
      'reports',
      'reports-accounting_events_report',
      'accounting_events',
      'admin_menu',
      'admin_reports',
      'admin_reports-invoices_report',
      'admin_reports-payouts_report',
      'admin_reports-settlements_report',
      'admin_reports-bank-account-transactions',
      'admin_adminbot',
      'admin_operator_fees',
      'finance_menu',
      'finance_dashboards',
      'finance_clients',
      'finance_invoices_review',
      'finance_reports-accounting-v2',
    ],
    'operator-admin': [
      'user_menu',
      'dashboards',
      'messaging',
      'clients',
      'invoices',
      'incomes',
      'expenses',
      'multi-expenses',
      'expenditures',
      'business-statements',
      'salaries',
      'salaries-sales_payouts',
      'salaries-salary_calculator',
      'settlements',
      'settlements-sales_settlements',
      // 'settlements-settlement_calculator',
      'jobs',
      'liability-data-search',
      'work-notices',
      'work-shifts',
      'work-shifts-list',
      'work-shifts-app',
      'work-shifts-for-review',
      'work-shifts-ongoing',
      'work-shifts-rejected',
      'work-hours',
      'wallet',
      'insurances',
      'insurances-my_insurances',
      'insurances-yel_calculator',
      'reports',
      'reports-income_report',
      'reports-cumulative_report',
      'reports-salary_report',
      'reports-work_shifts_report',
      'reports-taxi_rides_report',
      'reports-accounting_events_report',
      'faq',
      'subscriptions',
      // 'marketing_menu',
      // 'marketing_referrals',
      // 'marketing_campaigns',
      'accounting_events',
      'admin_menu',
      'admin_operator_details',
      'admin_tax_cards',
      'admin_invoices_review',
      'admin_payouts_review',
      'admin_settlements_review',
      'admin_jobs_review',
      'admin_bank_transfer_transactions_review',
      'admin_users',
      // 'admin_users_taxi-companies',
      // 'admin_users_taxi-drivers',
      'admin_user_fees',
      'admin_liability_data',
      'admin_talent-search',
      'admin_marketing',
      'admin_referrals',
      'admin_campaigns',
      'admin_reports',
      'admin_reports-accounting',
      'admin_reports-accounting-v2',
      'admin_reports-income_report',
      'admin_reports-monthly_report',
      'admin_reports-invoices_report',
      'admin_reports-payouts_report',
      'admin_reports-settlements_report',
      'admin_reports-bank-account-transactions',
      'admin_adminbot',
      'admin_operator_fees',
      'admin_importdata',
      'taxi_menu',
      'taxi_invoice-form',
      'taxi_simple-invoice-form',
      'taxi_kelaov-simple-invoice-form',
      'taxi_contracts',
      'taxi_contracts-list',
      'taxi_contract-items',
      // 'taxi_taxi-companies',
      'taxi_sub-users',
      'taxi_vehicles',
      'taxi_rides'
    ],
    'operator-support': [
      'user_menu',
      'dashboards',
      'messaging',
      'clients',
      'invoices',
      'incomes',
      'expenses',
      'multi-expenses',
      'expenditures',
      'business-statements',
      'salaries',
      'salaries-sales_payouts',
      'salaries-salary_calculator',
      'jobs',
      'liability-data-search',
      'work-notices',
      'work-shifts',
      'work-shifts-list',
      'work-shifts-app',
      'work-shifts-for-review',
      'work-shifts-ongoing',
      'work-shifts-rejected',
      'work-hours',
      'wallet',
      'insurances',
      'insurances-my_insurances',
      'insurances-yel_calculator',
      'reports',
      'reports-income_report',
      'reports-cumulative_report',
      'reports-salary_report',
      'reports-work_shifts_report',
      'reports-taxi_rides_report',
      'reports-accounting_events_report',
      'faq',
      'subscriptions',
      'settlements',
      'settlements-sales_settlements',
      // 'marketing_menu',
      // 'marketing_referrals',
      // 'marketing_campaigns',
      'accounting_events',
      'admin_menu',
      'admin_operator_details',
      'admin_tax_cards',
      'admin_invoices_review',
      'admin_payouts_review',
      'admin_settlements_review',
      'admin_jobs_review',
      'admin_bank_transfer_transactions_review',
      'admin_users',
      // 'admin_users_taxi-companies',
      // 'admin_users_taxi-drivers',
      'admin_user_fees',
      'admin_liability_data',
      'admin_talent-search',
      'admin_marketing',
      'admin_referrals',
      'admin_campaigns',
      'admin_adminbot',
      'admin_operator_fees',
      'admin_importdata',
      'taxi_menu',
      'taxi_contracts',
      'taxi_contracts-list',
      'taxi_contract-items',
      // 'taxi_taxi-companies',
      'taxi_sub-users',
      'taxi_vehicles',
      'taxi_rides'
    ],
    'operator-accounting': [
      'user_menu',
      'dashboards',
      'faq',
      'invoices',
      'incomes',
      'expenses',
      'multi-expenses',
      'expenditures',
      'business-statements',
      'settlements',
      'settlements-sales_settlements',
      'salaries',
      'salaries-sales_payouts',
      'salaries-salary_calculator',
      'jobs',
      'work-hours',
      'reports',
      'reports-accounting_events_report',
      'accounting_events',
      'admin_menu',
      'admin_bank_transfer_transactions_review',
      'admin_reports',
      'admin_reports-accounting',
      'admin_reports-accounting-v2',
      'admin_reports-income_report',
      'admin_reports-monthly_report',
      'admin_reports-invoices_report',
      'admin_reports-payouts_report',
      'admin_reports-settlements_report',
      'admin_reports-bank-account-transactions',
      'admin_adminbot',
      'admin_operator_fees'
    ]
  }

  constructor(
    private _fuseNavigationService: FuseNavigationService
  ) { }

  registerNavigation(_navigation = undefined) {
    console.log('registerNavigation()');

    if (!_navigation) {
      _navigation = navigation;
    }

    // Register the navigation to the service
    this._fuseNavigationService.register('main', _navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // // Hide main menu items
    // this.MAIN_ITEMS.forEach(mainMenuItemId => {
    //   this._fuseNavigationService.updateNavigationItem(mainMenuItemId, { hidden: true });
    // });
  }

  resetNavigation() {
    console.log('resetNavigation()');

    // Unregister the navigation
    this._fuseNavigationService.unregister('main');
  }

  getNavigation(): any {
    console.log('getNavigation()');

    let navigation = this._fuseNavigationService.getCurrentNavigation();

    if (!navigation) {
      this.registerNavigation();
      navigation = this._fuseNavigationService.getCurrentNavigation();
    }
    return navigation;
  }

  updateBasedOnUserRole(role: string) {
    console.log('updateBasedOnUserRole() role:', role);

    const navigationData = this.getNavigation();

    if (navigationData) {
      const navigationItems = this._fuseNavigationService.getFlatNavigation(navigationData, []);
      // console.log('updateBasedOnUserRole() navigationItems:', navigationItems);

      const menuToShow = this.MENU_NAVIGATION_PER_ROLE[role];
      // console.log('updateBasedOnUserRole() menuToShow:', menuToShow);

      // review menu tree
      for (const menuItem of navigationItems) {
        // console.log('updateBasedOnUserRole() menuItem.id: %s - %s', menuItem.id, menuItem.hidden ? 'hide' : 'show');
        const menuId = menuItem.id;

        if (menuToShow.includes(menuId)) {
          // show it
          // console.log('updateBasedOnUserRole() show:', menuId);
          this._fuseNavigationService.updateNavigationItem(menuId, { hidden: false });
        } else {
          // hide it
          // console.log('updateBasedOnUserRole() hide:', menuItem.id);
          this._fuseNavigationService.updateNavigationItem(menuId, { hidden: true });
        }
      }

      // // ensure all items that are supposed to be shown are shown
      // for (const menuToShowItemId of menuToShow) {
      //   console.log('updateBasedOnUserRole() menuToShowItemId:', menuToShowItemId);
      //   this._fuseNavigationService.updateNavigationItem(menuToShowItemId, { hidden: false });
      // }
    } else {
      console.log('updateBasedOnUserRole() no navigationData; menu not updated!');
    }
  }

  updateBasedOnOperatorFeatures(features: any) {
    console.log('updateBasedOnOperatorFeatures() features:', features);

    const {
      workTime,
      faq,
      insurances,
      insuranceFee,
      insuranceAccidents,
      insuranceLiabilityAndLegal,
      // supportMessages,
      jobs,
      usersTalentProfile,
      workNotices,
      workShifts,
      usersForceYel,
      expensesSimplifiedManagement,
      rolesFreelancer,
      rolesCompany,
      taxiFeatures,
      taxiContracts,
      taxiRides,
      contractorLiabilityData,
      usersSkipIdVerification,
      usersFees
    } = features;

    const menuItemsToBeHidden = [];
    // const mainMenuItemsToBeShown = [];

    // work time
    // =hide work hours if feature disabled
    if (workTime && workTime !== 'enabled') {
      menuItemsToBeHidden.push('work-hours');
    }

    // faq
    // =hide FAQ if feature disabled
    if (faq && faq !== 'enabled') {
      menuItemsToBeHidden.push('faq');
    }

    // insurances
    // =hide insurances if insurances -feature is disabled
    // =hide insurances if both accident and liability insurances are forced always
    // =hide insurances if insurance fee is disabled
    console.log('updateBasedOnOperatorFeatures() insurances:', insurances);
    if ((insurances && insurances === 'disabled') || (insuranceFee && insuranceFee === 'disabled') || (insuranceAccidents && insuranceAccidents === 'forced' && insuranceLiabilityAndLegal && insuranceLiabilityAndLegal === 'forced')) {
      menuItemsToBeHidden.push('insurances');
      menuItemsToBeHidden.push('insurances-my_insurances');
    }

    // support messages
    // n/a

    // talent profile
    // =hide talent search in main menu and talent profile in user menu if feature disabled
    if (usersTalentProfile && usersTalentProfile !== 'enabled') {
      menuItemsToBeHidden.push('admin_talent-search');
    }

    // jobs
    // =hide jobs in main menu if feature disabled
    if (jobs && jobs !== 'enabled') {
      menuItemsToBeHidden.push('jobs');
    }

    // work notices
    // =hide work notices if feature disabled
    if (workNotices && workNotices !== 'enabled') {
      menuItemsToBeHidden.push('work-notices');
    }

    // work shifts
    // =hide work shifts if feature disabled
    if (workShifts && workShifts !== 'enabled') {
      menuItemsToBeHidden.push('work-shifts');
      menuItemsToBeHidden.push('reports-work_shifts_report');
    }

    // force YEL enabled
    // =hide YEL calculator if feature enabled
    if (usersForceYel && usersForceYel === 'enabled') {
      menuItemsToBeHidden.push('insurances-yel_calculator');
    }

    // simplified expense management
    // =hide expenses menu if feature enabled
    if (expensesSimplifiedManagement && expensesSimplifiedManagement === 'enabled') {
      menuItemsToBeHidden.push('expenses');
    }

    // freelancer roles enabled
    if (rolesFreelancer && rolesFreelancer !== 'enabled') {
      menuItemsToBeHidden.push('jobs');

      menuItemsToBeHidden.push('work-notices');
      menuItemsToBeHidden.push('expenses');
      menuItemsToBeHidden.push('salaries');
      menuItemsToBeHidden.push('insurances');
      menuItemsToBeHidden.push('reports-payouts_report');
      menuItemsToBeHidden.push('reports-work_certificate');
      menuItemsToBeHidden.push('reports-tax_report');
      menuItemsToBeHidden.push('faq');
      menuItemsToBeHidden.push('other-services');
      menuItemsToBeHidden.push('marketing_menu');
      menuItemsToBeHidden.push('admin_marketing');
      menuItemsToBeHidden.push('admin_tax_cards');
      menuItemsToBeHidden.push('admin_payouts_review');
      menuItemsToBeHidden.push('admin_jobs_review');
      menuItemsToBeHidden.push('admin_talent-search');
      menuItemsToBeHidden.push('admin_reports-payouts_report');
      menuItemsToBeHidden.push('admin_adminbot');
      menuItemsToBeHidden.push('admin_report-tool');

      if (rolesCompany && rolesCompany !== 'enabled') {
        menuItemsToBeHidden.push('work-hours');
        menuItemsToBeHidden.push('reports-income_report');
        menuItemsToBeHidden.push('reports-cumulative_report');
        menuItemsToBeHidden.push('reports-salary_report');
        menuItemsToBeHidden.push('admin_reports-income_report');
        menuItemsToBeHidden.push('admin_reports-monthly_report');
      }
    } else if (rolesCompany && rolesCompany !== 'enabled') {
      // Company roles
      // menuItemsToBeHidden.push('incomes');
      menuItemsToBeHidden.push('expenditures');
      // menuItemsToBeHidden.push('settlements');
      menuItemsToBeHidden.push('business-statements');
      // menuItemsToBeHidden.push('reports-settlements_report');
      // menuItemsToBeHidden.push('admin_settlements_review');
    }

    // taxi features disabled
    if (taxiFeatures && taxiFeatures !== 'enabled') {
      menuItemsToBeHidden.push('taxi_invoice-form');
      menuItemsToBeHidden.push('taxi_simple-invoice-form');
      menuItemsToBeHidden.push('taxi_kelaov-simple-invoice-form');
      menuItemsToBeHidden.push('taxi_sub-users');
      if (taxiContracts && taxiContracts !== 'enabled') {
        menuItemsToBeHidden.push('taxi_vehicles');
      }
      // menuItemsToBeHidden.push('taxi_rides');
    }

    // taxi rides disabled
    // NOTE: taxi rides menu is not shown for taxi-driver as of now..
    if (taxiRides && taxiRides !== 'enabled') {
      menuItemsToBeHidden.push('taxi_rides');
      menuItemsToBeHidden.push('reports-taxi_rides_report');
    }

    // taxi contracts disabled
    if (taxiContracts && taxiContracts !== 'enabled') {
      // menuItemsToBeHidden.push('incomes');
      menuItemsToBeHidden.push('taxi_contracts');
      menuItemsToBeHidden.push('taxi_taxi-companies');
    }

    // company, taxi features and taxi contracts disabled
    if (taxiFeatures === 'disabled' && taxiContracts === 'disabled' && rolesCompany === 'disabled') {
      menuItemsToBeHidden.push('incomes');
      menuItemsToBeHidden.push('settlements');
      menuItemsToBeHidden.push('reports-settlements_report');
      menuItemsToBeHidden.push('admin_settlements_review');
    }

    // all taxi features, taxi contracts and taxi rides disabled
    if (taxiFeatures !== 'enabled' && taxiContracts !== 'enabled' && taxiRides !== 'enabled') {
      menuItemsToBeHidden.push('taxi_menu');
      menuItemsToBeHidden.push('admin_users_taxi-companies');
      menuItemsToBeHidden.push('admin_users_taxi-drivers');
    }

    // Contractor liability data
    if (contractorLiabilityData !== 'enabled') {
      menuItemsToBeHidden.push('subscriptions');
      menuItemsToBeHidden.push('my-liability-data');
      menuItemsToBeHidden.push('liability-data-search');
    }

    // Contractor liability data
    if (usersFees !== 'enabled') {
      menuItemsToBeHidden.push('admin_user_fees');
    }

    // skip ID verification
    // n/a

    // hide them
    for (const menuItem of menuItemsToBeHidden) {
      console.log('updateBasedOnOperatorFeatures() hide:', menuItem);
      this._fuseNavigationService.updateNavigationItem(menuItem, { hidden: true });
    }
  }

  updateBasedOnUserData(user: User) {
    console.log('updateBasedOnUserData() user:', user);

    // show wallet badge if unread transactions
    if (user) {
      console.log('updateBasedOnUserData() update wallet badge:', user?.unreadTransactions || 'n/a');
      this._fuseNavigationService.updateNavigationItem('wallet', {
        badge: { title: user?.unreadTransactions || '' }
      });
    }

    // show/hide messaging badge
    console.log('updateBasedOnUserData() update messaging badge:', user?.unreadMessages || 'n/a');

    // To update existent badge, it is required to set it empty first
    this._fuseNavigationService.updateNavigationItem('messaging', { badge: { title: '' } });
    setTimeout(_ => {
      this._fuseNavigationService.updateNavigationItem('messaging', {
        badge: { title: user?.unreadMessages || '' }
      });
    }, 200);


    // Hide menus depending on some specific user data
    if (['freelancer', 'soletrader', 'company', 'taxi-company', 'taxi-driver'].includes(user.role)) {
      // if user is not ID verified, hide most of the menu items
      if (['freelancer', 'soletrader', 'company', 'taxi-company'].includes(user.role) && !user.userProfile?.idVerified) {
        console.log('updateBasedOnUserData() ID is not verified; hide menus..');
        console.log('updateBasedOnUserData() user.userProfile:', user.userProfile);
        this.hideMenusIfNotComplete(user);
      } else if (['taxi-driver'].includes(user.role) && !user.userProfile?.isCompleted) {
        console.log('updateBasedOnUserData() is taxi-driver and profile not completed; hide menus..');
        console.log('updateBasedOnUserData() user.userProfile:', user.userProfile);
        this.hideMenusIfNotComplete(user);
      }
      // if user does not have user profile/business profile completed, hide most of the menu items
      this.checkBusinessProfileData(user);
    }

    if (['taxi-driver'].includes(user.role) && user.operator?.features?.workShifts === 'enabled') {
      // hide simple invoice menus
      this._fuseNavigationService.updateNavigationItem('taxi_simple-invoice-form', { hidden: true });
      this._fuseNavigationService.updateNavigationItem('taxi_kelaov-simple-invoice-form', { hidden: true });
    }
  }

  checkBusinessProfileData(user) {
    console.log('checkBusinessProfileData()');

    if (user && user.businessProfile && !user.businessProfile.isCompleted && !['taxi-driver'].includes(user.role)) {
      this.hideMenusIfNotComplete(user);
      // if (user && user.businessProfile && ['soletrader', 'company', 'taxi-company'].includes(user.role)) {
      //   const fieldsToCheck = [
      //     'businessSectorName',
      //     'businessSectorCode',
      //     // 'bankAccountIban',
      //     // 'bankAccountBic',
      //     // 'bankAccountOwner'
      //     ];
      //   for (let fieldToCheck of fieldsToCheck) {
      //     if (!user.businessProfile[fieldToCheck]) {
      //       console.log('checkBusinessProfileData() businessProfile is not complete; hide menus..');
      //       console.log('checkBusinessProfileData() businessProfile:', user.businessProfile);
      //       this.hideMenusIfNotComplete(user);
      //       break;
      //     }
      //   }
    } else {
      console.log('checkBusinessProfileData() no business profile or business profile is complete; do nothing..');
      console.log('checkBusinessProfileData() user.businessProfile:', user.businessProfile);
    }
  }

  hideEmptyGroupsIfAny() {
    console.log('hideEmptyGroupsIfAny()');

    const navigationData = this.getNavigation();
    if (navigationData) {
      const navigationItems = this._fuseNavigationService.getFlatNavigation(navigationData, []);
      // console.log('updateBasedOnUserRole() navigationItems:', navigationItems);

      // browse menu tree
      for (const menuItem of navigationItems) {
        //console.log('hideEmptyGroupsIfAny() menuItem:', menuItem);
        const menuId = menuItem.id;

        // Hide not hidden menu entries when subitems are hidden or empty
        if (!menuItem.hidden && menuItem.children?.length && !menuItem.children.some(entry => entry.hidden === false)) {
          console.log('hideEmptyGroupsIfAny() Hidding empty group: ', menuId);
          this._fuseNavigationService.updateNavigationItem(menuId, { hidden: true });
        }
      }
    } else {
      console.log('hideEmptyGroupsIfAny() no navigation; menu not updated!');
    }

    /*const reportsEntries = [
      'reports-income_report',
      'reports-cumulative_report' ,
      'reports-salary_report',
      'reports-payouts_report',
      'reports-invoices_report',
      'reports-settlements_report'
    ];

    console.log('-----');
    console.log(menuItemsToBeHidden);
    console.log(reportsEntries);
    // Hide reports menu if has no sub items
    if (!menuItemsToBeHidden.some(elem => reportsEntries.includes(elem))) {
      menuItemsToBeHidden.push('reports');
    }*/
  }

  hideMenusIfNotComplete(user) {
    const userMenus = this.MENU_NAVIGATION_PER_ROLE[user.role];

    for (let userMenu of userMenus) {
      if (!['user_menu', 'dashboards', 'messaging', 'faq'].includes(userMenu)) {
        this._fuseNavigationService.updateNavigationItem(userMenu, {
          hidden: true
        });
      }
    }
  }

  updateMenuPerCurrentUser(user: User, operator: Operator = undefined) {
    console.log('updateMenuPerCurrentUser()');

    try {
      // console.log('updateMenuPerCurrentUser() user:', user);
      // console.log('updateMenuPerCurrentUser() operator:', operator);

      // update based on role
      // step 1 - show all menu items based on role
      if (user.role) {
        this.updateBasedOnUserRole(user.role);
      }

      // update based on operator features
      // step 2 - hide menu items if feature is disabled
      if (operator || user.operator) {
        const features = operator?.features || user?.operator?.features;
        if (features) {
          this.updateBasedOnOperatorFeatures(features);
        }
      }

      // update based on user status/user profile data
      // step 3 - update menu items/badges based on data or user status
      this.updateBasedOnUserData(user);

      // hide empty items without children
      this.hideEmptyGroupsIfAny();
    } catch (err) {
      console.error('updateMenuPerCurrentUser() catch; err:', err);
    }
  }
}
