import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FuseMatchMediaService implements OnDestroy 
{
    activeMediaQuery: string;
    onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _unsubscribeAll = new Subject<void>();

    /**
     * Constructor
     *
     * @param {MediaObserver} _mediaObserver
     */
    constructor(
        private _mediaObserver: MediaObserver
    )
    {
        // Set the defaults
        this.activeMediaQuery = '';

        // Initialize
        this._init();

    }
    
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void
    {      
        this._mediaObserver.asObservable()
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe((changes: MediaChange[]) => {
                const currentMediaChange = changes[0];
                if ( this.activeMediaQuery !== currentMediaChange.mqAlias )
                {
                    this.activeMediaQuery = currentMediaChange.mqAlias;
                    this.onMediaChange.next(currentMediaChange.mqAlias);
                }
            });
    }
}
