import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getCurrentUser() {
    let json: any;
    try {
      json = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') || '') : null;
    } catch (err) {
      console.error('LocalStorage/getCurrentUser() catch; err:', err);
      json = null;
    }
    return json;
  }

  setLoggedAccount(token: string, expiresAt: string) {
    if (token) {
      localStorage.setItem('userInfo', JSON.stringify({ token, expiresAt }));
    } else {
      console.log('LocalStorage/setLoggedAccount() error! no token provided');
    }
  }

  removeLoggedAccount() {
    localStorage.removeItem('userInfo');
  }

  getUserLang(): string {
    return localStorage.getItem('lang') || '';
  }

  setUserLang(lang: string): void {
    if (lang) {
      localStorage.setItem('lang', lang);
    } else {
      console.log('LocalStorage/setUserLang() error! no lang provided');
    }
  }

  getReferralCode(): string {
    return localStorage.getItem('referralCode') || '';
  }

  setReferralCode(referralCode: string): void {
    if (referralCode) {
      localStorage.setItem('referralCode', referralCode);
    } else {
      console.log('LocalStorage/setReferralCode() error! no referralCode provided');
    }
  }

  getCampaignCode(): string {
    return localStorage.getItem('campaignCode') || '';
  }

  setCampaignCode(campaignCode: string): void {
    if (campaignCode) {
      localStorage.setItem('campaignCode', campaignCode);
    } else {
      console.log('LocalStorage/setCampaignCode() error! no campaignCode provided');
    }
  }

  getPublicParentUser(): string {
    return localStorage.getItem('p') || '';
  }

  setPublicParentUser(publicParentUser: string): void {
    if (publicParentUser) {
      localStorage.setItem('p', publicParentUser);
    } else {
      console.log('LocalStorage/setPublicParentUser() error! no publicParentUser provided');
    }
  }
}
