import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ReCaptchaV3Service } from "ngx-captcha";
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaService {
  captchaElemClass = '.grecaptcha-badge';

  constructor(
    @Inject(DOCUMENT) private document: Document,
      private reCaptchaV3Service: ReCaptchaV3Service,
  ) { }

  showLogo() {
    let elem = this.document.querySelector(this.captchaElemClass);
    if (elem && !elem.classList.contains('show-element')) {
      elem.classList.add('show-element');
    } else if (!elem) {
      setTimeout(_ => this.showLogo(), 500);
    }
  }

  hideLogo() {
    let elem = this.document.querySelector(this.captchaElemClass);
    if (elem && elem.classList.contains('show-element')) {
      elem.classList.remove('show-element');
    } else if (!elem) {
      setTimeout(_ => this.hideLogo(), 500);
    }
  }

  getCaptchaToken(action): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.reCaptchaV3Service.execute(
        environment.googleReCaptchaV3Key,
        action,
        (token) => {
          return resolve(token);
        },
        { useGlobalDomain: false }
      );
    })
  }

}
